.Navbar {
  width: 90%;
  margin: auto;
  padding: 10px 0;
}

.navbar-list > ul > li > a {
  color: #ffffff;
  font-size: 17px;
}

.navbar-list > ul > li > a:hover {
  color: #392ddd;
}

@media only screen and (max-width: 600px) {
  .navbar-list {
      display: none;
  }

  .col-md-4 {
      text-align: center;
      width: 100%;
  }

  .col-md-7 {
      display: none;
  }

  .col-md-1 {
      width: 100%;
      text-align: center;
  }
}

@media only screen and (max-width: 890px) {
  .dots {
      display: none;
  }
}
