/* Main About Container */
#rs-about {
    background-color: #F9F9F9;
    padding: 20px; /* Add padding for better spacing */
}

/* Inner container styling */
.rs-about .about-container {
    width: 90%;
    margin: auto;
    padding-top: 50px;
}

/* Text and content styling */
.rs-about .sec-title4 {
    margin-bottom: 20px;
}

.rs-about .accordion {
    margin-top: 20px;
}

/* Image container for about content */
.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Styling for image parts */
.images-part img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Rounded corners for images */
    box-shadow: 1px 1px 4px rgb(197, 194, 194);
    margin-bottom: 20px;
}

/* Animations container */
.rs-animations {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between animated images */
}

/* Animation styling for images */
.spinner.dot img,
.spinner.ball img {
    width: 80px; /* Adjust size for mobile */
    height: auto; /* Maintain aspect ratio */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    /* Adjust padding and margin for smaller screens */
    .rs-about.style10 {
        padding: 10px;
    }
    .about-container {
        padding-top: 30px;
    }

    /* Responsive text styling */
    .sec-title4 h2 {
        font-size: 24px;
    }

    .sec-title4 p {
        font-size: 16px;
    }

    /* Responsive column layout */
    .rs-about .row {
        flex-direction: column; /* Stack the columns */
        align-items: center; /* Center the content */
    }

    .col-lg-6 {
        width: 100%; /* Full width for columns in mobile view */
        padding: 0; /* Remove padding */
        margin-bottom: 20px; /* Add spacing between sections */
    }

    .accordion .card-header {
        text-align: center;
    }
}
