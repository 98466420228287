.service-container{
    width: 90%;
    margin:auto;
    margin-top: 50px;
    
}
.service{  
    margin:auto;
    
    margin-top: 80px;
}
.service-box{
    /* border:1px solid #000; */
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.637);
    height:200px;
    width:99%;
    margin-bottom: 30px;
    display:flex;
    padding:10px
}
.service-box:hover{
    box-shadow: 1px 1px 5px rgb(0, 0, 0);
    background-color: rgba(231, 229, 229, 0.377);
    transition:0.4ms;
}
.icon-part{
    width: 200px;

}

.details-part{

}
/* Main service container */


/* Responsive styling for mobile */
@media (max-width: 768px) {
    .service-container {
        width: 90%;
        margin: auto;
        margin-top: 50px;
    }
    
    /* Service row container */
    .service {
        margin: auto;
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap; /* Allow content to wrap for smaller views */
        gap: 20px; /* Space between service boxes */
    }
    
    /* Service box styling */
    .service-box {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.637);
        height: auto; /* Adapt height to content */
        width: 100%; /* Full width on smaller screens */
        max-width: 350px; /* Set a max width to control the box size */
        margin-bottom: 30px;
        display: flex;
        padding: 15px;
        background-color: #fff; /* Background color for visibility */
        border-radius: 5px; /* Rounded corners */
        transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
    }
    
    /* Hover effect for service box */
    .service-box:hover {
        box-shadow: 1px 1px 5px rgb(0, 0, 0);
        background-color: rgba(231, 229, 229, 0.377);
        transition: 0.4ms;
    }
    
    /* Icon part styling */
    .icon-part {
        max-width: 100px; /* Control icon size */
        margin-right: 15px; /* Space between icon and text */
        align-self: center; /* Center the icon */
    }
    
    /* Details part styling */
    .details-part {
        flex: 1; /* Allow details to take the remaining space */
    }
    .service {
        flex-direction: column; /* Make service boxes stack vertically */
        align-items: center; /* Center content */
    }

    .service-box {
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center content in mobile view */
        width: 100%; /* Full width on mobile */
        max-width: 100%; /* Remove the maximum width for full width on small screens */
    }

    .icon-part {
        width: 80px; /* Reduce icon size for mobile */
        margin-bottom: 10px; /* Add space below icon */
    }

    .details-part h2, .details-part p {
        text-align: center; /* Center text for better readability */
    }
}
