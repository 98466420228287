.features-container {
    width: 90%;
    margin: auto;
    padding: 50px 0px;
}

/* Feature Box Styling */
.feature-box {
    height: auto; /* Allow height to adjust based on content */
    min-height: 270px; /* Maintain minimum height for consistent design */
    box-shadow: 2px 2px 20px rgb(216, 213, 213);
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px;
    overflow: hidden; /* Hide overflow content */
    text-align: center; /* Center text for a uniform look */
}

/* Hover Effect for Feature Box */
.feature-box:hover {
    background-color: #562dd4;
    background-image: linear-gradient(90deg, #1672de 0%, #071f6b 58%);
    color: #fff;
    transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
    transform: scale(1.05); /* Slightly scale up on hover */
}

/* Adjust Image on Hover */
.image img {
    max-width: 100%; /* Ensure the image doesn't overflow */
    height: auto;
}

.image:hover {
    transform: rotate(2deg);
    transition: 1s;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
    .feature-box {
        margin-bottom: 20px; /* Add space between boxes on mobile */
    }

    .description h4 {
        font-size: 18px; /* Adjust title size for mobile */
    }

    .description p {
        font-size: 14px; /* Adjust description size for mobile */
    }
}
