
.team-item-wrap {
    margin: 0 auto;
    max-width: 300px; /* Optional: Set a max-width for team items */
}

.team-content {
    text-align: center;
}

@media (min-width: 992px) { /* Apply these styles on larger screens */
    .row.d-flex {
        justify-content: center; /* Center the team members */
    }

    .team-item-wrap {
        max-width: none; /* Ensure that width is dynamic for centering */
        flex: 0 0 45%; /* Take up around 45% of the row width */
    }

    .team-item-wrap:nth-child(2) {
        margin-left: 5%; /* Optional: Add a bit of spacing between two team members */
    }
}
