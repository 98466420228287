.slider-container{
    width:90%;
    margin: auto;
    padding-top:30px;
    padding-bottom:50px;
}
.slide-part{
    display:flex;
    align-items:center;
}

.slide-image{
    width:85%;
}

@media only screen and (max-width: 1200px) {
    .slide-des h4{
        font-size:15px;
      }
    .slide-des h1{
        font-size:30px;
      }
    .slide-des h3{
        font-size:20px;
      }
      .slide-image{
        width:65%;
    }
  }
@media only screen and (max-width: 1055px) {
    .slide-des h4{
        font-size:15px;
      }
    .slide-des h1{
        font-size:30px;
        margin-top:-25px;
        margin-bottom:-10px;
      }
    .slide-des h3{
        font-size:20px;
      }
      .slide-image{
        width:55%;
    }
  }
@media only screen and (max-width: 765px) {
    .slide-des{
        padding:0% !important;
        padding-left:6% !important;
        text-align:center !important;
        padding-bottom:30px !important;
      }
      .slide-image{
        display:none;
    }
  }