@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

html,
body {
  font-size: 16px;
  color: #363636;
  font-family: 'Roboto', sans-serif;
  vertical-align: baseline;
  line-height: 1.7;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #1c1b1b;
  margin: 0 0 26px;
  font-weight: 700;
}
h1 {
  color: #0a0a0a;
  font-size: 54px;
  line-height: 64px;
}
h2 {
  font-size: 36px;
  line-height: 40px;
}
h3 {
  font-size: 28px;
  line-height: 32px;
}
h4 {
  font-size: 20px;
  line-height: 28px;
}
h5 {
  font-size: 18px;
  line-height: 28px;
}
h6 {
  font-size: 16px;
  line-height: 20px;
}
a {
  color: #106eea;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #106eea;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.uppercase {
  text-transform: uppercase;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #106eea;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #106eea;
  text-shadow: none;
  color: #ffffff;
}
.width-unset {
  width: unset !important;
}
.white-bg {
  background-color: #ffffff;
}
.primary-bg {
  background: #106eea;
}
.secondary-bg {
  background: #228bfd;
}
.title-bg {
  background: #1c1b1b;
}
.gray-bg {
  background: #f1f6fc;
}
.gray-bg2 {
  background: #fbfbfb;
}
.gray-bg3 {
  background: #fafafa;
}
.gray-bg4 {
  background: #f8f8f8;
}
.gray-bg5 {
  background: #f9f9f9;
}
.gray-bg6 {
  background: #f7f7f7;
}
.gray-bg7 {
  background: #f2e1e1;
}
.blue-bg {
  background: #1c3988;
}
.blue-color {
  color: #1c3988;
}
.red-color {
  color: #ec4c6f;
}
.red-bg {
  background: #ec4c6f;
}
.red-bg2 {
  background: #d83030;
}
.red-color2 {
  color: #d83030;
}
.green-bg {
  background: #67c27c;
}
.green-color {
  color: #67c27c;
}
.law-color {
  color: #b1976b;
}
.law-bg {
  background: #b1976b;
}
.navy-color {
  color: #282e3f;
}
.navy-bg {
  background: #282e3f;
}
.blue-light {
  background-image: linear-gradient(160deg, #0052f5 0%, #4899f9 100%);
}
.transparent-bg {
  background: transparent;
}
.pt-220 {
  padding-top: 220px;
}
.md-pt-186 {
  padding-top: 186px !important;
}
.bg-fixed {
  background-attachment: fixed !important;
}
.bg1 {
  background-image: url(./images/bg/bg1.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg2 {
  background-image: url(./images/bg/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg3 {
  background-image: url(./images/bg/bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg4 {
  background-image: url(./images/bg/bg4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg5 {
  background-image: url(./images/bg/bg5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg6 {
  background-image: url(./images/bg/bg6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg7 {
  background-image: url(./images/bg/bg7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg8 {
  background-image: url(./images/bg/bg8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg9 {
  background-image: url(./images/bg/bg9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg10 {
  background-image: url(./images/bg/bg10.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg11 {
  background-image: url(./images/bg/bg11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg12 {
  background-image: url(./images/bg/bg12.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg13 {
  background-image: url(./images/bg/bg13.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg14 {
  background-image: url(./images/bg/bg14.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg15 {
  background-image: url(./images/bg/bg15.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.bg16 {
  background-image: url(./images/bg/bg16.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.bg17 {
  background-image: url(./images/bg/bg17.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}
.bg18 {
  background-image: url(./images/bg/bg18.png);
  background-position: center center;
}
.bg19 {
  background-image: url(./images/bg/bg19.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg20 {
  background-image: url(./images/bg/bg20.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg21 {
  background-image: url(./images/bg/bg21.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg22 {
  background-image: url(./images/bg/bg22.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg23 {
  background-image: url(./images/bg/bg23.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg24 {
  background-image: url(./images/bg/bg24.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg25 {
  background-image: url(./images/bg/bg25.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg26 {
  background-image: url(./images/bg/call-action-12.jpg);
  background-color: #7211FF;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
}
.bg27 {
  background-image: url(./images/bg/website-seo.jpg);
  background-color: #7211FF;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
}
.bg28 {
  background-image: url(./images/bg/testi-12.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg29 {
  background-image: url(./images/bg/services-bg13.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0px 280px 0px;
}
.bg30 {
  background-image: url(./images/bg/counter-bg13.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 90px 100px;
  border-radius: 5px;
}
.bg31 {
  background-image: url(./images/bg/whychoose-bg13.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px 494px 0px;
}
.bg32 {
  background-image: url(./images/bg/testi-bg13.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 50px 70px;
  border-radius: 5px 5px 5px 5px;
}
.bg33 {
  background-image: url(./images/bg/counter-bg14.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg34 {
  background-image: url(./images/bg/video-bg14.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 240px 0 240px;
  position: relative;
  z-index: 111;
}
.bg35 {
  background-image: url(./images/bg/project-bg14.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 250px;
}
.bg36 {
  background-image: url(./images/bg/testi-bg14.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg37 {
  background-image: url(./images/bg/get-quote14.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg38 {
  background-image: url(./images/bg/counter-bg15.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg39 {
  background-image: url(./images/bg/video-bg15.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 260px 0 230px;
}
.bg40 {
  background-image: url(./images/bg/price-bg15.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg41 {
  background-image: url(./images/bg/blog-bg15.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg42 {
  background-image: url(./images/bg/project-bg16.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg43 {
  background-image: url(./images/bg/testi-bg16.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F1F6FC;
}
.bg44 {
  background-image: url(./images/bg/testi-bg17.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #110909;
}
.bg45 {
  background-image: url(./images/bg/testi-bg18.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.shape-bg1 {
  background: url(./images/bg/shape/shape-bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.shape-bg2 {
  background: url(./images/bg/shape/shape-bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.shape-bg3 {
  background-image: url(./images/bg/shape/shape-bg3.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.shape-bg4 {
  background-image: url(./images/bg/shape/shape-bg4.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.shape-bg5 {
  background: url(./images/bg/shape/shape-bg5.png);
  background-size: 100% 94%;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.rs-appscreen {
  position: relative;
  z-index: 111;
}
.rs-appscreen .rs-animations .animate {
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.rs-appscreen .rs-animations .animate.big {
  left: -6%;
  top: 23%;
}
.rs-appscreen .rs-animations .animate.small {
  right: 10%;
}
.bg-unset {
  background: unset !important;
}
.button-text {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  padding: 17px 32px 17px 32px;
  border-radius: 5px;
  transition: all .3s;
  display: inline-block;
}
.button-text i {
  color: #ffffff;
  margin-right: 11px;
}
.button-text:hover {
  background-color: #1C3988;
  border-color: #1C3988;
}
.button-text:hover .sub-text {
  color: #ffffff;
}
.rs-menu-toggle {
  color: #106eea;
  text-align: right;
}
button {
  cursor: pointer;
}
.glyph-icon:before {
  margin: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
/* ------------------------------------
    02. Global Class CSS
---------------------------------------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.readon {
  outline: none;
  padding: 15px 29px;
  border: none;
  border-radius: 3px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #106eea;
  transition: all 0.3s ease;
}
.readon.banner-style {
  background: #ffffff;
  color: #228bfd;
}
.readon.banner-style:hover {
  background: #f1f6fc;
  color: #228bfd;
}
.readon.modify {
  padding: 13px 32px;
}
.readon.white-btn {
  background: #ffffff;
  color: #106eea;
  font-weight: 400;
  padding: 14px 35px;
}
.readon.white-btn:hover {
  color: #ffffff;
  background: #106eea;
}
.readon.green-btn {
  background: #67c27c;
  color: #ffffff;
  padding: 11.5px 25px;
  font-weight: 600;
  border: 2px solid #67c27c;
}
.readon.green-btn:hover {
  color: #ffffff;
  background: transparent;
}
.readon.blue-btn {
  background: #1c3988;
  font-weight: 600;
  color: #ffffff;
  padding: 13.5px 27px;
}
.readon.blue-btn:hover {
  background: #20429d;
  color: #ffffff;
}
.readon.transparent {
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  padding: 11px 28px;
  border: 2px solid;
}
.readon.transparent.primary {
  color: #106eea;
  border-color: #106eea;
}
.readon.transparent.primary:hover {
  color: #ffffff;
  background: #106eea;
}
.readon.transparent.white {
  color: #ffffff;
  border-color: #ffffff;
}
.readon.transparent.white:hover {
  border-color: #106eea;
  background: #106eea;
}
.readon:hover {
  background: #228bfd;
  color: #ffffff;
}
.readon2 {
  outline: none;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 2px;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: none;
  letter-spacing: 1px;
}
.readon2.started {
  background-image: linear-gradient(340deg, #e68008 0%, #fb3a26 100%);
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  padding: 16px 35px 16px 35px;
}
.readon2.started:hover {
  color: #ffffff;
  background-image: linear-gradient(360deg, #fb3a26 0%, #e68008 90%);
}
.readon2.contact {
  background-color: #1273EB;
  padding: 17px 30px 17px 30px;
  color: #FFFFFF;
  font-size: 16px;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
}
.readon2.contact:hover {
  background-color: rgba(15, 109, 211, 0.8705882352941177);
}
.readon2.contact.solutions {
  padding: 10px 29px 10px 29px;
  font-size: 16px;
  background-color: #1273EB00;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF;
  border-radius: 30px 30px 30px 30px;
}
.readon2.contact.solutions:hover {
  background-color: #007AFF;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #007AFF;
}
.readon2.submit-btn {
  background-color: #FB3A26;
  padding: 17px 30px;
  font-size: 14px;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
}
.readon2.submit-btn:hover {
  opacity: 0.9;
}
.readon2.submit-btn.new:hover {
  background-color: #438cdc;
  color: #ffffff;
}
.readon2.submit-btn.now {
  color: #fff;
  background-color: #070925;
  padding: 25px 30px 25px 30px;
  font-family: "Nunito", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 3px;
}
.readon2.submit-btn.now:hover {
  color: #fff;
  background-color: #070925BA;
}
.readon2.submit-btn.now.submit {
  background-color: #1566F5;
}
.readon2.submit-btn.now.submit:hover {
  color: #fff;
  background-color: #070925BA;
}
.readon2.submit-btn.now.submit-red {
  background-color: #BF0505;
  padding: 20px 30px 20px 30px;
  font-size: 16px;
  font-weight: 500;
}
.readon2.submit-btn.now.submit-red:hover {
  color: #fff;
  background-color: #D83030;
}
.readon2.submit-btn.con-btn {
  background-color: #070F3A;
  padding: 5px 30px 5px 30px;
  font-size: 16px;
  border-radius: 30px 30px 30px 30px;
}
.readon2.submit-btn.con-btn:hover {
  color: #fff;
  background-color: rgba(15, 109, 211, 0.8705882352941177);
}
.readon2.view-more {
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #111111;
  background-color: #FFFFFF;
  border-radius: 30px 30px 30px 30px;
}
.readon2.discover {
  font-weight: 500;
  color: #fff;
  background: #e68008;
  padding: 16px 24px;
  border-radius: 3px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0;
}
.readon2.discover:hover {
  color: #fff!important;
  opacity: .9;
}
.readon2.discover.new-more {
  background: #1c3988;
}
.readon2.discover.apps {
  background: #ec4c6f;
}
.readon2.discover.marketing {
  background: #bf0505;
}
.readon2.get-new {
  padding: 16px 35px 16px 35px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  background-color: transparent;
  background-image: linear-gradient(20deg, #015eea 23%, #02a3f4 98%);
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 30px 30px 30px 30px;
}
.readon2.get-new:hover {
  background-image: linear-gradient(340deg, #015eea 23%, #02a3f4 98%);
  color: #fff;
}
.readon2.get-new.download {
  background: #fff;
  color: #333333;
  border-radius: 4px;
  padding: 15px 30px 15px;
  text-transform: uppercase;
  font-family: Roboto;
}
.readon2.get-new.download:hover {
  opacity: 0.89;
}
.readon2.get-new.purchase {
  background: #ec4c6f;
  color: #fff;
  border-radius: 4px;
  padding: 15px 30px 15px;
  text-transform: uppercase;
  font-family: Roboto;
}
.readon2.get-new.purchase:hover {
  opacity: 0.89;
}
.readon2.buy-now {
  padding: 10px 40px 10px 40px;
  background-color: #017aef;
  border-radius: 30px 30px 30px 30px;
  text-transform: uppercase;
  position: relative;
  top: 25px;
  font-size: 16px;
  letter-spacing: 0;
}
.readon2.buy-now:hover {
  color: #fff;
  background-color: #0A6FD1;
}
.readon2.buy-now.white-btn {
  box-shadow: 0px 4px 15px 0px #eee;
  background-color: #FFFFFF;
  color: #106eea;
}
.readon2.buy-now.white-btn:hover {
  background-color: #FFFFFFE3;
}
.readon2.slide-quote {
  font-size: 13px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #fff;
  padding: 16px 40px;
  border-radius: 5px;
}
.readon2.slide-quote:hover {
  color: #ffffff;
  background: #1c3988;
  border-color: #1c3988;
}
.readon2.slide-quote.touch {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  border-radius: 30px;
  padding: 16px 32px;
  border: 2px solid #fff;
}
.readon2.slide-quote.touch:hover {
  color: #ffffff;
  background: #106eea;
  border-color: #106eea;
}
.readon2.slide-get {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  font-weight: 600;
  border-color: transparent;
  background: linear-gradient(45deg, #bf0505 0%, #232323 100%);
  font-family: Poppins;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 40px;
}
.readon2.slide-get:hover {
  background: linear-gradient(180deg, #bf0505 0%, #320001 100%);
  color: #ffffff;
}
.readon2.blog-btn {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #090E40;
  color: #090E40;
  border-radius: 3px 3px 3px 3px;
  padding: 17px 32px 17px 32px;
}
.readon2.blog-btn:hover {
  color: #fff;
  background-color: #090E40;
  border-color: #090E40;
}
.readon2.price-btn {
  padding: 10px 30px 10px 30px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #EC4C6F;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EC4C6F;
  border-radius: 4px 4px 4px 4px;
}
.readon2.price-btn.price2 {
  background-color: #02010100;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EC4C6F;
  color: #555555;
}
.readon2.price-btn.price2:hover {
  color: #ffffff;
  background-color: #EC4C6F;
}
.readon2.learn-more {
  padding: 16px 35px 16px 35px;
  font-size: 16px;
  text-transform: capitalize;
  background-color: transparent;
  background-image: linear-gradient(20deg, #bf0505 23%, #360d0d 98%);
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 0px 0px 0px 0px;
}
.readon2.learn-more:hover {
  color: #fff;
  background-image: linear-gradient(110deg, #010710 23%, #bf0505 98%);
}
.readon2.corporate-btn {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  padding: 17px 55px;
  border-radius: 50px;
  color: #1566f5;
  background-color: #ffffff;
  font-family: Poppins;
  text-transform: uppercase;
}
.readon2.corporate-btn:hover {
  color: #ffffff;
  background-color: #1566f5;
  border-color: #1566f5;
}
.readon2.services-view {
  font-size: 15px;
  line-height: 36px;
  border-radius: 30px 30px 30px 30px;
  text-transform: uppercase;
  font-family: poppins, sans-serif;
  color: #fff;
  background: #1566F5;
  padding: 10px 40px;
  position: relative;
}
.readon2.services-view:after {
  content: "\f113";
  position: absolute;
  font-family: flaticon;
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: all 0.3s ease;
}
.readon2.services-view:hover {
  opacity: .99;
}
.dual-btn {
  display: flex;
  justify-content: center;
}
.dual-btn .dual-btn-wrap {
  position: relative;
  text-align: center;
  font-weight: 400;
}
.dual-btn .dual-btn-wrap a {
  max-width: 100%;
  color: #fff;
  display: inline-block;
  background-color: #106eea;
  padding: 14px 30px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.dual-btn .dual-btn-wrap a.btn-left {
  border-radius: 4px 0 0 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.dual-btn .dual-btn-wrap a.btn-right {
  border-radius: 0 4px 4px 0;
}
.dual-btn .dual-btn-wrap a:hover {
  background-color: #0e5ec9;
}
.dual-btn .dual-btn-wrap .connector {
  display: inline-block;
  position: absolute;
  z-index: 9;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
  color: #27374c;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  top: 50%;
  right: 0;
}
.dual-btn.modify {
  justify-content: unset;
}
.dual-btn.modify .dual-btn-wrap a {
  text-transform: capitalize;
  font-size: 16px;
  box-shadow: none;
}
.dual-btn.modify .dual-btn-wrap a.btn-right {
  background: #132235;
}
.readon-arrow {
  color: #444;
  padding-right: 25px;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
.readon-arrow:after {
  content: "\f114";
  position: absolute;
  font-family: flaticon;
  font-size: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  transition: all 0.3s ease;
}
.readon-arrow:hover {
  color: #106eea;
}
.readon-arrow:hover:after {
  right: -5px;
  color: #106eea;
}
.video-btn a {
  width: 80px;
  height: 80px;
  line-height: 90px;
  border-radius: 100%;
  background: #ffffff;
  color: #106eea;
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.video-btn a i {
  font-size: 30px;
  margin-left: 5px;
  position: relative;
  z-index: 1;
}
.video-btn a:after {
  background: #ffffff;
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
}
.video-btn.border-style a:before {
  width: 100px;
  height: 100px;
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: 1px solid #ffffff;
}
.video-btn.primary a {
  background: #106eea;
  color: #ffffff;
}
.video-btn.primary a:after {
  background: #106eea;
}
.video-btn.primary a:before {
  border-color: #106eea;
}
.video-btn.green a {
  background: #67c27c;
  color: #ffffff;
}
.video-btn.green a:after {
  background: #67c27c;
}
.video-btn.green a:before {
  border-color: #67c27c;
}
.video-btn.red2 a {
  background: #d83030;
  color: #ffffff;
}
.video-btn.red2 a:after {
  background: #d83030;
}
.video-btn.red2 a:before {
  border-color: #d83030;
}
.video-btn.law a {
  background: #ffffff;
  color: #b1976b;
}
.video-btn.law a:after {
  background: #ffffff;
}
.video-btn.law a:before {
  border-color: #ffffff;
}
.video-btn.seo-agency a {
  background: #106eea;
  color: #ffffff;
  left: -7%;
  bottom: 66px;
}
.video-btn.seo-agency a:after {
  background: #106eea;
}
.video-btn.seo-agency a:before {
  border-color: #106eea;
}
.video-btn.corporate a {
  color: #101010;
}
.video-btn.corporate a:after {
  background: #fff;
}
.video-btn.corporate a:before {
  background: #fff;
}
.video-btn.apps a {
  background: #fb607d;
  color: #ffffff;
}
.video-btn.apps a:after {
  background: #fb607d;
}
.video-btn.apps a:before {
  border-color: #fb607d;
}
.rs-videos .animate-border .popup-border {
  background: #f74820;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.rs-videos .animate-border .popup-border i {
  font-size: 20px;
  line-height: 80px;
  display: block;
  color: #ffffff;
}
.rs-videos .animate-border .popup-border:before {
  content: "";
  border: 2px solid #f74820;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}
.rs-videos .animate-border .popup-border:after {
  content: "";
  border: 2px solid #f74820;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
@-webkit-keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
.waving-line {
  padding-bottom: 29px;
  position: relative;
}
.waving-line:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 114px;
  height: 12px;
  animation: waving-line 12s linear infinite;
  -webkit-animation: waving-line 12s linear infinite;
  background: url(./images/waving-line.png);
}
.waving-line.center:after {
  left: 50%;
  transform: translateX(-50%);
}
.waving-line.white:after {
  background: url(./images/waving-line-light.png);
}
.margin-remove {
  margin: 0 !important;
}
.bdru-40 {
  border-radius: 40px;
}
.bdru-4 {
  border-radius: 4px;
}
.bdru-5 {
  border-radius: 5px;
}
.shadow {
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07) !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.gutter-16 {
  margin-left: -8px;
  margin-right: -8px;
}
.gutter-16 [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}
.gutter-20 {
  margin-left: -10px;
  margin-right: -10px;
}
.gutter-20 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.clearfix {
  clear: both;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.container-fluid {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.container-fluid2 {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.wrap-1400 {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.white-color {
  color: #ffffff !important;
}
.title-color {
  color: #1c1b1b !important;
}
.title-color2 {
  color: #101010 !important;
}
.title-color3 {
  color: #05275a !important;
}
.secondary-color {
  color: #228bfd;
}
.primary-color {
  color: #106eea !important;
}
.gray-color {
  color: #999;
}
ul.listing-style li {
  position: relative;
  padding-left: 30px;
  line-height: 34px;
  font-weight: 500;
  font-size: 16px;
}
ul.listing-style li:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: "\f058";
  font-family: 'FontAwesome';
  color: #106eea;
  font-size: 20px;
}
ul.listing-style li:last-child {
  margin: 0 !important;
}
ul.listing-style.regular li {
  font-weight: 400;
}
ul.listing-style.regular2 li {
  font-weight: 400;
  margin-bottom: 7px;
}
ul.listing-style.regular2 li:before {
  font-size: 24px;
  top: -2px;
}
ul.listing-style2 li {
  position: relative;
  padding-left: 30px;
  line-height: 2em;
  font-size: 16px;
  margin-bottom: 15px;
}
ul.listing-style2 li:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: "\f05d";
  font-family: 'FontAwesome';
  color: #106eea;
  font-size: 22px;
}
ul.listing-style2 li:last-child {
  margin-bottom: 0 !important;
}
ul.listing-style2.modify li {
  padding-left: 25px;
  margin-bottom: 8px;
}
ul.listing-style2.modify li:before {
  content: "\f105";
}
ul.page-nav-vertical li {
  margin-bottom: 13px;
}
ul.page-nav-vertical li a {
  display: block;
  border: 2px solid #d3e1fb;
  padding: 16px 18px;
  transition: all .5s ease;
  border-radius: 5px;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  color: #444;
  position: relative;
}
ul.page-nav-vertical li a:before {
  content: "\f113";
  position: absolute;
  right: 12px;
  top: 16px;
  font-family: Flaticon;
  color: #1c1b1b;
  transition: all .5s ease;
}
ul.page-nav-vertical li.active a {
  color: #ffffff;
  border-color: #106eea;
  background: #106eea;
}
ul.page-nav-vertical li.active a:before {
  color: #fff;
}
ul.page-nav-vertical li:hover a {
  color: #ffffff;
  border-color: #106eea;
  background: #106eea;
}
ul.page-nav-vertical li:hover a:before {
  color: #fff;
}
ul.page-nav-vertical li:last-child {
  margin: 0;
}
.cl-skill-bar .skillbar-title {
  font-weight: 600;
  color: #212121;
  font-size: 14px;
}
.cl-skill-bar .skillbar {
  background: #eeeeee;
  height: 10px;
  margin: 17px 0 24px;
  position: relative;
  width: 100%;
}
.cl-skill-bar .skillbar .skillbar-bar {
  background: #106eea;
  height: 10px;
  width: 0;
  margin-bottom: 0;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -45px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.cl-skill-bar.style2 .skillbar {
  height: 8px;
  color: #ffffff;
}
.cl-skill-bar.style2 .skillbar .skillbar-bar {
  background: #106eea;
  height: 8px;
  background-image: linear-gradient(160deg, #97a5f3 0%, #102cc8 100%);
}
.cl-skill-bar.style2 .skillbar .skillbar-bar.orange-dark {
  background-image: linear-gradient(160deg, #e68008 0%, #e31313 100%);
}
.cl-skill-bar.style2.solutions .skillbar-title {
  color: #ffffff;
}
.cl-skill-bar.style2.solutions .skillbar {
  height: 10px;
}
.cl-skill-bar.style2.solutions .skillbar .skill-bar-percent {
  color: #ffffff;
}
.cl-skill-bar.style2.solutions .skillbar .skillbar-bar {
  height: 10px;
  line-height: 10px;
  background: #FFFFFF;
  background-color: #1273EB;
}
.cl-skill-bar.style2.marketing .skillbar-title {
  color: #212121;
}
.cl-skill-bar.style2.marketing .skillbar {
  height: 10px;
}
.cl-skill-bar.style2.marketing .skillbar .skill-bar-percent {
  color: #212121;
}
.cl-skill-bar.style2.marketing .skillbar .skillbar-bar {
  height: 10px;
  line-height: 10px;
  background: #FFFFFF;
  background-color: #bf0505;
}
.humburger {
  max-width: 45px;
  display: flex !important;
  flex-wrap: wrap;
  margin: -5px;
}
.humburger span {
  height: 5px;
  width: 5px;
  display: block;
  background: #fff;
  border-radius: 50%;
  transition: none;
  list-style: none;
  transition: all 0.3s ease;
  margin: 4px 5px;
}
.humburger span.dot2,
.humburger span.dot4,
.humburger span.dot6,
.humburger span.dot8 {
  background: #ffd900;
}
.humburger:hover span {
  background: #106eea;
}
.humburger:hover span.dot2,
.humburger:hover span.dot4,
.humburger:hover span.dot6,
.humburger:hover span.dot8 {
  background: rgb(253, 0, 0);
}
.addd {
  background: url(./images/addd.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 50px 25px 53px;
  border-radius: 5px;
}
.addd .contact-icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 100%;
  background: #ffffff;
  color: #106eea;
  font-size: 25px;
  margin: 0 auto 34px;
  position: relative;
}
.addd .contact-icon i {
  position: relative;
  z-index: 1;
}
.addd .contact-icon:after {
  background: #ffffff;
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 2s ease-out infinite;
}
.addd .title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 18px;
}
.addd .contact a {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}
.addd .contact.white a {
  color: #ffffff;
}
.addd .contact.white a:hover {
  color: #e6e6e6;
}
.brochures {
  background: #f1f6fc;
  padding: 35px 30px 45px;
}
.sidebar-form {
  padding: 40px 30px;
  background: url(./images/bg/sidebar-form.jpg);
  background-repeat: no-repeat;
}
.sidebar-form input,
.sidebar-form textarea {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 30px;
  color: #ffffff;
  background: rgba(10, 56, 112, 0.25);
  font-size: 16px;
  padding: 10.5px 20px;
}
.sidebar-form textarea {
  display: inherit;
  height: 120px;
}
.sidebar-form button.readon {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: #106eea;
  background: #ffffff;
}
.sidebar-form button.readon:hover {
  background: #e6e6e6;
}
.sidebar-form ::-webkit-input-placeholder {
  color: #ffffff;
}
.sidebar-form :-ms-input-placeholder {
  color: #ffffff;
}
.sidebar-form ::placeholder {
  color: #ffffff;
}
.sidebar-grid {
  padding: 40px 30px;
}
.sidebar-grid .sidebar-title .title {
  color: #1c1b1b;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-bottom: 12px;
  margin-bottom: 30px;
}
.sidebar-grid .sidebar-title .title:after {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #106eea;
  z-index: 1;
  margin-left: 0;
  bottom: 0;
  left: 0;
}
.sidebar-grid.sidebar-search .search-bar {
  position: relative;
}
.sidebar-grid.sidebar-search .search-bar input {
  border: 1px solid #ddd;
  color: #444444;
  padding: 12px 17px;
  width: 100%;
  border-radius: 5px;
}
.sidebar-grid.sidebar-search .search-bar button {
  outline: none;
  border: none;
  color: #1c1b1b;
  padding: 0 16px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 1;
  transition: all 0.3s ease;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  background: transparent;
}
.sidebar-grid.sidebar-search .search-bar button i:before {
  font-size: 18px;
  font-weight: 600;
}
.sidebar-grid.sidebar-search .search-bar button:hover {
  color: #106eea;
}
.sidebar-grid.sidebar-popular-post .single-post {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
}
.sidebar-grid.sidebar-popular-post .single-post .post-image {
  margin-right: 15px;
}
.sidebar-grid.sidebar-popular-post .single-post .post-image img {
  max-width: 80px;
  border-radius: 3px;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc ul li {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  color: #555;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc ul li i {
  margin-right: 5px;
  color: #106eea;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc ul li:last-child {
  margin-right: 0;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc .post-title h5 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 0;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc .post-title h5 a {
  color: #1c1b1b;
}
.sidebar-grid.sidebar-popular-post .single-post .post-desc .post-title h5 a:hover {
  color: #106eea;
}
.sidebar-grid.sidebar-categories ul li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}
.sidebar-grid.sidebar-categories ul li a {
  color: #363636;
  transition: all 0.3s ease;
}
.sidebar-grid.sidebar-categories ul li a:hover {
  color: #106eea;
}
.sidebar-grid.sidebar-categories ul li:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #1c1b1b;
}
.sidebar-grid.sidebar-categories ul li:last-child {
  margin: 0;
}
.sidebar-grid.sidebar-price-filter .bottom-part {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-top: 29px;
}
.sidebar-grid.sidebar-price-filter .bottom-part .filter-btn a {
  padding: 12px 32px;
  z-index: 1;
  position: relative;
  font-size: 12px;
  background: #106eea;
  color: #ffffff;
  border-radius: 3px;
}
.sidebar-grid.sidebar-price-filter .bottom-part .rangevalue {
  font-size: 14px;
  padding-top: 3px;
}
.sidebar-grid.sidebar-tags .tagcloud a {
  display: inline-block;
  margin: 10px 3px 0 0;
  line-height: 20px;
  padding: 6px 12px;
  font-size: 13px;
  text-transform: capitalize;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background: #fff;
  color: #444;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
.sidebar-grid.sidebar-tags .tagcloud a:hover {
  background: #106eea;
  color: #ffffff;
  border-color: #106eea;
}
.pagenav-link ul {
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  display: inline-block;
  padding: 0 3px;
}
.pagenav-link ul li {
  display: inline-block;
  padding: 0 18px;
  margin: 10px 0;
  border-right: 1px solid #E6E6E6;
  color: #106eea;
  font-size: 16px;
}
.pagenav-link ul li a {
  color: #363636;
}
.pagenav-link ul li a i:before {
  color: #363636;
  font-size: 13px;
  transition: all 0.3s ease;
}
.pagenav-link ul li a:hover {
  color: #106eea;
}
.pagenav-link ul li a:hover i:before {
  color: #106eea;
}
.pagenav-link ul li:last-child {
  border-right: none;
}
.rs-carousel.nav-style1 {
  position: relative;
}
.rs-carousel.nav-style1 .owl-nav {
  display: block;
}
.rs-carousel.nav-style1 .owl-nav .owl-next,
.rs-carousel.nav-style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #106eea;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before,
.rs-carousel.nav-style1 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-carousel.nav-style1 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next,
.rs-carousel.nav-style1:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-carousel.nav-style1.nav-mod .owl-nav {
  display: block;
}
.rs-carousel.nav-style1.nav-mod .owl-nav .owl-next,
.rs-carousel.nav-style1.nav-mod .owl-nav .owl-prev {
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
  left: 30px;
}
.rs-carousel.nav-style1.nav-mod .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-carousel.nav-style1.nav-mod:hover .owl-nav .owl-next,
.rs-carousel.nav-style1.nav-mod:hover .owl-nav .owl-prev {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-carousel.nav-style2 {
  position: relative;
}
.rs-carousel.nav-style2 .owl-nav {
  display: block;
  position: absolute;
  top: -80px;
  right: 0;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev,
.rs-carousel.nav-style2 .owl-nav .owl-next {
  display: inline-block;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i,
.rs-carousel.nav-style2 .owl-nav .owl-next i {
  transition: all 0.3s ease;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before,
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  font-family: Flaticon;
  font-size: 22px;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:hover i,
.rs-carousel.nav-style2 .owl-nav .owl-next:hover i {
  color: #106eea;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before {
  content: "\f134";
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:after {
  content: "/";
  padding: 0 5px 0 5px;
  position: relative;
  top: -3px;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  content: "\f133";
}
.rs-carousel.dot-style1 .owl-dots {
  text-align: center;
}
.rs-carousel.dot-style1 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  border-radius: 50px;
  margin: 0 5px;
  background: #106eea;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  opacity: .5;
}
.rs-carousel.dot-style1 .owl-dots .owl-dot:hover {
  width: 40px;
  opacity: 1;
}
.rs-carousel.dot-style1 .owl-dots .owl-dot.active {
  width: 40px;
  opacity: 1;
}
body.home-three .readon {
  padding: 13px 28px;
  font-size: 15px;
  font-weight: 400;
}
body.home-six .loader .spinner {
  background-color: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li i {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li a {
  color: #363636;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li a:hover {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .social li a i {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger span {
  background: #1c1b1b;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger span.dot2,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger span.dot4,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger span.dot6,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger span.dot8 {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger:hover span {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot2,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot4,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot6,
body.home-six .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot8 {
  background: #1c1b1b;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #67c27c !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #67c27c !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #67c27c !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #67c27c !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu .sub-menu li.active a {
  color: #ccc !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot2,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot4,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot6,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot2,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot4,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot6,
body.home-six .full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot8 {
  background: #ffffff;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .appointment-cart ul.cart li i:hover {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #101010;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span {
  background: #67c27c;
}
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot2,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot4,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot6,
body.home-six .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot8 {
  background: #101010;
}
body.home-six .readon {
  background: #67c27c;
  border-radius: 30px;
  color: #ffffff;
  padding: 13px 30px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
}
body.home-six .readon:hover {
  background: #79c98c;
}
body.home-six .readon.active {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
body.home-six .readon.active:hover {
  border: 1px solid #ffffff;
}
body.home-six .readon.sl {
  font-weight: 600;
}
body.home-six .sec-title.style2 {
  align-items: center;
}
body.home-six .sec-title.style2 .title-part {
  border-color: #67c27c;
}
body.home-six .sec-title.style2 .first-half {
  border-color: #67c27c;
  max-width: 50%;
  flex: 0 0 50%;
  border-width: 4px;
}
body.home-six .sec-title.style2 .last-half {
  max-width: 50%;
  flex: 0 0 50%;
}
body.home-six ul.listing-style li:before {
  color: #67c27c;
}
body.home-six #scrollUp i {
  background: #67c27c;
}
body.home-six #scrollUp i:hover {
  background-color: #67c27c;
}
body.home-six .rs-casestudies.style1 .portfolio-item .content-part .title a:hover {
  color: #67c27c;
}
body.home-six .rs-contact.style1 .contact-info {
  height: auto;
}
body.home-six .rs-contact.style1 .contact-info,
body.home-six .rs-carousel.dot-style1 .owl-dots .owl-dot {
  background: #67c27c;
}
body.home-six .rs-footer {
  background-image: url(./images/bg/footer-bg.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
body.home-six .rs-footer .footer-newsletter {
  border-color: rgba(255, 255, 255, 0.15);
}
body.home-six .rs-footer .footer-newsletter .newsletter-form input {
  border-color: #67c27c;
}
body.home-six .rs-footer .footer-newsletter .newsletter-form button {
  background: #67c27c;
}
body.home-six .rs-footer .footer-content .widget-title {
  color: #67c27c;
}
body.home-six .rs-footer .footer-content .about-widget .btn-part a.readon {
  padding: 16px 24px;
  border-radius: 3px;
}
body.home-six .rs-footer .footer-content .address-widget li i:before {
  color: #67c27c;
}
body.home-six .rs-footer .footer-content .address-widget li .desc a {
  color: #e0e0e0;
}
body.home-six .rs-footer .footer-content .address-widget li .desc a:hover {
  color: #67c27c;
}
body.home-six .rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #ffffff;
}
body.home-six .rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #67c27c;
}
body.home-six .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #67c27c;
}
body.home-six .rs-footer .footer-bottom {
  border-color: rgba(255, 255, 255, 0.15);
}
body.home-six .rs-footer .footer-bottom .footer-social li a {
  background: #67c27c;
}
body.home-six .rs-footer .footer-bottom .footer-social li a:hover {
  background: #67c27c;
}
body.home-seven {
  color: #485973;
}
body.home-seven h1,
body.home-seven h2,
body.home-seven h3,
body.home-seven h4,
body.home-seven h5,
body.home-seven h6 {
  color: #05275a;
}
body.home-seven h2 {
  font-size: 36px;
  line-height: 48px;
}
body.home-seven .rs-footer {
  background-image: url(./images/bg/footer-bg2.jpg);
}
body.home-seven .rs-footer .footer-content .about-widget .desc {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .about-widget .btn-part a.readon {
  padding: 12.5px 24px;
  border-radius: 3px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: 'Roboto', sans-serif;
  color: #106eea;
}
body.home-seven .rs-footer .footer-content .about-widget .btn-part a.readon:hover {
  background: #f2f2f2;
}
body.home-seven .rs-footer .footer-content .address-widget .desc {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .address-widget li i:before {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .address-widget li .desc a {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .address-widget li .desc a:hover {
  color: #106eea;
}
body.home-seven .rs-footer .footer-content .footer-post .post-wrap .post-desc {
  line-height: 22px;
}
body.home-seven .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #ffffff;
  margin-bottom: 6px;
  display: block;
}
body.home-seven .rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #ffffff;
}
body.home-seven .rs-footer .footer-bottom {
  border-color: rgba(255, 255, 255, 0.31);
}
body.home-seven .rs-footer .footer-bottom .footer-social li a {
  background: #ffffff;
  color: #106eea;
}
body.home-seven .rs-footer .footer-bottom .footer-social li a:hover {
  background: #f2f2f2;
  color: #106eea;
}
body.home-seven .rs-footer .footer-bottom .copyright p {
  color: #ffffff;
}
body.home-eight .loader .spinner {
  background-color: #1c3988;
}
body.home-eight h2 {
  font-size: 36px;
  line-height: 48px;
}
body.home-eight .full-width-header .toolbar-area {
  background: #1c3988;
}
body.home-eight .full-width-header .toolbar-area .toolbar-contact ul li {
  border-color: #052887;
}
body.home-eight .full-width-header .toolbar-area .toolbar-sl-share ul li.opening {
  border-color: #052887;
}
body.home-eight .full-width-header .right_menu_togle .canvas-contact .contact li i {
  color: #1c3988;
}
body.home-eight .full-width-header .right_menu_togle .canvas-contact .contact li a {
  color: #363636;
}
body.home-eight .full-width-header .right_menu_togle .canvas-contact .contact li a:hover {
  color: #1c3988;
}
body.home-eight .full-width-header .right_menu_togle .social li a i {
  background: #1c3988 !important;
}
body.home-eight .full-width-header .right_menu_togle .humburger span {
  background: #1c1b1b;
}
body.home-eight .full-width-header .right_menu_togle .humburger span.dot2,
body.home-eight .full-width-header .right_menu_togle .humburger span.dot4,
body.home-eight .full-width-header .right_menu_togle .humburger span.dot6,
body.home-eight .full-width-header .right_menu_togle .humburger span.dot8 {
  background: #1c3988;
}
body.home-eight .full-width-header .right_menu_togle .humburger:hover span {
  background: #1c3988;
}
body.home-eight .full-width-header .right_menu_togle .humburger:hover span.dot2,
body.home-eight .full-width-header .right_menu_togle .humburger:hover span.dot4,
body.home-eight .full-width-header .right_menu_togle .humburger:hover span.dot6,
body.home-eight .full-width-header .right_menu_togle .humburger:hover span.dot8 {
  background: #1c1b1b;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #1c3988 !important;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #1c3988 !important;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #1c3988 !important;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #1c3988 !important;
}
body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu .sub-menu li.active a {
  color: #ccc !important;
}
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger span {
  background: #101010;
}
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot2,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot4,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot6,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot2,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot4,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot6,
body.home-eight .full-width-header .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot8 {
  background: #101010;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .appointment-cart ul.cart li i:hover {
  color: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #101010;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span {
  background: #1c3988;
}
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot2,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot4,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot6,
body.home-eight .full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot8 {
  background: #101010;
}
body.home-eight .rs-footer {
  background-image: url(./images/bg/footer-bg3.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
body.home-eight .rs-footer .footer-content .widget-title {
  color: #1c3988;
}
body.home-eight .rs-footer .footer-content .about-widget .desc {
  color: #333;
}
body.home-eight .rs-footer .footer-content .about-widget .btn-part a.readon {
  padding: 12px 23px;
  border-radius: 3px;
  background: #1c3988;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
body.home-eight .rs-footer .footer-content .address-widget li i:before {
  color: #1c3988;
}
body.home-eight .rs-footer .footer-content .address-widget li .desc {
  color: #333;
}
body.home-eight .rs-footer .footer-content .address-widget li .desc a {
  color: #333;
}
body.home-eight .rs-footer .footer-content .address-widget li .desc a:hover {
  color: #1c3988;
}
body.home-eight .rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #1c1b1b;
}
body.home-eight .rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #1c3988;
}
body.home-eight .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #333;
}
body.home-eight .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #1c3988;
}
body.home-eight .rs-footer .footer-bottom {
  border-color: rgba(28, 57, 136, 0.15);
}
body.home-eight .rs-footer .footer-bottom .copyright p {
  color: #333;
}
body.home-eight .rs-footer .footer-bottom .footer-social li a {
  background: #1c3988;
}
body.home-eight .rs-footer .footer-bottom .footer-social li a:hover {
  background: #1c3988;
}
body.home-nine {
  color: #555555;
}
body.home-nine h1,
body.home-nine h2,
body.home-nine h3,
body.home-nine h4,
body.home-nine h5,
body.home-nine h6 {
  color: #333333;
}
body.home-nine h2 {
  font-size: 36px;
  line-height: 47px;
}
body.home-nine .readon {
  background: #ec4c6f;
}
body.home-nine .readon:hover {
  background: #ee6382;
}
body.home-nine .loader .spinner {
  background-color: #ec4c6f;
}
body.home-nine #scrollUp i {
  background: #ec4c6f;
}
body.home-nine .rs-carousel.dot-style1 .owl-dots .owl-dot {
  background: #ec4c6f;
}
body.home-nine .rs-footer {
  background-image: url(./images/bg/footer-bg4.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
body.home-nine .rs-footer .footer-newsletter,
body.home-nine .rs-footer .footer-newsletter .newsletter-form input,
body.home-nine .rs-footer .footer-bottom {
  border-color: #e9e9ea;
}
body.home-nine .rs-footer .footer-newsletter .newsletter-form input {
  color: #363636;
}
body.home-nine .rs-footer .footer-newsletter .newsletter-form button {
  background: #ec4c6f;
}
body.home-nine .rs-footer .footer-newsletter .newsletter-form ::placeholder {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .widget-title {
  color: #333333;
}
body.home-nine .rs-footer .footer-content .about-widget .desc {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .address-widget li i:before {
  color: #ec4c6f;
}
body.home-nine .rs-footer .footer-content .address-widget li .desc {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .address-widget li .desc a {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .address-widget li .desc a:hover {
  color: #ec4c6f;
}
body.home-nine .rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #ec4c6f;
}
body.home-nine .rs-footer .footer-content .footer-post .post-wrap .date-post {
  color: #363636;
}
body.home-nine .rs-footer .footer-content .footer-post .post-wrap .date-post i {
  color: #ec4c6f;
}
body.home-nine .rs-footer .footer-bottom .copyright p {
  color: #363636;
}
body.home-nine .rs-footer .footer-bottom .footer-social li a {
  background: #ec4c6f;
}
body.home-ten .loader .spinner {
  background-color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li i {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li a {
  color: #363636;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .canvas-contact .contact li a:hover {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .social li a i {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger span {
  background: #1c1b1b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger span.dot2,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger span.dot4,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger span.dot6,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger span.dot8 {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger:hover span {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot2,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot4,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot6,
body.home-ten .full-width-header.header-style2 .right_menu_togle .humburger:hover span.dot8 {
  background: #1c1b1b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area {
  justify-content: flex-end;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.sub-menu {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu {
  background: #b1976b;
  right: -140px;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu .sub-menu li.active a {
  color: #ccc !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li > a:hover {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot2,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot4,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot6,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger:hover span {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger:hover span.dot2,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger:hover span.dot4,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger:hover span.dot6,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger:hover span.dot8 {
  background: #ffffff;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .appointment-cart ul.cart li i:hover {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #b1976b;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #494949 !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span {
  background: #b1976b !important;
}
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot2,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot4,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot6,
body.home-ten .full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot8 {
  background: #494949 !important;
}
body.home-ten .readon {
  background: #b1976b;
  border-radius: 0;
  color: #ffffff;
  padding: 13.5px 28px 15.5px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}
body.home-ten .readon:hover {
  background: #997e50;
}
body.home-ten .sec-title.style2 {
  align-items: center;
}
body.home-ten .sec-title.style2 .title-part {
  border-color: #b1976b;
}
body.home-ten .sec-title.style2 .first-half {
  border-color: #b1976b;
  max-width: 50%;
  flex: 0 0 50%;
  border-width: 4px;
}
body.home-ten .sec-title.style2 .last-half {
  max-width: 50%;
  flex: 0 0 50%;
}
body.home-ten ul.listing-style li:before {
  color: #b1976b;
}
body.home-ten #scrollUp i {
  background: #b1976b;
}
body.home-ten #scrollUp i:hover {
  background-color: #b1976b;
}
body.home-ten .rs-carousel.dot-style1 .owl-dots .owl-dot {
  background: #b1976b;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .img-part img {
  border-radius: 5px 5px 0 0;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .img-part .fly-btn a {
  background: #b1976b;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
body.home-ten .rs-blog.style1.modify .blog-wrap .img-part .fly-btn a:hover {
  background: #a88b5a;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .content-part {
  border-radius: 0 0 5px 5px;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .content-part a.categories {
  color: #b1976b;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .content-part .title a:hover {
  color: #b1976b;
}
body.home-ten .rs-blog.style1.modify .blog-wrap .content-part .blog-meta .date i {
  color: #b1976b;
}
body.home-ten .rs-footer {
  background: #282e3f;
}
body.home-ten .rs-footer .footer-newsletter {
  border-color: rgba(55, 62, 77, 0.57);
}
body.home-ten .rs-footer .footer-newsletter .title {
  font-size: 36px;
  line-height: 1;
}
body.home-ten .rs-footer .footer-newsletter .newsletter-form input {
  border-color: #40485b;
}
body.home-ten .rs-footer .footer-newsletter .newsletter-form button {
  background: #b1976b;
}
body.home-ten .rs-footer .footer-content .widget-title {
  color: #ffffff;
}
body.home-ten .rs-footer .footer-content .about-widget .btn-part a.readon {
  padding: 16px 24px;
  border-radius: 3px;
}
body.home-ten .rs-footer .footer-content .address-widget li i:before {
  color: #b1976b;
}
body.home-ten .rs-footer .footer-content .address-widget li .desc a {
  color: #e0e0e0;
}
body.home-ten .rs-footer .footer-content .address-widget li .desc a:hover {
  color: #b1976b;
}
body.home-ten .rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #ffffff;
}
body.home-ten .rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #b1976b;
}
body.home-ten .rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #b1976b;
}
body.home-ten .rs-footer .footer-bottom {
  border-color: rgba(55, 62, 77, 0.57);
}
body.home-ten .rs-footer .footer-bottom .footer-social li a {
  background: #b1976b;
}
body.home-ten .rs-footer .footer-bottom .footer-social li a:hover {
  background: #b1976b;
}
body.home-eleven {
  color: #676767;
}
body.home-eleven h1,
body.home-eleven h2,
body.home-eleven h3,
body.home-eleven h4,
body.home-eleven h5,
body.home-eleven h6 {
  color: #032e42;
}
body.home-eleven h2 {
  font-size: 36px;
  line-height: 47px;
}
body.home-eleven .readon {
  background: #d83030;
}
body.home-eleven .readon:hover {
  background: #dc4545;
}
body.home-eleven .loader .spinner {
  background-color: #d83030;
}
body.home-eleven #scrollUp i {
  background: #d83030;
}
body.home-eleven .rs-carousel.dot-style1 .owl-dots .owl-dot {
  background: #d83030;
}
body.home-eleven .full-width-header .toolbar-area {
  background: #032e42;
  position: relative;
  overflow: hidden;
}
body.home-eleven .full-width-header .toolbar-area .toolbar-contact ul li {
  font-size: 15px;
  padding-top: 14px;
  padding-bottom: 12px;
  border-color: #224355;
}
body.home-eleven .full-width-header .toolbar-area .toolbar-contact ul li i {
  color: #d83030;
}
body.home-eleven .full-width-header .toolbar-area .toolbar-sl-share {
  position: relative;
  z-index: 1;
}
body.home-eleven .full-width-header .toolbar-area .toolbar-sl-share ul li {
  padding: 0;
  border-color: #224355;
  font-size: 15px;
}
body.home-eleven .full-width-header .toolbar-area:after {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  width: 33%;
  height: 101%;
  background: #d83030;
  transform: skew(-20deg);
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner {
  display: flex;
  align-items: center;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li {
  display: inline-block;
  margin-right: 16px;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a {
  color: #d83030;
  display: inline-block;
  cursor: pointer;
  position: relative;
  height: 115px;
  line-height: 115px;
  padding-right: 20px;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a i {
  font-size: 18px;
  font-weight: 700;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a i:before {
  font-size: 18px;
  font-weight: 700;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a i.flaticon-bag {
  position: relative;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a i.flaticon-bag:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 6px;
  width: 100%;
  height: 1px;
  background: #ffffff;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a:hover {
  color: #d83030;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li > a:before {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 45px;
  background: #d83030;
  opacity: 0.2;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li:last-child {
  margin-right: 0;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li:last-child > a {
  padding-right: 0;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner ul > li:last-child > a:before {
  display: none;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner .head-btn a {
  font-size: 14px;
  padding: 13px 28px;
  font-weight: 500;
  text-transform: uppercase;
  background: #d83030;
  color: #ffffff;
  border-radius: 30px;
}
body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .expand-btn-inner .head-btn a:hover {
  background: #dc4545;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  padding: 0 30px;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul > li > a,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul > li > a:before {
  color: #676767;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul > li > a:hover,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul > li > a:hover:before,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #d83030 !important;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #1c1b1b;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active > a {
  color: #d83030 !important;
}
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title,
body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #1c1b1b;
}
body.home-eleven .rs-footer {
  background: transparent;
}
body.home-eleven .rs-footer .footer-newsletter {
  border: none;
}
body.home-eleven .rs-footer .footer-newsletter {
  padding: 70px 0;
}
body.home-eleven .rs-footer .footer-newsletter .title {
  font-size: 36px;
  line-height: 32px;
  font-weight: 700;
}
body.home-eleven .rs-footer .footer-newsletter .newsletter-form {
  width: 100%;
  max-width: 100%;
}
body.home-eleven .rs-footer .footer-newsletter .newsletter-form input {
  color: #363636;
  max-width: 390px;
  border-radius: 5px;
  border-color: #ffffff;
  background: #ffffff;
}
body.home-eleven .rs-footer .footer-newsletter .newsletter-form button {
  background: #032e42;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
}
body.home-eleven .rs-footer .footer-newsletter .newsletter-form ::placeholder {
  color: #a4a4a4;
}
body.home-eleven .rs-footer .bg-wrap {
  background: url(./images/bg/footer-bg5.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .widget-title {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .about-widget .desc {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .address-widget li i:before {
  color: #d83030;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .address-widget li .desc {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .address-widget li .desc a {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .address-widget li .desc a:hover {
  color: #d83030;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .footer-post .post-wrap .post-desc a {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #d83030;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .footer-post .post-wrap .date-post {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-content .footer-post .post-wrap .date-post i {
  color: #d83030;
}
body.home-eleven .rs-footer .bg-wrap .footer-bottom {
  border-color: #234759;
}
body.home-eleven .rs-footer .bg-wrap .footer-bottom .copyright p {
  color: #ffffff;
}
body.home-eleven .rs-footer .bg-wrap .footer-bottom .footer-social li a {
  background: #ffffff;
  color: #d83030;
}
body.home-eleven .rs-footer .bg-wrap .footer-bottom .footer-social li a:hover {
  background: #d83030;
  color: #ffffff;
}
body.apps .loader .spinner {
  background-color: #ec4c6f;
}
body.apps #scrollUp i {
  background: #ec4c6f;
}
body.marketing .loader .spinner {
  background-color: #bf0505;
}
body.marketing #scrollUp i {
  background: #bf0505;
}
.hoverparallax {
  transform: rotateX(0) rotateY(0);
  transform-style: preserve-3d;
  transition-duration: 0.1s;
  transition-timing-function: ease !important;
}
.hoverparallax.is-out {
  transform: rotateX(0) rotateY(0) !important;
  transition-duration: 1s;
}
.morphin {
  border-color: #d83030;
  background: #d83030;
}
.relative {
  position: relative;
}
.sec-title .sub-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.sec-title .sub-title.modify {
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: unset;
}
.sec-title .sub-title.primary {
  color: #106eea;
}
.sec-title .sub-title.primary:after {
  background: #106eea;
}
.sec-title .sub-title.secondary {
  color: #228bfd;
}
.sec-title .sub-title.secondary:after {
  background: #228bfd;
}
.sec-title .sub-title.green {
  color: #67c27c;
}
.sec-title .sub-title.green:after {
  background: #67c27c;
}
.sec-title .sub-title.white {
  color: #ffffff;
}
.sec-title .sub-title.white:after {
  background: #ffffff;
}
.sec-title .sub-title.dark {
  color: #1c1b1b;
}
.sec-title .sub-title.dark:after {
  background: #1c1b1b;
}
.sec-title .sub-title.right-line {
  padding-right: 65px;
  position: relative;
  display: inline-block;
}
.sec-title .sub-title.right-line:after {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  z-index: 1;
  margin-left: 0;
  right: 0;
  top: 13px;
}
.sec-title .title2 {
  font-size: 48px;
  line-height: 60px;
}
.sec-title .title3 {
  line-height: 1.4em;
}
.sec-title .desc.big {
  font-size: 18px;
  line-height: 30px;
}
.sec-title .desc2 {
  font-weight: 700;
  color: #585555;
}
.sec-title.style2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sec-title.style2 .title-part {
  padding-right: 50px;
  border-right: 2px solid #106eea;
}
.sec-title.style2 .first-half {
  width: 100%;
  max-width: 46%;
  flex: 0 0 46%;
  padding-right: 50px;
  border-right: 2px solid #106eea;
}
.sec-title.style2 .last-half {
  width: 100%;
  max-width: 54%;
  flex: 0 0 54%;
  padding-left: 50px;
}
.sec-title .bottom-wave {
  position: relative;
  padding-bottom: 38px;
}
.sec-title .bottom-wave:after {
  position: absolute;
  content: '';
  height: 23px;
  width: 118px;
  left: 0;
  bottom: 0;
  background: url(./images/waveline.png);
}
.sec-title.text-center .bottom-wave:after {
  left: 50%;
  transform: translateX(-50%);
}
.sec-title.text-right .bottom-wave:after {
  left: unset;
  right: 0;
}
.sec-title2 .sub-title {
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  color: #f67d31;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}
.sec-title2 .sub-title.primary-color {
  color: #106eea;
}
.sec-title2 .sub-title.sub-title2 {
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  color: #BF0505;
  margin-bottom: 8px;
}
.sec-title2 .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 0;
}
.sec-title2 .title.small {
  font-size: 32px;
  color: #fa3f24;
}
.sec-title2 .title.small2 {
  font-size: 24px;
  font-weight: 600;
  color: #1c1b1b;
  margin: 0px 0px 0px 0px;
}
.sec-title2 .title.title2 {
  max-width: 425px;
  margin: 0 auto;
}
.sec-title2 .title.title2.title3 {
  max-width: 480px;
}
.sec-title2 .title.title2.title4 {
  max-width: 550px;
}
.sec-title2 .title.title5 {
  font-size: 36px;
}
.sec-title2 .title.title6 {
  font-weight: 700;
  color: #032E42;
  font-family: poppins, sans-serif;
}
.sec-title2 .description p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #363636;
  margin-bottom: 0;
}
.sec-title2 .description p.right {
  padding-right: 70px;
}
.sec-title2 .description p.small {
  font-size: 16px;
}
.sec-title3 .sub-title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #B1E0F9;
  display: block;
  margin-bottom: 10px;
}
.sec-title3 .sub-title.small {
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 2px;
}
.sec-title3 .title {
  font-size: 36px;
  line-height: 1.3em;
  font-weight: 600;
  color: #111111;
  margin-bottom: 0;
}
.sec-title3 .title.title2 {
  max-width: 450px;
}
.sec-title3 .title.title3 {
  max-width: 550px;
  margin: 0 auto;
}
.sec-title3 .title.title4 {
  color: #ffffff;
  font-weight: 700;
}
.sec-title3 .desc {
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 0;
}
.sec-title3 .description {
  color: #666666;
  margin-bottom: 0;
}
.sec-title4 .icon-part {
  width: 70px;
  margin: 0 auto 40px;
}
.sec-title4 .sub-title {
  font-family: poppins, sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #090E40;
  display: block;
  margin-bottom: 0;
}
.sec-title4 .sub-title.new {
  color: #1273EB;
  font-family: poppins, sans-serif;
  letter-spacing: 1px;
}
.sec-title4 .title {
  font-size: 36px;
  line-height: 40px;
  color: #0a0a0a;
  font-weight: 700;
  margin-bottom: 0;
}
.sec-title4 .title .first-title {
  display: block;
}
.sec-title4 .title .second-title {
  color: #090E40;
}
.sec-title4 .title.title2 {
  max-width: 500px;
  line-height: 48px;
  margin: 0 auto;
}
.sec-title4 .title.title3 {
  color: #1c1b1b;
  font-family: poppins, sans-serif;
  max-width: 670px;
  margin: 0 auto;
}
.sec-title4 .desc {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: #363636;
  margin-bottom: 0;
}
.sec-title5 .sub-title {
  font-size: 26px;
  line-height: 28px;
  font-weight: 500;
  font-family: poppins, sans-serif;
  text-transform: uppercase;
  color: #1C3988;
  display: block;
  margin: 0;
}
.sec-title5 .sub-title.new-title {
  color: #1273EB;
  font-family: poppins, sans-serif;
  letter-spacing: 1px;
}
.sec-title5 .sub-title.sub-title2 {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  font-family: "poppins", Sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.sec-title5 .title {
  font-size: 42px;
  line-height: 1.3em;
  font-weight: 700;
  color: #04004D;
  margin: 0;
}
.sec-title5 .title.title2 {
  font-size: 36px;
  line-height: 47px;
  color: #1c1b1b;
}
.sec-title5 .desc {
  font-size: 18px;
  font-weight: 500;
  color: #555555;
  margin: 0;
}
.sec-title5 .description {
  max-width: 500px;
  margin: 0 auto;
}
.sec-title5 .description.onepage {
  max-width: 630px;
}
.sec-title5 .description.solutions {
  max-width: 540px;
}
.sec-title6 .sub-title {
  position: relative;
  padding-left: 65px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #BF0505;
  margin: 0px 0px 6px 0px;
  display: inline-block;
}
.sec-title6 .sub-title:after {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #BF0505;
  z-index: 1;
  margin-left: 0;
  left: 0;
  top: 14px;
}
.sec-title6 .sub-title2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #BF0505;
  margin: 0px 0px 6px 0px;
  position: relative;
  display: inline-block;
}
.sec-title6 .sub-title2:before {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #BF0505;
  z-index: 1;
  margin-left: 0;
  left: -55px;
  top: 15px;
}
.sec-title6 .sub-title2:after {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #BF0505;
  z-index: 1;
  margin-left: 0;
  left: auto;
  right: -55px;
  top: 15px;
}
.sec-title6 .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.3em;
  color: #111111;
  margin: 0px 0px 30px 0px;
}
.sec-title6 .title span {
  color: #BF0505;
  font-family: "Playfair Display", Sans-serif;
  font-weight: 700;
  font-style: italic;
}
.sec-title6 .desc-big {
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  margin: 0px 100px 25px 0px;
}
.sec-title6 .desc-big.style2 {
  margin: 0px 0px 25px 0px;
}
.sec-title7 .title {
  font-size: 36px;
  line-height: 40px;
  color: #1c1b1b;
  font-family: poppins, sans-serif;
  font-weight: 700;
  position: relative;
  margin-bottom: 15px;
}
.sec-title7 .title .watermark {
  position: absolute;
  font-size: 200px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: poppins, sans-serif;
  opacity: .05;
  width: 100%;
  left: 0;
  color: inherit;
  white-space: nowrap;
  vertical-align: middle;
}
.sec-title7 .title2 {
  font-size: 24px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0px 0px 8px 0px;
}
.sec-title7 .sub-text {
  color: #ffffff;
  font-weight: 700;
}
.sec-title7 .sub-title {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #1273eb;
  font-weight: 500;
  margin: 0 0 10px;
  font-family: poppins, sans-serif;
  text-transform: uppercase;
  display: block;
}
.custom-for-sl .rs-carousel .owl-stage-outer .owl-item {
  padding-right: 15px;
  padding-left: 15px;
}
input[type=range] {
  -webkit-appearance: none;
  margin: 0px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ddd;
}
input[type=range]:focus::-ms-fill-lower {
  background: #106eea;
}
input[type=range]:focus::-ms-fill-upper {
  background: #106eea;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.39px;
  border-radius: 1em;
  cursor: pointer;
  background: #ddd;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.39px;
  border-radius: 1em;
  cursor: pointer;
  background: #ddd;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.39px;
  border-radius: 1em;
  cursor: pointer;
  background: #ddd;
}
input[type=range]::-webkit-slider-thumb {
  height: 1em;
  width: 1em;
  border: none;
  border-radius: 100%;
  background: #106eea;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]::-moz-range-thumb {
  height: 1em;
  width: 1em;
  border: none;
  border-radius: 100%;
  background: #106eea;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]::-ms-thumb {
  height: 1em;
  width: 1em;
  border: none;
  border-radius: 100%;
  background: #106eea;
  cursor: ew-resize;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]::-ms-fill-lower {
  background: #000;
}
input[type=range]::-ms-fill-upper {
  background: #000;
}
/*--------------------------------------
    03. Header Section CSS
---------------------------------------*/
.full-width-header .toolbar-area {
  background: #106eea;
}
.full-width-header .toolbar-area .toolbar-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.full-width-header .toolbar-area .toolbar-contact ul li {
  display: inline-block;
  margin-right: 20px;
  padding: 13px 20px 11px 0;
  border-right: 1px solid #1273eb;
  color: #fff;
  font-size: 14px;
}
.full-width-header .toolbar-area .toolbar-contact ul li i {
  margin-right: 7px;
  color: #ffffff;
}
.full-width-header .toolbar-area .toolbar-contact ul li i.flaticon-email:before {
  position: relative;
  bottom: -0.5px;
}
.full-width-header .toolbar-area .toolbar-contact ul li i:before {
  font-size: 14px;
}
.full-width-header .toolbar-area .toolbar-contact ul li a {
  transition: all 0.3s ease;
  color: #fff;
}
.full-width-header .toolbar-area .toolbar-contact ul li a:hover {
  color: #ccc;
}
.full-width-header .toolbar-area .toolbar-contact ul li:last-child {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
}
.full-width-header .toolbar-area .toolbar-sl-share ul {
  text-align: right;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 15px;
  padding: 11px 0;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li.opening {
  color: #ffffff;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #1273eb;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li.opening i {
  margin-right: 5px;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li.opening i:before {
  font-size: 15px;
  font-weight: 600;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li a {
  display: block;
  color: #ffffff;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li a:hover {
  color: #ccc;
}
.full-width-header .toolbar-area .toolbar-sl-share ul li:last-child {
  margin: 0;
}
.full-width-header .rs-header {
  z-index: 99;
}
.full-width-header .rs-header .menu-area {
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .logo-area {
  position: relative;
  height: 115px;
  line-height: 111px;
}
.full-width-header .rs-header .menu-area .logo-area img {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  max-height: 30px;
}
.full-width-header .rs-header .menu-area .logo-area .dark {
  display: none;
}
.full-width-header .rs-header .menu-area .logo-area .light {
  display: inherit;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 40px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  height: 115px;
  line-height: 115px;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #101010;
  position: relative;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child {
  margin-right: 0!important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child i {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children a {
  padding-right: 17px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children a:before {
  position: absolute;
  content: "+";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:hover.menu-item-has-children a:before {
  content: "-";
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #106eea !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a:before {
  color: #106eea;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu li a {
  padding-right: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu li a:before {
  display: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #106eea;
  margin: 0;
  padding: 15px 0;
  border-radius: 0 0 5px 5px;
  right: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu .sub-menu {
  right: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu .current-page-item > a {
  color: #ccc;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0;
  border: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  height: unset;
  line-height: unset;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 30px !important;
  text-transform: capitalize;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:before {
  display: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.children-mega-menu .child-mega-menu {
  width: 100%;
  opacity: 1 !important;
  position: unset;
  transform: translateY(0px);
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.children-mega-menu .child-mega-menu .child-single-megamenu {
  width: 50%;
  float: left;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.children-mega-menu .child-mega-menu .child-single-megamenu .sub-menu {
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.right-menu {
  left: unset;
  right: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #106eea;
  border-radius: 0 0 5px 5px;
  min-width: 1160px;
  right: -225px;
  left: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container {
  padding: 0;
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu {
  width: 25%;
  float: left;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu {
  padding: 35px 38px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  font-size: 17px;
  font-weight: 600;
  padding: 10px 30px;
  color: #ffffff;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ccc;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.home11-style {
  right: -280px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.home11-style .mega-menu-container .single-megamenu .sub-menu {
  padding: 30px 30px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header .rs-header .menu-area .main-menu .nav-expander {
  font-size: 20px;
  -webkit-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  display: block;
  color: #92278f;
  position: absolute;
  right: 15px;
  top: 26px;
}
.full-width-header .rs-header .menu-area .main-menu .nav-expander:hover {
  transform: scale(1.1);
  color: #106eea;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li {
  display: inline-block;
  margin-right: 32px;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li.mini-cart-active {
  display: none;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li.mini-cart-active .cart-icon {
  position: relative;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li.mini-cart-active .cart-icon .cart-count {
  position: absolute;
  min-width: 18px;
  min-height: 18px;
  line-height: 18px;
  font-size: 11px;
  border-radius: 50%;
  background: #f2f2f2;
  color: #363636;
  top: -13px;
  right: -17px;
  text-align: center;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li > a {
  color: #101010;
  display: inline-block;
  cursor: pointer;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li > a i:before {
  font-size: 18px;
  font-weight: 600;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li > a i.flaticon-shopping-bag-1:before {
  font-weight: normal;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header .menu-area .expand-btn-inner .head-cta {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area .expand-btn-inner .head-cta i {
  color: #ffffff;
  margin-right: 15px;
  font-size: 30px;
  line-height: 1;
}
.full-width-header .rs-header .menu-area .expand-btn-inner .head-cta i:before {
  font-size: 30px;
  line-height: 1;
}
.full-width-header .rs-header .menu-area .expand-btn-inner .head-cta a {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
}
.full-width-header .rs-header .menu-area .expand-btn-inner .head-cta a:hover {
  color: #ccc;
}
.full-width-header .rs-header .menu-area.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff !important;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.8);
}
.full-width-header .rs-header .menu-area.sticky .logo-area {
  height: 72px;
  line-height: 68px;
}
.full-width-header .rs-header .menu-area.sticky .logo-area img {
  max-height: 30px;
}
.full-width-header .rs-header .menu-area.sticky .logo-area .dark {
  display: inherit;
}
.full-width-header .rs-header .menu-area.sticky .logo-area .light {
  display: none;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  height: 72px;
  line-height: 72px;
  color: #494949;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area.sticky .appointment-cart ul.cart li i {
  color: #494949;
}
.full-width-header .rs-header .menu-area.sticky .appointment-cart ul.cart li i:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li.search-parent {
  display: none;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #106eea;
  color: #fff;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li > a {
  color: #494949;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #106eea;
}
.full-width-header .rs-header .right_menu_togle {
  background: #ffffff;
  padding: 25px 30px 50px 50px;
  z-index: 999999;
}
.full-width-header .rs-header .right_menu_togle .close-btn {
  overflow: visible;
  padding: 0;
  text-align: right;
  margin-bottom: 17px;
}
.full-width-header .rs-header .right_menu_togle .close-btn .humburger {
  margin: 0 0 0 auto;
  background: #f1f6fc;
  padding: 10px;
  max-width: 65px;
  cursor: pointer;
  border-radius: 4px;
}
.full-width-header .rs-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 55px;
}
.full-width-header .rs-header .right_menu_togle .canvas-logo img {
  width: 176px;
  height: auto;
}
.full-width-header .rs-header .right_menu_togle .offcanvas-text {
  margin-bottom: 57px;
}
.full-width-header .rs-header .right_menu_togle .offcanvas-text p {
  font-size: 18px;
  line-height: 28px;
  color: #333;
  margin-bottom: 0;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact {
  padding: 0 0 60px;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li {
  padding: 0 0 26px;
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li:nth-child(1) {
  font-size: 18px;
  line-height: 1.7;
  padding-right: 40px;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li i {
  padding-right: 20px;
  color: #106eea;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li i:before {
  font-size: 26px;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li a {
  color: #363636;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li a:hover {
  color: #106eea;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .contact li:last-child {
  padding: 0;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .social li {
  display: inline-block;
  padding-right: 8px;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .social li a i {
  font-size: 15px;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #106eea;
  text-align: center;
  border-radius: 3px;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .social li a:hover {
  opacity: 0.82;
}
.full-width-header .rs-header .right_menu_togle .canvas-contact .social li:last-child {
  padding: 0;
}
.full-width-header.header-style-4 .rs-header {
  z-index: 99;
}
.full-width-header.header-style-4 .rs-header .menu-area {
  transition: all 0.3s ease;
}
.full-width-header.header-style-4 .rs-header .menu-area .rs-menu-area {
  justify-content: flex-end;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu .current-page-item > a {
  color: #ccc;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0;
  border: none;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #ffffff;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:before {
  display: none;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  right: -153px;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu {
  padding: 35px 38px;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  padding: 10px 30px;
  color: #ffffff;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ccc;
}
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style-4 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li {
  display: inline-block;
  margin-right: 32px;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li > a {
  color: #101010;
  display: inline-block;
  cursor: pointer;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li > a i:before {
  font-size: 18px;
  font-weight: 600;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li > a i.flaticon-shopping-bag-1:before {
  font-weight: normal;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #106eea;
}
.full-width-header.header-style-4 .rs-header .menu-area .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .expand-btn-inner li.search-parent {
  display: none;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #106eea;
  color: #ffffff;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .expand-btn-inner li > a {
  color: #494949;
}
.full-width-header.header-style-4 .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #106eea;
}
.full-width-header.header-style2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header.header-style2 .custom-row {
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
}
.full-width-header.header-style2 .custom-row .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header.header-style2 .toolbar-area {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header.header-style2 .toolbar-area .toolbar-contact ul li {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header.header-style2 .toolbar-area .toolbar-contact ul li:last-child {
  border: none;
}
.full-width-header.header-style2 .toolbar-area .toolbar-sl-share ul li.opening {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header.header-style2 .rs-header {
  z-index: 99;
}
.full-width-header.header-style2 .rs-header .menu-area {
  transition: all 0.3s ease;
  background: transparent;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:before {
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #106eea !important;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #106eea !important;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #106eea;
  min-width: 1150px;
  right: -320px;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ccc;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.home2 {
  right: -220px;
}
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li.mini-cart-active .cart-icon .cart-count {
  background: #f2f2f2;
  color: #363636;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li > a {
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot2,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot4,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot6,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span {
  background: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot2,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot4,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot6,
.full-width-header.header-style2 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot8 {
  background: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .logo-area {
  height: 103px;
  line-height: 99px;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
  height: 103px;
  line-height: 103px;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .appointment-cart ul.cart li i {
  color: #494949;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .appointment-cart ul.cart li i:hover {
  color: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li.search-parent {
  display: none;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #106eea;
  color: #ffffff;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li > a {
  color: #494949;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #101010;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span {
  background: #106eea;
}
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot2,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot4,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot6,
.full-width-header.header-style2 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot8 {
  background: #101010;
}
.full-width-header.header-style2 .rs-header .rs-menu ul ul {
  right: 0;
}
.full-width-header.header-style2.modify .rs-header {
  z-index: 99;
}
.full-width-header.header-style2.modify .rs-header .menu-area {
  transition: all 0.3s ease;
  background: transparent;
}
.full-width-header.header-style2.modify .rs-header .menu-area .logo-area {
  height: unset;
  line-height: unset;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu {
  padding-right: 65px;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:before {
  color: #ffffff;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a {
  height: 103px;
  line-height: 103px;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #106eea;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #106eea;
  right: -230px;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #ffffff;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ffffff;
}
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #ccc !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu {
  padding-right: 0;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #106eea !important;
}
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu {
  padding-right: 0;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #101010;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #101010 !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #101010 !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
.full-width-header.header-style2.modify.red .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #ccc !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu {
  padding-right: 0;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .expand-btn-inner .head-cta i {
  color: #101010;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .expand-btn-inner .head-cta a {
  color: #101010;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .expand-btn-inner .head-cta a:hover {
  color: #ec4c6f;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .rs-menu-area .main-menu .mobile-menu .rs-menu-toggle {
  color: #101010 !important;
}
.full-width-header.header-style2.modify.red .rs-header .menu-area .rs-menu-area .main-menu .mobile-menu .rs-menu-toggle:hover {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify2 .custom-row {
  display: flex;
  align-items: center;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:before {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #106eea !important;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #106eea !important;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #106eea;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #106eea;
  right: -284px;
  min-width: 1140px;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #ffffff;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ccc;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header.header-style2.modify2 .rs-header .menu-area .expand-btn-inner ul .search-parent a i {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify3 .custom-row {
  display: flex;
  align-items: center;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:before {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #12adfc !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #12adfc !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #106eea !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #12adfc !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #12adfc !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #ffffff;
  right: -284px;
  min-width: 1140px;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #12adfc;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify3 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #12adfc !important;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .expand-btn-inner ul .search-parent a i {
  color: #12adfc;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .expand-btn-inner ul .quote-btn a {
  font-size: 14px;
  background: #1273eb;
  padding: 10px 22px;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
}
.full-width-header.header-style2.modify3 .rs-header .menu-area .expand-btn-inner ul .quote-btn a:hover {
  opacity: .7;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area {
  justify-content: flex-end;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #7188c8 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #7188c8 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #7188c8 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.sub-menu {
  background: #1c3988;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu {
  background: #1c3988;
  right: -140px;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #7188c8 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu .sub-menu li.active a {
  color: #cccccc !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li > a:hover {
  color: #7188c8;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot2,
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot4,
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot6,
.full-width-header.header-style2.modify4 .rs-header .menu-area .rs-menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #ffffff;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #7188c8 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #000000 !important;
}
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
.full-width-header.header-style2.modify4 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #1c3988 !important;
}
.full-width-header.header-style2.modify4 .right_menu_togle .canvas-contact .contact li i {
  color: #0e0b38;
}
.full-width-header.header-style2.modify4 .right_menu_togle .canvas-contact .contact li a:hover {
  color: #555555;
}
.full-width-header.header-style2.modify4 .right_menu_togle .canvas-contact .social li {
  display: inline-block;
  padding-right: 8px;
}
.full-width-header.header-style2.modify4 .right_menu_togle .canvas-contact .social li a i {
  background: #0e0b38;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area {
  justify-content: flex-end;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #cccccc !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #cccccc !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #b1976b !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu {
  background: #ffffff;
  right: 0;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title:hover {
  color: #ec4c6f;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #cccccc !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu .sub-menu li.active a {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu {
  padding-right: 0;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover:before {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify5 .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #ec4c6f !important;
}
.full-width-header.header-style2.modify6 .rs-header {
  z-index: 99;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area {
  transition: all 0.3s ease;
  background: transparent;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #ffffff;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:before {
  color: #ffffff;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  background: #ffffff;
  min-width: 1150px;
  right: -320px;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #1c1b1b;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title:hover {
  color: #ec4c6f;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style2.modify6 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li.mini-cart-active .cart-icon .cart-count {
  background: #f2f2f2;
  color: #363636;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li > a {
  color: #bf0505;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li > a:hover {
  color: #ccc;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot2,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot4,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot6,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger span.dot8 {
  background: #bf0505;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span {
  background: #bf0505;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot2,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot4,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot6,
.full-width-header.header-style2.modify6 .rs-header .menu-area .expand-btn-inner ul li .humburger:hover span.dot8 {
  background: #ffffff;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .logo-area {
  height: 103px;
  line-height: 99px;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
  height: 103px;
  line-height: 103px;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #bf0505 !important;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span {
  background: #101010;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot2,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot4,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot6,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger span.dot8 {
  background: #bf0505;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span {
  background: #bf0505;
}
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot2,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot4,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot6,
.full-width-header.header-style2.modify6 .rs-header .menu-area.sticky .expand-btn-inner li .humburger:hover span.dot8 {
  background: #101010;
}
.full-width-header.header-style2.modify6 .right_menu_togle .canvas-contact .contact li i {
  color: #bf0505;
}
.full-width-header.header-style2.modify6 .right_menu_togle .canvas-contact .contact li a:hover {
  color: #bf0505;
}
.full-width-header.header-style2.modify6 .right_menu_togle .canvas-contact .social li {
  display: inline-block;
  padding-right: 8px;
}
.full-width-header.header-style2.modify6 .right_menu_togle .canvas-contact .social li a i {
  background: #bf0505;
}
.full-width-header.header-style3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header.header-style3 .rs-header {
  z-index: 99;
}
.full-width-header.header-style3 .rs-header .menu-area {
  transition: all 0.3s ease;
  background: transparent;
}
.full-width-header.header-style3 .rs-header .menu-area .container {
  background: rgba(255, 255, 255, 0.9);
  padding: 0;
  border-right: 10px solid;
  border-left: 10px solid;
  padding: 0 40px;
  margin-top: 50px;
  border-color: #106eea;
  max-width: 1160px;
  width: 100%;
  transition: all 0.3s ease;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.nav-menu li a {
  color: #1c1b1b;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.nav-menu li a:before {
  color: #1c1b1b;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.nav-menu li a:hover:before {
  color: #106eea !important;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.nav-menu.onepage-menu .active-menu a {
  color: #106eea !important;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu {
  background: #106eea;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li a {
  color: #ffffff;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #ccc !important;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li.active a {
  color: #ccc !important;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.mega-menu {
  background: #106eea;
  right: -284px;
  min-width: 1140px;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #ffffff;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ccc;
}
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul li a:hover,
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul li.active a,
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #106eea !important;
}
.full-width-header.header-style3 .rs-header .menu-area .expand-btn-inner .number i {
  color: #106eea;
  margin-right: 15px;
}
.full-width-header.header-style3 .rs-header .menu-area .expand-btn-inner .number a {
  color: #1c1b1b;
  font-weight: 500;
  padding-bottom: 3px;
}
.full-width-header.header-style3 .rs-header .menu-area .expand-btn-inner .number a:hover {
  color: #106eea;
}
.full-width-header.header-style3 .rs-header .menu-area.sticky {
  background: #ffffff !important;
}
.full-width-header.header-style3 .rs-header .menu-area.sticky .container {
  background: transparent;
  border: none;
  padding: 0 15px;
  max-width: 1200px;
  margin-top: 0;
}
.full-width-header.header-style3 .rs-header .menu-area.sticky .container .main-menu .rs-menu ul.nav-menu > li a:before {
  color: #363636;
}
.full-width-header.header-style3 .rs-header .menu-area.sticky .container .main-menu .rs-menu ul.nav-menu > li > a {
  color: #494949;
}
.full-width-header.header-style3 .rs-header .menu-area.sticky .container .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
body.modal-open {
  padding: 0 !important;
}
body .search-modal {
  padding: 0 !important;
}
body .search-modal .modal-content {
  background: transparent;
  position: initial;
  border: 0;
}
body .search-modal .search-block form {
  position: relative;
}
body .search-modal .search-block form input {
  border-radius: 5px;
  padding: 12px 17px;
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
}
body .search-modal .search-block form button {
  background: transparent;
  border: none;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  position: absolute;
  content: '';
  right: 0;
  top: 0;
}
body .search-modal .search-block form button i {
  opacity: 0.7;
}
body .search-modal .close {
  color: #ffffff;
  margin: 50px 0 0 0;
  font-size: 14px;
  background-color: #228bfd;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  opacity: 1;
  outline: none;
  transition: all 0.3s ease;
  position: absolute;
  right: 50px;
}
body .search-modal .close span:before {
  font-size: 16px;
}
body .search-modal .close:hover {
  opacity: 0.8 !important;
}
body .modal-backdrop {
  background: rgba(3, 7, 73, 0.98);
  opacity: 1 !important;
}
/*---------------------------------------
    04. Sticky Menu CSS
---------------------------------------*/
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*---------------------------------------
    05. Slider Section CSS
---------------------------------------*/
.rs-slider {
  position: relative;
  overflow: hidden;
  text-align: left;
}
.rs-slider.slider1 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider1 .sl-title {
  -webkit-animation: fadeInUp 1.7s ease-in-out;
  animation: fadeInUp 1.7s ease-in-out;
}
.rs-slider.slider1 .sl-desc {
  -webkit-animation: fadeInUp 1.5s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
}
.rs-slider.slider1 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider1 .container {
  position: relative;
  height: 100%;
}
.rs-slider.slider1 .container .content-part {
  padding: 45px 35px 55px;
  border-radius: 5px;
  max-width: 545px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  left: 15px;
}
.rs-slider.slider1 .container .content-part .sl-title {
  margin-bottom: 20px;
  color: #ffffff;
}
.rs-slider.slider1 .container .content-part .sl-desc {
  font-size: 16px;
  line-height: 30px;
}
.rs-slider.slider1 .container .content-part .slider-bottom {
  display: inline-block;
  margin: 39px 0 20px;
}
.rs-slider.slider1 .container .content-part .slider-bottom .readon {
  display: block !important;
}
.rs-slider.slider1 .container .content-part .slider-bottom .white-btn {
  background: #ffffff;
  color: #228bfd;
}
.rs-slider.slider1 .container .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider1 .container .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider1 .container .content-part:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #228bfd;
  z-index: -1;
  border-radius: 5px;
  -webkit-animation: fadeInUp 1s ease-in-out;
  animation: fadeInUp 1s ease-in-out;
  opacity: 0.86;
}
.rs-slider.slider1 .nivoSlider .sl-ly-img {
  margin-left: 100px;
}
.rs-slider.slider1 .nivoSlider .sl-ly-img img {
  position: unset;
  padding-top: 150px;
  max-height: unset;
}
.rs-slider.slider1 .nivo-caption {
  padding: 0;
}
.rs-slider.slider2 {
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider2 .sl-subtitle {
  -webkit-animation: fadeInUp 1.7s ease-in-out;
  animation: fadeInUp 1.7s ease-in-out;
}
.rs-slider.slider2 .sl-title {
  -webkit-animation: fadeInUp 1.7s ease-in-out;
  animation: fadeInUp 1.7s ease-in-out;
}
.rs-slider.slider2 .sl-desc {
  -webkit-animation: fadeInUp 1.7s ease-in-out;
  animation: fadeInUp 1.7s ease-in-out;
}
.rs-slider.slider2 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider2 .content-part {
  position: relative;
  padding: 367px 0 230px;
}
.rs-slider.slider2 .content-part .sl-subtitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.rs-slider.slider2 .content-part .sl-title {
  font-size: 80px;
  line-height: 85px;
  font-weight: 700;
  margin-bottom: 20px;
}
.rs-slider.slider2 .content-part .sl-desc {
  font-size: 30px;
  line-height: 35px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 7px;
  text-transform: capitalize;
  line-height: 30px;
}
.rs-slider.slider2 .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider2 .content-part .slider-bottom .readon {
  display: block !important;
}
.rs-slider.slider2 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider2 .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider2 .content-part .sl-ly-img {
  position: absolute;
  bottom: 0;
  right: -40px;
}
.rs-slider.slider2 .content-part .sl-ly-img img {
  width: 469px;
}
.rs-slider.slider2 .content-part.slide2 .sl-ly-img img {
  width: 555px;
}
.rs-slider.slider3 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider3 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider3 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider3 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider3 .content-part {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.rs-slider.slider3 .content-part .sl-subtitle {
  font-size: 21px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 16px;
}
.rs-slider.slider3 .content-part .sl-title {
  font-size: 70px;
  line-height: 83px;
  font-weight: 700;
  margin-bottom: 36px;
  text-transform: uppercase;
}
.rs-slider.slider3 .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider3 .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 32px;
}
.rs-slider.slider3 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider3 .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider3 .nivo-caption {
  padding: 0;
}
.rs-slider.slider3 .nivo-directionNav > a {
  background: rgba(0, 0, 0, 0.2);
}
.rs-slider.slider3 .nivo-directionNav > a:before {
  color: #ffffff;
}
.rs-slider.slider3 .nivo-directionNav > a:hover {
  background: #f1f6fc;
}
.rs-slider.slider3 .nivo-directionNav > a:hover:before {
  color: #106eea;
}
.rs-slider.slider4 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider4 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider4 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider4 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider4 .slider {
  padding: 436px 0 240px;
}
.rs-slider.slider4 .slider .content-part .sl-subtitle {
  font-size: 50px;
  line-height: 68px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #222;
  text-shadow: #ffffff 1px 1px 1px;
}
.rs-slider.slider4 .slider .content-part .sl-title {
  font-size: 100px;
  line-height: 120px;
  font-weight: 900;
  margin-bottom: 21px;
  text-transform: uppercase;
  color: #222;
  text-shadow: #ffffff 1px 1px 1px;
}
.rs-slider.slider4 .slider .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider4 .slider .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 17px 30px;
}
.rs-slider.slider4 .slider .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider4 .slider .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider4 .slider.slide1 {
  background: url(./images/slider/h4-sl1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider4 .slider.slide2 {
  background: url(./images/slider/h4-sl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider4 .owl-dots {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider4 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider4 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider4 .owl-dots .owl-dot:hover,
.rs-slider.slider4 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #106eea;
}
.rs-slider.slider4 .owl-dots .owl-dot:hover span,
.rs-slider.slider4 .owl-dots .owl-dot.active span {
  background: #106eea;
  transform: scale(0.4);
}
.rs-slider.slider4 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider5 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider5 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider5 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider5 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider5 .slider {
  padding: 285px 0 170px;
}
.rs-slider.slider5 .slider .content-part .sl-subtitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  margin-bottom: 16px;
}
.rs-slider.slider5 .slider .content-part .sl-title {
  font-size: 100px;
  line-height: 100px;
  font-weight: 900;
  margin-bottom: 38px;
  text-transform: uppercase;
  color: #fff;
}
.rs-slider.slider5 .slider .content-part .sl-title span {
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
}
.rs-slider.slider5 .slider .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider5 .slider .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 16px 30px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  background: transparent;
}
.rs-slider.slider5 .slider .content-part .slider-bottom .readon:hover {
  border-color: #106eea;
  background: #106eea;
}
.rs-slider.slider5 .slider .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider5 .slider .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider5 .slider.slide1 {
  background: url(./images/slider/h5-sl1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider5 .slider.slide2 {
  background: url(./images/slider/h5-sl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider5 .owl-dots {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider5 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider5 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider5 .owl-dots .owl-dot:hover,
.rs-slider.slider5 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #106eea;
}
.rs-slider.slider5 .owl-dots .owl-dot:hover span,
.rs-slider.slider5 .owl-dots .owl-dot.active span {
  background: #106eea;
  transform: scale(0.4);
}
.rs-slider.slider5 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider6 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider6 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider6 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider6 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider6 .slider {
  height: 950px;
  padding: 275px 0 0;
}
.rs-slider.slider6 .slider.slide1 {
  background: url(./images/slider/h6-sl1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider6 .slider.slide1 .content-part {
  max-width: 570px;
}
.rs-slider.slider6 .slider.slide1 .content-part .sl-subtitle {
  font-size: 20px;
  line-height: 35px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  letter-spacing: 2px;
}
.rs-slider.slider6 .slider.slide1 .content-part .sl-title {
  font-size: 59px;
  line-height: 70px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}
.rs-slider.slider6 .slider.slide1 .content-part .sl-title span {
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
}
.rs-slider.slider6 .slider.slide1 .content-part .sl-desc {
  font-size: 18px;
  line-height: 32px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  letter-spacing: 2px;
}
.rs-slider.slider6 .slider.slide1 .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider6 .slider.slide1 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider6 .slider.slide1 .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider6 .slider.slide2 {
  background: url(./images/slider/h6-sl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider6 .slider.slide2 .content-part {
  text-align: center;
}
.rs-slider.slider6 .slider.slide2 .content-part .video-btn {
  margin-bottom: 50px;
}
.rs-slider.slider6 .slider.slide2 .content-part .video-btn .popup-videos {
  color: #67c27c;
  height: 60px;
  width: 60px;
  line-height: 65px;
}
.rs-slider.slider6 .slider.slide2 .content-part .video-btn .popup-videos i {
  font-size: 20px;
}
.rs-slider.slider6 .slider.slide2 .content-part .video-btn .popup-videos:after,
.rs-slider.slider6 .slider.slide2 .content-part .video-btn .popup-videos:before {
  display: none;
}
.rs-slider.slider6 .slider.slide2 .content-part .sl-title {
  font-size: 73px;
  line-height: 80px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  margin-bottom: 32px;
}
.rs-slider.slider6 .slider.slide2 .content-part .sl-title span {
  display: block;
}
.rs-slider.slider6 .slider.slide2 .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider6 .slider.slide2 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider6 .slider.slide2 .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider6 .owl-dots {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider6 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider6 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider6 .owl-dots .owl-dot:hover,
.rs-slider.slider6 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #67c27c;
}
.rs-slider.slider6 .owl-dots .owl-dot:hover span,
.rs-slider.slider6 .owl-dots .owl-dot.active span {
  background: #67c27c;
  transform: scale(0.4);
}
.rs-slider.slider6 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider8 {
  position: relative;
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider8 .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider8 .sl-title {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider8 .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider8 .slider {
  padding: 245px 0 240px;
}
.rs-slider.slider8 .slider.slide1 {
  background: url(./images/slider/h8-sl1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider8 .slider.slide1 .content-part {
  max-width: 622px;
}
.rs-slider.slider8 .slider.slide2 {
  background: url(./images/slider/h8-sl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider8 .slider.slide2 .content-part {
  max-width: 665px;
}
.rs-slider.slider8 .slider .content-part .sl-subtitle {
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #1c3988;
}
.rs-slider.slider8 .slider .content-part .sl-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 800;
  color: #0c121d;
}
.rs-slider.slider8 .slider .content-part .sl-desc {
  font-size: 18px;
  line-height: 32px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  letter-spacing: 2px;
}
.rs-slider.slider8 .slider .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 23px;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li .readon {
  padding: 16px 55px;
  font-size: 14px;
  letter-spacing: 1px;
  border: 2px solid #1c3988;
  color: #1c3988;
  text-transform: uppercase;
  font-weight: 700;
  background: transparent;
  border-radius: 6px;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li .readon:hover {
  background: #1c3988;
  color: #ffffff;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li .popup-videos {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  background: #ffffff;
  color: #1c3988;
  font-size: 20px;
  text-align: center;
  display: inline-block;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li .popup-videos i {
  padding-left: 5px;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li .btn-title {
  color: #1c3988;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  padding-left: 7px;
  font-family: 'Poppins', sans-serif;
}
.rs-slider.slider8 .slider .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider8 .owl-dots {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider8 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider8 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider8 .owl-dots .owl-dot:hover,
.rs-slider.slider8 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #1c3988;
}
.rs-slider.slider8 .owl-dots .owl-dot:hover span,
.rs-slider.slider8 .owl-dots .owl-dot.active span {
  background: #1c3988;
  transform: scale(0.4);
}
.rs-slider.slider8 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider10 {
  position: relative;
}
.rs-slider.slider10 .slider {
  padding: 359px 0 255px;
}
.rs-slider.slider10 .slider.slide1 {
  background: url(./images/slider/h10-sl1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider10 .slider.slide2 {
  background: url(./images/slider/h10-sl2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider10 .slider .content-part {
  max-width: 700px;
}
.rs-slider.slider10 .slider .content-part .sl-subtitle {
  font-size: 20px;
  line-height: 35px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #fff;
}
.rs-slider.slider10 .slider .content-part .sl-title {
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}
.rs-slider.slider10 .slider .content-part .sl-title span {
  font-size: 100px;
  line-height: 100px;
  font-weight: 900;
  display: block;
  margin-top: 13px;
}
.rs-slider.slider10 .slider .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider10 .slider .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider10 .slider .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider10 .owl-dots {
  display: none;
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider10 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider10 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider10 .owl-dots .owl-dot:hover,
.rs-slider.slider10 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #b1976b;
}
.rs-slider.slider10 .owl-dots .owl-dot:hover span,
.rs-slider.slider10 .owl-dots .owl-dot.active span {
  background: #b1976b;
  transform: scale(0.4);
}
.rs-slider.slider10 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider10 .owl-nav {
  display: block;
}
.rs-slider.slider10 .owl-nav .owl-next,
.rs-slider.slider10 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 70px;
  height: 70px;
  line-height: 74px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.slider10 .owl-nav .owl-next i:before,
.rs-slider.slider10 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
  font-size: 20px;
  margin-right: 3px;
}
.rs-slider.slider10 .owl-nav .owl-next:hover,
.rs-slider.slider10 .owl-nav .owl-prev:hover {
  background: #b1976b;
}
.rs-slider.slider10 .owl-nav .owl-next {
  right: 0;
  left: unset;
}
.rs-slider.slider10 .owl-nav .owl-next i:before {
  content: "\f113";
  margin-right: 0;
  margin-left: 3px;
}
.rs-slider.slider10 .owl-item.active {
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider10 .owl-item.active .sl-subtitle {
  -webkit-animation: fadeInDown 1.7s ease-in-out;
  animation: fadeInDown 1.7s ease-in-out;
}
.rs-slider.slider10 .owl-item.active .sl-title {
  -webkit-animation: slideInRightShort 1.2s ease-in-out;
  animation: slideInRightShort 1.2s ease-in-out;
}
.rs-slider.slider10 .owl-item.active .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider10:hover .owl-nav .owl-next,
.rs-slider.slider10:hover .owl-nav .owl-prev {
  left: 30px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.slider10:hover .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.slider11 {
  background: url(./images/slider/h11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider11 .slider {
  overflow: hidden;
}
.rs-slider.slider11 .slider .container {
  position: relative;
  padding: 213px 15px 245px;
}
.rs-slider.slider11 .slider .container .content-part .sl-title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
}
.rs-slider.slider11 .slider .container .content-part .sl-desc {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.rs-slider.slider11 .slider .container .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider11 .slider .container .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider11 .slider .container .content-part .slider-bottom ul li:last-child {
  margin: 0;
}
.rs-slider.slider11 .slider .container .image-part {
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.rs-slider.slider11 .slider .container .sl-shape {
  position: absolute;
  width: 199px;
  top: 30%;
}
.rs-slider.slider11 .slider .container .sl-shape img {
  animation: rotate 6s linear infinite;
  -webkit-animation: rotate 6s linear infinite;
}
.rs-slider.slider11 .slider.slide1 .container .image-part {
  left: -175px;
}
.rs-slider.slider11 .slider.slide1 .container .sl-shape {
  left: -150px;
}
.rs-slider.slider11 .slider.slide2 .container .image-part {
  right: -110px;
}
.rs-slider.slider11 .slider.slide2 .container .sl-shape {
  right: -150px;
}
.rs-slider.slider11 .owl-item.active .slider {
  /*-- Animations Start --*/

  /*-- Animations End --*/

}
.rs-slider.slider11 .owl-item.active .slider.slide1 .sl-shape {
  animation: fadeInLeft 1.7s ease-in-out;
  -webkit-animation: fadeInLeft 1.7s ease-in-out;
}
.rs-slider.slider11 .owl-item.active .slider.slide2 .sl-shape {
  animation: fadeInRight 1.7s ease-in-out;
  -webkit-animation: fadeInRight 1.7s ease-in-out;
}
.rs-slider.slider11 .owl-item.active .slider .image-part {
  animation: fadeInUp 1.7s ease-in-out;
  -webkit-animation: fadeInUp 1.7s ease-in-out;
}
.rs-slider.slider11 .owl-item.active .slider .sl-title {
  -webkit-animation: fadeInDown 1.2s ease-in-out;
  animation: fadeInDown 1.2s ease-in-out;
}
.rs-slider.slider11 .owl-item.active .slider .sl-desc {
  -webkit-animation: fadeInUp 1.2s ease-in-out;
  animation: fadeInUp 1.2s ease-in-out;
}
.rs-slider.slider11 .owl-item.active .slider .slider-bottom {
  -webkit-animation: fadeInUp 1.8s ease-in-out;
  animation: fadeInUp 1.8s ease-in-out;
}
.rs-slider.slider11 .owl-dots {
  display: none;
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.slider11 .owl-dots .owl-dot {
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.rs-slider.slider11 .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.rs-slider.slider11 .owl-dots .owl-dot:hover,
.rs-slider.slider11 .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #b1976b;
}
.rs-slider.slider11 .owl-dots .owl-dot:hover span,
.rs-slider.slider11 .owl-dots .owl-dot.active span {
  background: #b1976b;
  transform: scale(0.4);
}
.rs-slider.slider11 .owl-dots .owl-dot.last-child {
  margin-bottom: 0;
}
.rs-slider.slider11 .owl-nav {
  display: block;
}
.rs-slider.slider11 .owl-nav .owl-next,
.rs-slider.slider11 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 70px;
  height: 70px;
  line-height: 74px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.slider11 .owl-nav .owl-next i:before,
.rs-slider.slider11 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
  font-size: 20px;
  margin-right: 3px;
}
.rs-slider.slider11 .owl-nav .owl-next:hover,
.rs-slider.slider11 .owl-nav .owl-prev:hover {
  background: #d83030;
}
.rs-slider.slider11 .owl-nav .owl-next {
  right: 0;
  left: unset;
}
.rs-slider.slider11 .owl-nav .owl-next i:before {
  content: "\f113";
  margin-right: 0;
  margin-left: 3px;
}
.rs-slider.slider11:hover .owl-nav .owl-next,
.rs-slider.slider11:hover .owl-nav .owl-prev {
  left: 30px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.slider11:hover .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.slider12 .slider {
  position: relative;
}
.rs-slider.slider12 .slider.slide1 {
  background: url(./images/slider/corporate/2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider12 .slider.slide2 {
  background: url(./images/slider/corporate/1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider12 .slider .content-part {
  padding: 350px 0 400px;
  max-width: 800px;
  max-height: 960px;
  margin: 0 auto;
  text-align: center;
}
.rs-slider.slider12 .slider .content-part .sl-subtitle {
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Poppins", Sans-serif;
  margin-bottom: 15px;
}
.rs-slider.slider12 .slider .content-part .sl-title {
  font-size: 64px;
  line-height: 74px;
  font-weight: 800;
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
}
.rs-slider.slider12 .slider .content-part .slider-bottom {
  display: inline-block;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li {
  margin-bottom: 25px;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li .readon {
  padding: 16px 55px;
  font-size: 14px;
  letter-spacing: 1px;
  border: 2px solid #1c3988;
  color: #1c3988;
  text-transform: uppercase;
  font-weight: 700;
  background: transparent;
  border-radius: 6px;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li .readon:hover {
  background: #1c3988;
  color: #ffffff;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li .popup-videos {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  background: #ffffff;
  color: #1c3988;
  font-size: 20px;
  text-align: center;
  display: inline-block;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li .popup-videos i {
  padding-left: 5px;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li .btn-title {
  color: #ffffff;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  padding-left: 7px;
  font-family: 'Poppins', sans-serif;
}
.rs-slider.slider12 .slider .content-part .slider-bottom ul li:last-child {
  margin-bottom: 0;
}
.rs-slider.slider12 .owl-nav .owl-next,
.rs-slider.slider12 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.slider12 .owl-nav .owl-next i:before,
.rs-slider.slider12 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.slider12 .owl-nav .owl-next:hover,
.rs-slider.slider12 .owl-nav .owl-prev:hover {
  opacity: 0.09;
}
.rs-slider.slider12 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.slider12 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-slider.slider12:hover .owl-nav .owl-next,
.rs-slider.slider12:hover .owl-nav .owl-prev {
  left: 50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.slider12:hover .owl-nav .owl-next {
  right: 50px;
  left: unset;
}
.rs-slider.slider13 {
  background: url(./images/slider/solution/slide-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.rs-slider.slider13 .slider .slider-content {
  padding: 330px 0 330px;
}
.rs-slider.slider13 .slider .slider-content .sl-sub-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
}
.rs-slider.slider13 .slider .slider-content .sl-title {
  font-size: 55px;
  line-height: 65px;
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 25px;
}
.rs-slider.slider13 .slider .slider-content .sl-sub-title2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 40px;
}
.rs-slider.slider13 .slider .image-part {
  position: absolute;
  right: 224px;
  top: 22%;
}
.rs-slider.slider13 .slider .image-part img {
  width: 550px;
}
.rs-slider.slider13 .owl-nav .owl-next,
.rs-slider.slider13 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.slider13 .owl-nav .owl-next i:before,
.rs-slider.slider13 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.slider13 .owl-nav .owl-next:hover,
.rs-slider.slider13 .owl-nav .owl-prev:hover {
  opacity: 0.09;
}
.rs-slider.slider13 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.slider13 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-slider.slider13:hover .owl-nav .owl-next,
.rs-slider.slider13:hover .owl-nav .owl-prev {
  left: 50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.slider13:hover .owl-nav .owl-next {
  right: 50px;
  left: unset;
}
.rs-slider.slider14 .slider {
  position: relative;
}
.rs-slider.slider14 .slider .slider-content {
  padding: 350px 0 400px;
  max-width: 1000px;
  max-height: 1000px;
  margin: 0 auto;
  text-align: center;
}
.rs-slider.slider14 .slider .slider-content .sl-sub-title {
  text-align: center;
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.21);
  color: #ffffff;
  font-family: Roboto;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 25px;
}
.rs-slider.slider14 .slider .slider-content .sl-title {
  font-size: 90px;
  line-height: 100px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.rs-slider.slider14 .slider.slide1 {
  background: url(./images/slider/marketing/1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider14 .slider.slide2 {
  background: url(./images/slider/marketing/2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-slider.slider14 .owl-nav .owl-next,
.rs-slider.slider14 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.slider14 .owl-nav .owl-next i:before,
.rs-slider.slider14 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.slider14 .owl-nav .owl-next:hover,
.rs-slider.slider14 .owl-nav .owl-prev:hover {
  opacity: 0.09;
}
.rs-slider.slider14 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.slider14 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-slider.slider14:hover .owl-nav .owl-next,
.rs-slider.slider14:hover .owl-nav .owl-prev {
  left: 50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.slider14:hover .owl-nav .owl-next {
  right: 50px;
  left: unset;
}
.rs-slider .sl-wrap {
  padding: 50px 0;
}
.rs-slider .nivo-directionNav > a {
  font-size: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: none;
  background: #f1f6fc;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav {
  left: 60px;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
}
.rs-slider .nivo-directionNav > a.nivo-nextNav {
  right: 60px;
}
.rs-slider .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
  margin-left: 2px;
}
.rs-slider .nivo-directionNav > a:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  transition: all 0.3s ease;
  color: #106eea;
}
.rs-slider .nivo-directionNav > a:hover {
  background: #106eea;
}
.rs-slider .nivo-directionNav > a:hover:before {
  color: #ffffff;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a {
  opacity: 1;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a.nivo-prevNav {
  left: 30px;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a.nivo-nextNav {
  right: 30px;
}
.rs-slider .nivo-controlNav {
  position: absolute;
  bottom: 50px;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: none !important;
}
.rs-slider .nivo-controlNav .nivo-control {
  width: 26px;
  height: 7px;
  border: 1px solid #fff;
  font-size: 0px;
  display: inline-block;
  margin-right: 7px;
  -webkit-transition: .4s ease all;
  transition: .4s ease all;
}
.rs-slider .nivo-controlNav .nivo-control.active {
  background: #106eea;
  border-color: #106eea;
}
/*---------------------------------------
    06. Banner Section CSS
---------------------------------------*/
.rs-banner.style1 {
  background: url(./images/banner/h7-bnr.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding: 177px 0 50px;
}
.rs-banner.style1 .content-part {
  max-width: 815px;
  margin: 0 auto 50px;
}
.rs-banner.style1 .content-part .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 38px;
}
.rs-banner.style1 .content-part .subscribe-form {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}
.rs-banner.style1 .content-part .subscribe-form input,
.rs-banner.style1 .content-part .subscribe-form button {
  outline: none;
  border: none;
  font-size: 16px;
}
.rs-banner.style1 .content-part .subscribe-form input {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px 18px;
  height: 55px;
}
.rs-banner.style1 .content-part .subscribe-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 9.5px 30px;
  border-radius: 0px 3px 3px 0px;
  background: #106eea;
  color: #ffffff;
  transition: all 0.3s ease;
}
.rs-banner.style1 .content-part .subscribe-form button:hover {
  background: #228bfd;
}
.rs-banner.style1 .content-part .subscribe-form ::placeholder {
  color: #a5a5a5;
}
.rs-banner.style1 .content-part .desc {
  font-family: 'Poppins', sans-serif;
  margin-top: 15px;
}
.rs-banner.style1 .parallax-elemen {
  position: relative !important;
  max-width: 749px;
  margin: 0 auto;
}
.rs-banner.style1 .parallax-elemen div {
  position: unset !important;
}
.rs-banner.style1 .parallax-elemen span {
  position: absolute !important;
  z-index: 1;
}
.rs-banner.style1 .parallax-elemen span img {
  max-width: 128px;
  width: 100%;
  margin: 0 auto;
}
.rs-banner.style1 .parallax-elemen span:nth-child(1) {
  left: 0 !important;
  top: 0 !important;
  right: unset !important;
  bottom: unset !important;
}
.rs-banner.style1 .parallax-elemen span:nth-child(2) {
  right: 0 !important;
  top: 0 !important;
  left: unset !important;
  bottom: unset !important;
}
.rs-banner.style1 .parallax-elemen span:nth-child(3) {
  right: 0 !important;
  bottom: 0 !important;
  left: unset !important;
  top: unset !important;
}
.rs-banner.style1 .parallax-elemen span:nth-child(4) {
  left: 0 !important;
  bottom: 0 !important;
  right: unset !important;
  top: unset !important;
}
.rs-banner.style1 .parallax-elemen span:nth-child(5) {
  left: -5% !important;
  bottom: 40% !important;
  right: unset !important;
  top: unset !important;
}
.rs-banner.style1 .parallax-elemen span:nth-child(6) {
  right: -5% !important;
  bottom: 40% !important;
  left: unset !important;
  top: unset !important;
}
.rs-banner.style2 {
  background: url(./images/banner/h9-bnr.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 235px 0 347px;
}
.rs-banner.style2 .content-part .title {
  font-size: 65px;
  line-height: 70px;
  text-transform: capitalize;
}
.rs-banner.style2 .content-part .desc {
  font-size: 18px;
  line-height: 26px;
  max-width: 490px;
  font-family: 'Poppins', sans-serif;
}
.rs-banner.style2 .content-part .btn-part a {
  background: #ffffff;
  color: #333333;
  padding: 11.5px 27px;
}
.rs-banner.style2 .content-part .btn-part a:hover {
  background: #f0f0f0;
}
.rs-banner.style2 .bnr-image-wrap {
  position: absolute;
  content: '';
  right: -136px;
  top: 55%;
  transform: translateY(-50%);
}
.rs-banner.style2 .bnr-image-wrap .bnr-image {
  max-width: 708px;
  position: relative;
  padding-top: 38px;
}
.rs-banner.style2 .bnr-image-wrap .bnr-image .fly {
  position: absolute;
}
.rs-banner.style2 .bnr-image-wrap .bnr-image .fly.ly1 {
  max-width: 156px;
  right: 240px;
  bottom: 0;
}
.rs-banner.style2 .bnr-image-wrap .bnr-image .fly.ly2 {
  max-width: 151px;
  right: 120px;
  bottom: -2px;
}
.rs-banner.style2 .bnr-image-wrap .bnr-image .fly.ly3 {
  max-width: 108px;
  right: 165px;
  top: 0;
}
.rs-banner.style2 .bnr-image-wrap .bnr-image .fly.ly4 {
  max-width: 87px;
  right: 224px;
  top: 33px;
}
.rs-banner.style3 {
  padding: 80px 0 0 0;
}
.rs-banner.style3 .banner-content {
  position: relative;
  z-index: 1;
  padding: 230px 0 230px;
}
.rs-banner.style3 .banner-content .sub-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #F67D31;
  text-transform: uppercase;
  display: block;
  margin: 0px 0px 15px 0px;
}
.rs-banner.style3 .banner-content .title {
  font-size: 68px;
  line-height: 75px;
  font-weight: 700;
  color: #0a0a0a;
  letter-spacing: -2px;
  margin-bottom: 25px;
}
.rs-banner.style3 .banner-content .description p {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #363636;
  margin-bottom: 43px;
}
.rs-banner.style3 .banner-content .banner-btn {
  position: relative;
}
.rs-banner.style3 .banner-content .banner-btn li .rs-videos .animate-border .popup-border {
  top: -10px;
}
.rs-banner.style3 .image-part {
  margin: 0px -280px 0px 0px;
}
.rs-banner.style3 .animations-shape {
  position: relative;
}
.rs-banner.style3 .animations-shape .pattern {
  position: absolute;
  left: -2px;
  top: -800px;
}
.rs-banner.style4 {
  background: url(./images/banner/style2/banner-right.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  padding: 300px 0px 135px 0px;
  position: relative;
  z-index: 1;
}
.rs-banner.style4 .banner-content .title {
  font-size: 80px;
  line-height: 96px;
  font-weight: 700;
  color: #12adfc;
}
.rs-banner.style4 .banner-content .title .watermark {
  color: #111111;
  display: block;
}
.rs-banner.style4 .banner-content .description p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #363636;
  margin: 0px 198px 42px 0px;
}
.rs-banner.style4 .animate-part .spiner {
  position: absolute;
  bottom: 0;
}
.rs-banner.style4 .animate-part .spiner.dot-style {
  left: 8%;
  top: 12%;
  z-index: -1;
}
.rs-banner.style4 .animate-part .spiner.horizontal {
  right: 48%;
  bottom: 16%;
}
.rs-banner.style4 .animate-part .spiner.horizontal2 {
  right: 52%;
  bottom: 12%;
}
.rs-banner.style4 .animate-part .spiner.line-shape {
  left: 0;
  top: 20%;
}
.rs-banner.style5 {
  background: url(./images/banner/apps/banner-1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.rs-banner.style5 .banner-content {
  padding: 270px 0 300px;
}
.rs-banner.style5 .banner-content .title {
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
  color: #ffffff;
  font-family: Poppins;
  margin-bottom: 0;
}
.rs-banner.style5 .banner-content .title .first {
  display: block;
}
.rs-banner.style5 .banner-content .desc {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff;
  margin: 20px 0 30px;
  font-family: Poppins;
}
.rs-banner.style5 .banner-content .banner-btn ul li {
  display: inline-block;
  margin-right: 20px;
}
.rs-banner.style5 .banner-content .banner-btn ul li:last-child {
  margin: 0;
}
.rs-banner.style5 .animate-part .spinner {
  position: absolute;
  bottom: 0;
  z-index: -111;
}
.rs-banner.style5 .animate-part .spinner.big {
  left: 12%;
  bottom: 28%;
}
.rs-banner.style5 .animate-part .spinner.ball {
  left: 12%;
  bottom: -30px;
}
.rs-banner.style5 .animate-part .spinner.ball1 {
  right: 170px;
  top: 100px;
}
.rs-banner.style5 .animate-part .spinner.ring {
  right: 13%;
  bottom: 20%;
}
.rs-banner.style5 .animate-part .spinner.arrow {
  right: 6%;
  bottom: 15%;
}
.rs-banner.style6 {
  background: url(./images/banner/style4/banner-18.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-banner.style6 .banner-content {
  padding: 230px 0 220px;
  position: relative;
}
.rs-banner.style6 .banner-content .sub-title {
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  border-color: #ffffff;
  border-style: solid;
  border-width: 0px 0px 3px;
  font-family: Roboto;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 20px;
}
.rs-banner.style6 .banner-content .title {
  font-size: 72px;
  line-height: 80px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 13px;
}
.rs-banner.style6 .banner-content .small-title {
  font-size: 46px;
  line-height: 80px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: Poppins;
  color: #ffffff;
  margin-bottom: 0;
}
.rs-banner.style6 .banner-content .animate-img {
  position: absolute;
  left: -121px;
  top: 47%;
}
.rs-banner.style6 .banner-content .animate-img img {
  width: 130px;
  height: 130px;
}
/*---------------------------------------
    07. Breadcrumbs Section CSS
---------------------------------------*/
.rs-breadcrumbs {
  background-size: cover;
  background-repeat: no-repeat;
}
.rs-breadcrumbs.bg-1 {
  background-image: url(./images/breadcrumbs/1.jpg);
}
.rs-breadcrumbs.bg-2 {
  background-image: url(./images/breadcrumbs/2.jpg);
}
.rs-breadcrumbs.bg-3 {
  background-image: url(./images/breadcrumbs/3.jpg);
}
.rs-breadcrumbs.bg-4 {
  background-image: url(./images/breadcrumbs/4.jpg);
}
.rs-breadcrumbs.bg-5 {
  background-image: url(./images/breadcrumbs/5.jpg);
}
.rs-breadcrumbs.bg-6 {
  background-image: url(./images/breadcrumbs/6.jpg);
}
.rs-breadcrumbs.bg-7 {
  background-image: url(./images/breadcrumbs/7.jpg);
}
.rs-breadcrumbs.bg-8 {
  background-image: url(./images/breadcrumbs/8.jpg);
}
.rs-breadcrumbs.bg-9 {
  background-image: url(./images/breadcrumbs/9.jpg);
}
.rs-breadcrumbs .content-part {
  padding: 160px 0;
}
.rs-breadcrumbs .content-part .breadcrumbs-title {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
}
/*---------------------------------------
    08. About Section CSS
---------------------------------------*/
.rs-about.style1 .services-part {
  display: inline-flex;
}
.rs-about.style1 .services-part .services-icon img {
  width: 80px;
  height: 80px;
  max-width: unset;
  transition: all 0.3s ease;
}
.rs-about.style1 .services-part .services-text {
  padding-left: 15px;
}
.rs-about.style1 .services-part .services-text .title {
  font-size: 20px;
  margin-bottom: 10px;
}
.rs-about.style1 .services-part:hover .services-icon img {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-about.style2 {
  position: relative;
}
.rs-about.style2 .cl-skill-bar .skillbar-title {
  font-weight: 400;
  color: #fff;
  font-size: 14px;
}
.rs-about.style2 .cl-skill-bar .skillbar {
  background: rgba(255, 255, 255, 0.6);
  height: 5px;
  margin: 17px 0 24px;
  position: relative;
  width: 100%;
}
.rs-about.style2 .cl-skill-bar .skillbar .skillbar-bar {
  background: #ffffff;
  height: 5px;
  width: 0;
  margin-bottom: 0;
}
.rs-about.style2 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -45px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.rs-about.style2:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 50%;
  top: 0;
}
.rs-about.style2:before {
  background: url(./images/about/about5-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  left: 0;
}
.rs-about.style4 .image-wrap img {
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.11);
}
.rs-about.style4 .sec-title.custom-law {
  position: relative;
  border-bottom: 1px dashed #b1976b;
  padding-bottom: 54px;
  margin-bottom: 19px;
}
.rs-about.style4 .sec-title.custom-law .justice-icon {
  width: 40px;
  height: 40px;
  line-height: 0;
  border-radius: 100%;
  text-align: center;
  padding: 10px;
  background: #ffffff;
  left: 50%;
  bottom: -19px;
  transform: translateX(-50%);
  position: absolute;
}
.rs-about.style4 .author-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rs-about.style4 .author-info .author-detail {
  padding-right: 20px;
}
.rs-about.style4 .author-info .author-detail .name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.rs-about.style4 .author-info .author-detail .designation {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #555;
}
.rs-about.style5 .counter-wrap .content-part {
  align-items: center;
  display: flex;
}
.rs-about.style5 .counter-wrap .content-part .counter-part {
  border-right: 2px solid #e0dddd;
  padding: 10px 0;
  margin-right: 30px;
  min-width: 154px;
}
.rs-about.style5 .counter-wrap .content-part .counter-part .rs-count {
  color: #d83030;
  line-height: 1;
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 7px;
  display: inline-block;
  position: relative;
}
.rs-about.style5 .counter-wrap .content-part .counter-part .rs-count.thousand {
  padding-right: 35px;
}
.rs-about.style5 .counter-wrap .content-part .counter-part .rs-count.thousand:after {
  position: absolute;
  content: "k";
  right: 0;
}
.rs-about.style5 .counter-wrap .content-part .counter-part .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #333333;
  letter-spacing: 2px;
}
.rs-about.style5 .img-part {
  position: relative;
}
.rs-about.style5 .img-part .morphin {
  position: absolute;
  right: 50px;
  bottom: 30px;
  width: 400px;
  height: 400px;
  z-index: -1;
}
.rs-about.style5 .img-part:after {
  position: absolute;
  content: '';
  bottom: 0;
}
.rs-about.style5 .wraping {
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.rs-about.style5 .wraping:after,
.rs-about.style5 .wraping:before {
  position: absolute;
  content: '';
  bottom: -1.5px;
  width: 250px;
  background: #ffffff;
  height: 3px;
}
.rs-about.style5 .wraping:after {
  left: 210px;
}
.rs-about.style5 .wraping:before {
  right: 210px;
}
.rs-about.style6 .img-part {
  position: relative;
}
.rs-about.style6 .img-part img {
  position: relative;
  z-index: 1;
}
.rs-about.style6 .img-part .morphin {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #d83030, #032e42);
  border: none;
}
.rs-about.style6 .img-part:after {
  position: absolute;
  content: '';
  bottom: 0;
}
.rs-about.style6 .video-part {
  display: flex;
  align-items: center;
}
.rs-about.style6 .video-part .title-part {
  padding-left: 30px;
}
.rs-about.style6 .video-part .title-part .title {
  color: #ffffff;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.rs-about.style7 {
  position: relative;
}
.rs-about.style7 .modify-gap {
  padding-right: 250px;
  padding-left: 70px;
}
.rs-about.style7 .images-about img {
  max-width: unset;
  width: 830px;
}
.rs-about.style7 .about-shape .animate-img {
  position: absolute;
  left: 50px;
  top: 580px;
}
.rs-about.style8 .widget-center {
  background: url(./images/about/exp-bg13.png);
  background-repeat: no-repeat;
  background-position: center;
  margin: -162px 0px 0px 260px;
  padding: 65px 0px 165px 0px;
  position: relative;
}
.rs-about.style8 .widget-center .content-part {
  position: absolute;
  left: 112px;
  text-align: center;
}
.rs-about.style8 .widget-center .content-part .title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 5px;
}
.rs-about.style8 .widget-center .content-part p {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0px 0px 0px 0px;
}
.rs-about.style9 .services-item {
  display: flex;
}
.rs-about.style9 .services-item .services-icon img {
  max-width: unset;
  height: 70px;
  width: 70px;
}
.rs-about.style9 .services-item .services-text {
  padding: 0px 0px 0px 18px;
}
.rs-about.style9 .services-item .services-text .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0;
}
.rs-about.style9 .services-item .services-text .title a {
  color: #0a0a0a;
}
.rs-about.style9 .services-item .services-text .title a:hover {
  color: #106eea;
}
.rs-about.style9 .services-item .services-text .services-txt {
  margin-bottom: 0;
}
.rs-about.style10 .accordion {
  border-width: 12px;
}
.rs-about.style10 .accordion .card {
  margin-bottom: 3px;
  border: none;
}
.rs-about.style10 .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-about.style10 .accordion .card .card-header {
  padding: 0 !important;
  border: none;
  background: #fff;
  padding: 16px 2px 16px 2px !important;
}
.rs-about.style10 .accordion .card .card-header .card-link {
  color: #1273EB;
  font-weight: 700;
}
.rs-about.style10 .accordion .card .card-body {
  background: #f9f9f9;
  font-weight: 400;
  color: #363636;
  padding: 17px 65px 40px 22px !important;
  border: none;
}
.rs-about.style10 .accordion .card .card-header .card-link {
  position: relative;
  padding-left: 44px !important;
}
.rs-about.style10 .accordion .card .card-header .card-link:after {
  content: "\f078";
  font-family: FontAwesome;
  position: absolute;
  left: 20px;
  color: #106eea;
  font-size: 15px;
  font-weight: 200;
  top: 50%;
  transform: translateY(-50%);
}
.rs-about.style10 .accordion .card .card-header .card-link.collapsed.card-link {
  color: #1c1b1b !important;
}
.rs-about.style10 .accordion .card .card-header .card-link.collapsed:after {
  content: "\f054";
  font-family: FontAwesome;
  color: #363636;
}
.rs-about.style10 .about-content {
  position: relative;
  z-index: 111;
}
.rs-about.style10 .about-content .rs-animations .spinner {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.rs-about.style10 .about-content .rs-animations .spinner.dot {
  top: -45px;
  left: -40px;
}
.rs-about.style10 .about-content .rs-animations .spinner.ball {
  bottom: -85px;
  right: 14%;
}
.rs-about.style10 .rs-divider {
  divider-border-style: none;
  --divider-border-width: 1px;
  --divider-color: #2c2c2c;
  --divider-icon-size: 20px;
  --divider-element-spacing: 10px;
  --divider-pattern-height: 24px;
  --divider-pattern-size: 20px;
  --divider-pattern-url: none;
  --divider-pattern-repeat: repeat-x;
}
.rs-about.style10 .rs-divider .line {
  position: relative;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.rs-about.style10 .rs-divider .line:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1rem;
  background: linear-gradient(90deg, var(--blue-color) 0 50%, transparent 0 100%) 0 0rem 1rem;
  mask: linear-gradient(-90deg, #000000, transparent);
}
.rs-about.style11 {
  position: relative;
}
.rs-about.style11 .images-part {
  border-style: solid;
  border-width: 20px 20px 20px 20px;
  border-color: #fff;
  border-radius: 50px 50px 50px 50px;
}
.rs-about.style11 .images-part img {
  border-radius: 20px 20px 20px 20px;
}
.rs-about.style11 .animations {
  position: absolute;
  top: 7%;
  right: 29%;
}
.rs-about.inner .image-part {
  position: relative;
}
.rs-about.inner .image-part img {
  border-radius: 10px;
}
.rs-about.inner .image-part .author-info {
  background: #106eea;
  text-align: center;
  padding: 30px 30px;
  width: 270px;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -64px;
}
.rs-about.inner .image-part .author-info .name {
  color: #ffffff;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.rs-about.inner .image-part .author-info .designation {
  color: #ffffff;
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 2.5px;
}
/*---------------------------------------
    09. Services Section CSS
---------------------------------------*/
.rs-services.style1 .service-wrap {
  padding: 30px;
  text-align: center;
  background: #f1f6fc;
  box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}
.rs-services.style1 .service-wrap .icon-part {
  margin-bottom: 25px;
}
.rs-services.style1 .service-wrap .icon-part img {
  max-width: 70px;
  transition: all 0.3s ease;
}
.rs-services.style1 .service-wrap .icon-part.size-mod img {
  max-width: 58px;
}
.rs-services.style1 .service-wrap .content-part .title {
  margin-bottom: 9px;
}
.rs-services.style1 .service-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-services.style1 .service-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-services.style1 .service-wrap:hover .icon-part img {
  animation-name: shake-x;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-services.style1.modify .service-wrap:hover .icon-part img {
  animation-name: shake-y;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-services.style1.modify2 .service-wrap {
  box-shadow: none;
  border-radius: 0;
}
.rs-services.style1.modify2 .service-wrap:hover .icon-part img {
  animation-name: shake-y;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-services.style2 .service-wrap {
  padding: 15px 15px 30px;
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07);
}
.rs-services.style2 .service-wrap .image-part {
  overflow: hidden;
  margin-bottom: 20px;
}
.rs-services.style2 .service-wrap .image-part img {
  transform: scale(1);
  transition: all 0.9s;
}
.rs-services.style2 .service-wrap .content-part .title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}
.rs-services.style2 .service-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-services.style2 .service-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-services.style2 .service-wrap:hover .image-part img {
  transform: scale(1.1);
}
.rs-services.style3 .service-wrap {
  text-align: center;
  background: #f1f6fc;
  border-radius: 40px;
  padding: 40px 30px 41px;
}
.rs-services.style3 .service-wrap .icon-part {
  margin-bottom: 26px;
}
.rs-services.style3 .service-wrap .icon-part img {
  width: 70px;
  margin: 0 auto;
}
.rs-services.style3 .service-wrap .title {
  font-weight: 600;
  margin-bottom: 12px;
}
.rs-services.style3 .service-wrap .title a {
  color: #1c1b1b;
}
.rs-services.style3 .service-wrap .title a:hover {
  color: #106eea;
}
.rs-services.style4 {
  position: relative;
  z-index: 1;
}
.rs-services.style4 .image-part img {
  border-radius: 10px;
}
.rs-services.style4 .service-wrap .icon-part {
  margin-bottom: 14px;
}
.rs-services.style4 .service-wrap .icon-part img {
  max-width: 55px;
}
.rs-services.style4 .service-wrap .content-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 4px;
}
.rs-services.style4 .service-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-services.style4 .service-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-services.style5 {
  position: relative;
  z-index: 1;
}
.rs-services.style5 .content-part {
  background: #ffffff;
  padding: 30px 0 22px;
  border-radius: 4px;
  text-align: center;
}
.rs-services.style5 .content-part .rs-pie-content {
  margin-bottom: 10px;
}
.rs-services.style5 .content-part .rs-pie-content .rs-pie2 {
  display: inline-block;
  position: relative;
  text-align: center;
}
.rs-services.style5 .content-part .rs-pie-content .rs-pie2 .rspie-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #101010;
}
.rs-services.style5 .content-part .pie-title {
  margin-bottom: 0;
  color: #101010;
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
}
.rs-services.style5:after,
.rs-services.style5:before {
  position: absolute;
  content: '';
  top: 0;
  height: 100%;
  z-index: -1;
}
.rs-services.style5:before {
  left: 0;
  width: 47%;
  background: url(./images/services/style5/left-img.jpg);
}
.rs-services.style5:after {
  right: 0;
  width: 53%;
  background: url(./images/services/style5/right-img.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.rs-services.style6 .services-wrap .icon-part {
  margin-bottom: 17px;
}
.rs-services.style6 .services-wrap .icon-part i {
  font-size: 40px;
  color: #898989;
}
.rs-services.style6 .services-wrap .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 10px;
}
.rs-services.style6 .services-wrap .title a {
  color: #ffffff;
}
.rs-services.style6 .services-wrap .title a:hover {
  color: #106eea;
}
.rs-services.style6 .services-wrap .desc {
  color: #ffffff;
}
.rs-services.style7 .services-wrap {
  padding: 38px 30px 36px;
  box-shadow: 0 0 72px -10px rgba(0, 0, 0, 0.22);
  text-align: center;
  border-radius: 30px;
}
.rs-services.style7 .services-wrap .icon-part {
  margin-bottom: 19px;
}
.rs-services.style7 .services-wrap .icon-part img {
  max-width: 80px;
}
.rs-services.style7 .services-wrap .title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}
.rs-services.style7 .services-wrap .title a {
  color: #0a0a0a;
}
.rs-services.style7 .services-wrap .title a:hover {
  color: #67c27c;
}
.rs-services.style7 .more-btn {
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
}
.rs-services.style7 .more-btn a {
  color: #67c27c;
}
.rs-services.style8 .service-wrap {
  padding: 50px 62px;
  background: #ffffff;
  box-shadow: 0px 0px 34px 20px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}
.rs-services.style8 .service-wrap .icon-part {
  margin-bottom: 24px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
}
.rs-services.style8 .service-wrap .icon-part img {
  margin-bottom: -36px;
  z-index: 1;
  position: relative;
}
.rs-services.style8 .service-wrap .icon-part:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #106eea;
  opacity: 0.7;
  background: #fceceb;
}
.rs-services.style8 .service-wrap .icon-part.two:after {
  background: #ecf3ff;
}
.rs-services.style8 .service-wrap .icon-part.three:after {
  background: #e5f9f6;
}
.rs-services.style8 .service-wrap .content-part .title {
  margin-bottom: 9px;
  font-weight: 600;
  line-height: 32px;
}
.rs-services.style8 .service-wrap .content-part .title a {
  color: #05275a;
}
.rs-services.style8 .service-wrap .content-part .read-btn a {
  color: #606e84;
  font-weight: 600;
  font-size: 15px;
}
.rs-services.style8 .service-wrap .content-part .read-btn a i {
  transition: all 0.3s ease;
  padding-left: 5px;
}
.rs-services.style8 .service-wrap .content-part .read-btn a:hover i {
  padding-left: 10px;
}
.rs-services.style8 .service-wrap:hover .icon-part {
  animation-name: shake-x;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-services.style9 .service-wrap {
  box-shadow: 0px 0px 45px -22px rgba(0, 0, 0, 0.22);
  padding: 40px 25px 30px;
  background-image: linear-gradient(180deg, #ffffff 0%, #f2f4fc 100%);
}
.rs-services.style9 .service-wrap .icon-part {
  margin-bottom: 27px;
}
.rs-services.style9 .service-wrap .icon-part img {
  max-width: 70px;
}
.rs-services.style9 .service-wrap .title {
  margin-bottom: 18px;
}
.rs-services.style9 .service-wrap .title a {
  color: #1c1b1b;
}
.rs-services.style9 .service-wrap .title a:hover {
  color: #1c3988;
}
.rs-services.style9 .bottom-link {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
}
.rs-services.style9 .bottom-link a {
  color: #1c3988;
}
.rs-services.style10 .service-wrap {
  padding: 60px 50px 57px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.03);
  border-right: 1px solid #F4FBFF;
  transition: all 0.3s ease;
}
.rs-services.style10 .service-wrap .icon-part {
  margin-bottom: 22px;
  transition: all 0.3s ease;
}
.rs-services.style10 .service-wrap .icon-part img {
  width: 60px;
}
.rs-services.style10 .service-wrap .content-part .title {
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.rs-services.style10 .service-wrap .content-part .title a {
  color: #101010;
}
.rs-services.style10 .service-wrap .content-part .title a:hover {
  color: #ec4c6f;
}
.rs-services.style10 .service-wrap.last-item {
  border: none;
}
.rs-services.style10 .service-wrap:hover {
  box-shadow: 0px 1px 20px 3px rgba(0, 0, 0, 0.08);
}
.rs-services.style11 .service-wraping {
  padding: 40px 50px 50px;
  position: relative;
  z-index: 1;
}
.rs-services.style11 .service-wraping .top-part {
  position: relative;
  border-bottom: 1px dashed #b1976b;
}
.rs-services.style11 .service-wraping .top-part .title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 32px;
}
.rs-services.style11 .service-wraping .top-part .justice-icon {
  width: 40px;
  height: 40px;
  line-height: 0;
  border-radius: 100%;
  text-align: center;
  padding: 10px;
  background: #282e3f;
  left: 50%;
  bottom: -19px;
  transform: translateX(-50%);
  position: absolute;
}
.rs-services.style11 .service-wraping .service-wrap {
  transition: all 0.3s ease;
  display: flex;
  padding-right: 30px;
}
.rs-services.style11 .service-wraping .service-wrap .icon-part {
  margin-right: 22px;
  transition: all 0.3s ease;
}
.rs-services.style11 .service-wraping .service-wrap .icon-part img {
  width: 60px;
  max-width: 60px;
}
.rs-services.style11 .service-wraping .service-wrap .content-part .title {
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.rs-services.style11 .service-wraping .service-wrap .content-part .title a {
  color: #ffffff;
}
.rs-services.style11 .service-wraping .service-wrap .content-part .title a:hover {
  color: #b1976b;
}
.rs-services.style12 .service-wrap {
  padding: 50px 35px;
  background-image: linear-gradient(130deg, #1e2433 0%, #424c65 100%);
  transition: all 0.3s ease;
  text-align: center;
}
.rs-services.style12 .service-wrap .icon-part img {
  width: 64px;
  margin: 0 auto;
}
.rs-services.style12 .service-wrap .content-part {
  color: #ffffff;
}
.rs-services.style12 .service-wrap .content-part .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 32px;
}
.rs-services.style12 .service-wrap .content-part .title a {
  color: #ffffff;
}
.rs-services.style12 .service-wrap .content-part .title a:hover {
  color: #b1976b;
}
.rs-services.style12 .service-wrap .content-part .btn-part a {
  font-size: 15px;
  font-weight: 500;
  color: #b1976b;
  text-transform: uppercase;
  padding-right: 15px;
}
.rs-services.style12 .service-wrap .content-part .btn-part a:after {
  content: "\f113";
  font-size: 10px;
  color: #b1976b;
}
.rs-services.style12 .service-wrap:hover {
  background-image: radial-gradient(at center center, #424c65 0%, #1e2433 100%);
}
.rs-services.style13 .service-wrap {
  text-align: center;
  background: #ffffff;
  padding: 15px 15px 0 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
.rs-services.style13 .service-wrap .img-part {
  position: relative;
}
.rs-services.style13 .service-wrap .img-part .icon-part {
  width: 70px;
  height: 70px;
  line-height: 64px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  background: #d83030;
  color: #ffffff;
  box-shadow: 0 0 30px #a79f9f;
  font-size: 30px;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  margin: 0 auto;
}
.rs-services.style13 .service-wrap .content-part {
  position: relative;
  padding: 45px 0 73px;
}
.rs-services.style13 .service-wrap .content-part .title {
  font-size: 22px;
  line-height: 40px;
  margin-bottom: 10px;
}
.rs-services.style13 .service-wrap .content-part .title a {
  color: #032e42;
}
.rs-services.style13 .service-wrap .content-part .title a:hover {
  color: #d83030;
}
.rs-services.style13 .service-wrap .content-part .btn-part {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 0.3s ease;
}
.rs-services.style13 .service-wrap .content-part .btn-part a {
  font-size: 20px;
  padding: 10px;
  color: #ffffff;
}
.rs-services.style13 .service-wrap .content-part:after,
.rs-services.style13 .service-wrap .content-part:before {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  margin: 0 auto;
  transition: all 0.3s ease;
}
.rs-services.style13 .service-wrap .content-part:before {
  width: 170px;
  height: 170px;
  border: 1px solid #f3f3f3;
  bottom: -125px;
}
.rs-services.style13 .service-wrap .content-part:after {
  background: #f3f3f3;
  width: 126px;
  height: 126px;
  bottom: -98px;
}
.rs-services.style13 .service-wrap:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background: #d83030;
  transition: all 0.5s;
}
.rs-services.style13 .service-wrap:hover .content-part .btn-part {
  bottom: 0;
}
.rs-services.style13 .service-wrap:hover .content-part:before {
  width: 182px;
  height: 182px;
  border-color: #d83030;
}
.rs-services.style13 .service-wrap:hover .content-part:after {
  width: 140px;
  height: 140px;
  background: #d83030;
}
.rs-services.style13 .service-wrap:hover:after {
  width: 100%;
}
.rs-services.style14 .flip-box-inner {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  background-color: #FFFFFF;
  text-align: center;
  padding: 50px 40px 50px 40px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #1c1b1b;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #106eea;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  color: #444;
  margin: 0;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateX(180deg);
  position: absolute;
  z-index: -1;
  padding: 30px;
  border-radius: 5px;
  background-color: #562dd4;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: transparent;
  background-position: center;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style14 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title a {
  color: #ffffff;
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateX(0);
  z-index: 1;
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.paste-light {
  background-image: linear-gradient(90deg, #00ebd3 0%, #00b0e8 100%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.pink-light {
  background-image: linear-gradient(90deg, #f67d31 0%, #ed3b4b 58%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.dark-blue {
  background-image: linear-gradient(90deg, #2647c8 0%, #c131f6 58%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.pink-dark {
  background-image: linear-gradient(90deg, #facc22 0%, #f83600 58%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.dark-paste {
  background-image: linear-gradient(90deg, #2ad45c 0%, #1fa2cb 58%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front.light-blue {
  background-image: linear-gradient(90deg, #0dccff 0%, #4760ff 58%);
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  color: #ffffff;
}
.rs-services.style14 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-desc-part .back-desc {
  color: #ffffff;
}
.rs-services.style14.it-solutions .flip-box-inner {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap {
  -webkit-perspective: 1e3px;
  perspective: 1e3px;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  background-color: #fff;
  text-align: left;
  padding: 50px 40px 50px 40px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #1c1b1b;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #106eea;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  color: #444;
  margin: 0;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateY(-180deg);
  background-color: #562dd4;
  perspective: 1e3px;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.9s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.9s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  background-image: linear-gradient(90deg, #1672de 0%, #071f6b 58%);
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style14.it-solutions .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title a {
  color: #ffffff;
}
.rs-services.style14.it-solutions .flip-box-inner:hover .flip-box-wrap .front-part {
  transform: rotateY(180deg);
}
.rs-services.style14.it-solutions .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateY(0);
  z-index: 1;
}
.rs-services.style14.it-solutions .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  color: #ffffff;
}
.rs-services.style14.it-solutions .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-desc-part .back-desc {
  color: #ffffff;
}
.rs-services.style15 .addon-services {
  background: url(./images/services/style10/service-shape.png);
  background-repeat: no-repeat;
  background-position: 200px 150px;
  padding: 45px 26px 40px 40px;
  background-color: #fff;
  box-shadow: 0 0 30px #eee;
  text-align: center;
}
.rs-services.style15 .addon-services .services-icon {
  margin-bottom: 20px;
}
.rs-services.style15 .addon-services .services-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
}
.rs-services.style15 .addon-services .services-text .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.rs-services.style15 .addon-services .services-text .title a {
  color: #111111;
}
.rs-services.style15 .addon-services .services-text .title a:hover {
  color: #12adfc;
}
.rs-services.style15 .addon-services .services-text .services-txt {
  margin-bottom: 0;
}
.rs-services.style15.modify1 .addon-services {
  background: url(./images/services/style16/service-box.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 7px 25px 0px #eee;
}
.rs-services.style15.modify1 .addon-services .services-text .title {
  margin-bottom: 10px;
}
.rs-services.style15.modify1 .addon-services .services-text .title a:hover {
  color: #bf0505;
}
.rs-services.style16 .services-wrap {
  margin: 0px 7px 0px 0px;
  transition: background 0.6s;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #DDE2E5;
  border-radius: 3px 3px 3px 3px;
  padding: 50px 40px 40px 40px;
  transition: all ease .3s;
}
.rs-services.style16 .services-wrap .icon-part {
  background-image: linear-gradient(200deg, #00a1f5 0%, #015eea 96%);
  border-radius: 2px;
  margin-left: 11px;
  margin-top: 11px;
  transition: .4s;
  height: 55px;
  display: inline-block;
  padding: 5px;
  min-width: 55px;
  text-align: center;
  transform: rotate(45deg);
  margin-bottom: 30px;
}
.rs-services.style16 .services-wrap .icon-part i {
  transform: rotate(-45deg);
  transition: all ease .3s;
}
.rs-services.style16 .services-wrap .icon-part i:before {
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
}
.rs-services.style16 .services-wrap .services-txt .services-title {
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 14px;
}
.rs-services.style16 .services-wrap .services-txt .services-title a {
  color: #1c1b1b;
}
.rs-services.style16 .services-wrap .services-txt .services-txt {
  margin-bottom: 20px;
}
.rs-services.style16 .services-wrap .services-txt .serial-number {
  color: #B1E0F9;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.rs-services.style16 .services-wrap:hover {
  background: url(./images/bg/service-bg2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-services.style16 .services-wrap:hover .icon-part {
  background: #ffffff;
}
.rs-services.style16 .services-wrap:hover .icon-part i {
  transform: rotate(-45deg);
}
.rs-services.style16 .services-wrap:hover .icon-part i:before {
  color: #106eea;
}
.rs-services.style16 .services-wrap:hover .services-txt .services-title a {
  color: #ffffff;
}
.rs-services.style16 .services-wrap:hover .services-txt .services-txt {
  color: #ffffff;
}
.rs-services.style16 .services-wrap:hover .services-txt .serial-number {
  color: #ffffff;
}
.rs-services.style17 .services-item {
  padding: 5px 5px 5px 5px;
  box-shadow: 0px 0px 10px 0px #eee;
  background: #ffffff;
  margin: 0 -10px 0 0;
}
.rs-services.style17 .services-item .services-img {
  padding: 5px 5px 5px 5px;
}
.rs-services.style17 .services-item .services-content {
  padding: 30px 0px 30px 10px;
}
.rs-services.style17 .services-item .services-content .services-title .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}
.rs-services.style17 .services-item .services-content .services-title .title a {
  color: #0a0a0a;
}
.rs-services.style17 .services-item .services-content .services-title .title a:hover {
  color: #106eea;
}
.rs-services.style17 .services-item .services-content .services-txt {
  margin-bottom: 0;
  padding-right: 20px;
}
.rs-services.style18 {
  margin-top: -160px;
  padding-top: 260px;
  position: relative;
}
.rs-services.style18 .services-item {
  box-shadow: 0px 0px 45px 22px #eee;
  background: #fdfdfe;
  margin: 0px -15px 0px 0px;
  padding: 40px 25px 30px 25px;
  text-align: center;
}
.rs-services.style18 .services-item .services-icon {
  margin-bottom: 15px;
}
.rs-services.style18 .services-item .services-icon a img {
  height: 70px;
}
.rs-services.style18 .services-item .text-area .title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 16px;
}
.rs-services.style18 .services-item .text-area .title a {
  color: #0a0a0a;
}
.rs-services.style18 .services-item .text-area .title a:hover {
  color: #1273eb;
}
.rs-services.style18 .services-item .text-area .services-txt {
  margin-bottom: 0;
}
.rs-services.style18 .bottom-link {
  font-size: 18px;
  font-weight: 500;
}
.rs-services.style18 .bottom-link a {
  color: #1C3988;
}
.rs-services.style19 .services-item .services-wrap {
  padding: 100px 50px 70px 50px;
  position: relative;
}
.rs-services.style19 .services-item .services-wrap .shape-part {
  position: absolute;
  top: 72px;
  right: 79px;
  transition: all .3s ease 0s;
}
.rs-services.style19 .services-item .services-wrap .icon-part {
  display: inline-block;
  padding: 5px;
  font-size: 45px;
  color: #fff;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  position: relative;
  background: #fc6638;
  margin: 0 auto 42px;
  z-index: 1;
  text-align: center;
}
.rs-services.style19 .services-item .services-wrap .icon-part:before {
  position: absolute;
  content: '';
  right: -1px;
  bottom: -2px;
  width: 112px;
  height: 112px;
  border-radius: 100%;
  border: 1px solid #fc6638;
  transition: all .3s ease 0s;
  z-index: 0;
}
.rs-services.style19 .services-item .services-wrap .icon-part i:before {
  font-size: 45px;
}
.rs-services.style19 .services-item .services-wrap .icon-part.purple-bg {
  background: #6C58FA;
}
.rs-services.style19 .services-item .services-wrap .icon-part.purple-bg:before {
  border: 1px solid #6C58FA;
}
.rs-services.style19 .services-item .services-wrap .icon-part.blue-bg {
  background: #323E79;
}
.rs-services.style19 .services-item .services-wrap .icon-part.blue-bg:before {
  border: 1px solid #323E79;
}
.rs-services.style19 .services-item .services-wrap .services-content .services-title .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin: 0 0 20px;
  font-family: Poppins;
}
.rs-services.style19 .services-item .services-wrap .services-content .services-title .title a {
  color: #04004d;
}
.rs-services.style19 .services-item .services-wrap .services-content .services-title .title a:hover {
  color: #ec4c6f;
}
.rs-services.style19 .services-item .services-wrap .services-content .services-txt {
  color: #555555;
  margin-bottom: 0;
}
.rs-services.style19 .services-item:hover .icon-part:before {
  opacity: 0;
  transform: scale(0.85);
}
.rs-services.style19 .hover-effect:hover .services-item:hover,
.rs-services.style19 .hover-effect:not(:hover) .active {
  box-shadow: 3px 7px 25px #eee;
  background: #fff;
}
.rs-services.style19 .hover-effect:hover .services-item:hover .icon-part .shape,
.rs-services.style19 .hover-effect:not(:hover) .active .icon-part .shape {
  animation: move-y 2s alternate infinite;
  -webkit-animation: move-y 2s alternate infinite;
}
.rs-services.style20 .services-item .iconbox-area {
  background: #fff;
  box-shadow: 0px 0px 45px 22px #eee;
  padding: 35px;
  border-radius: 5px 5px 5px 5px;
  margin: 0 -13px 0 0;
}
.rs-services.style20 .services-item .iconbox-area .icon-part {
  margin-bottom: 20px;
}
.rs-services.style20 .services-item .iconbox-area .icon-part a img {
  width: 64px;
}
.rs-services.style20 .services-item .iconbox-area .services-content .title {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 15px;
}
.rs-services.style20 .services-item .iconbox-area .services-content .title a {
  color: #0a0a0a;
}
.rs-services.style20 .services-item .iconbox-area .services-content .title a:hover {
  color: #106eea;
}
.rs-services.style20 .services-item .iconbox-area .services-content .services-txt {
  margin: 0;
}
.rs-services.style20 .services-item:hover .iconbox-area .icon-part {
  animation-name: elementor-animation-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-services.style20 .bottom-link {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #1c1b1b;
}
.rs-services.style20 .bottom-link a {
  color: #106eea;
}
.rs-services.style20 .bottom-link a:hover {
  color: #ec4c6f;
}
.rs-services.style21 .services-item .services-wrap {
  padding: 50px 25px 50px 25px;
  text-align: left;
  background-image: linear-gradient(160deg, #e68a8a 0%, #f8f9fa 52%);
  border-radius: 10px 10px 0px 0px;
  position: relative;
}
.rs-services.style21 .services-item .services-wrap .services-icon {
  margin-bottom: 25px;
}
.rs-services.style21 .services-item .services-wrap .services-icon .main-img {
  height: 50px;
  width: 50px;
  max-width: unset;
  transition: all .3s ease;
}
.rs-services.style21 .services-item .services-wrap .services-icon .hover-img {
  max-width: unset;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 23px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all .3s ease;
}
.rs-services.style21 .services-item .services-wrap .services-content .title {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
.rs-services.style21 .services-item .services-wrap .services-content .title a {
  color: #0a0a0a;
}
.rs-services.style21 .services-item .services-wrap .services-content .services-txt {
  margin-bottom: 0px;
}
.rs-services.style21 .services-item .services-wrap:after {
  display: block;
  width: 100%;
  height: 5px;
  background: #BB0B0B;
  content: '';
  position: absolute;
  transition: all .3s ease 0s;
  bottom: 0;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
}
.rs-services.style21 .services-item .services-wrap:hover {
  background-image: linear-gradient(120deg, #bf0505 0%, #21081a 100%);
  border-radius: 10px 10px 0px 0px;
}
.rs-services.style21 .services-item .services-wrap:hover .services-icon {
  margin-bottom: 25px;
}
.rs-services.style21 .services-item .services-wrap:hover .services-icon .main-img {
  transform: scale(0);
}
.rs-services.style21 .services-item .services-wrap:hover .services-icon .hover-img {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.rs-services.style21 .services-item .services-wrap:hover .services-content .title a {
  color: #ffffff;
}
.rs-services.style21 .services-item .services-wrap:hover .services-content .services-txt {
  color: #ffffff;
}
.rs-services.style22 {
  position: relative;
}
.rs-services.style22 .service-wrap {
  display: flex;
  padding: 30px 32px 30px 32px;
}
.rs-services.style22 .service-wrap .icon-part img {
  width: 66px;
  height: 66px;
  max-width: unset;
}
.rs-services.style22 .service-wrap .content-part {
  padding: 0px 0px 0px 28px;
}
.rs-services.style22 .service-wrap .content-part .title {
  font-size: 21px;
  line-height: 31px;
  font-weight: 600;
  margin: 0 0 10px;
}
.rs-services.style22 .service-wrap .content-part .title a {
  color: #0a0a0a;
}
.rs-services.style22 .service-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-services.style22 .service-wrap.light-bg {
  background-image: linear-gradient(180deg, #f9fcff 0%, #dceaf3 100%);
}
.rs-services.style22 .animate-style {
  position: absolute;
  left: 11%;
  bottom: 74px;
}
/*---------------------------------------
    10. Video Section CSS
---------------------------------------*/
.rs-video .content-part {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
}
.rs-video .content-part .video-desc {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.7;
}
.rs-video.style2 .video-skill .skillsets-part {
  background: url(./images/bg/skill-bg16.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #070F3A;
  padding: 60px 50px 60px 60px;
  border-radius: 10px 0px 0px 10px;
}
.rs-video.style2 .video-skill .video-wrap {
  background: url(./images/bg/video-bg16.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  padding: 244px 0 260px 0;
  border-radius: 0px 10px 10px 0px;
  margin-left: -30px;
}
/*---------------------------------------
    11. Why Choose Us Section CSS
---------------------------------------*/
.rs-whychooseus.style1 {
  position: relative;
}
.rs-whychooseus.style1 .container {
  z-index: 1;
  position: relative;
}
.rs-whychooseus.style1 .item-wrap {
  padding: 40px 30px 41px;
  border-radius: 40px;
  background: #f1f6fc;
  text-align: center;
}
.rs-whychooseus.style1 .item-wrap .icon-part {
  margin-bottom: 22px;
}
.rs-whychooseus.style1 .item-wrap .icon-part img {
  max-width: 65px;
  margin: 0 auto;
}
.rs-whychooseus.style1 .item-wrap .title {
  margin-bottom: 0;
  font-weight: 600;
}
.rs-whychooseus.style1 .item-wrap .title a {
  color: #1c1b1b;
}
.rs-whychooseus.style1 .item-wrap .title a:hover {
  color: #106eea;
}
.rs-whychooseus.style1 .video-wrap {
  background: url(./images/whychooseus/video-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  border-radius: 40px;
  position: relative;
}
.rs-whychooseus.style1 .video-wrap .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-whychooseus.style1:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.78;
  z-index: 0;
}
.rs-whychooseus.style2 .rs-counter-list {
  text-align: center;
  box-shadow: 0 0 72px -10px rgba(0, 0, 0, 0.22);
  padding: 42px 30px 35px;
  border-radius: 30px;
}
.rs-whychooseus.style2 .rs-counter-list .icon-part {
  margin-bottom: 20px;
}
.rs-whychooseus.style2 .rs-counter-list .icon-part img {
  max-width: 70px;
  margin: 0 auto;
}
.rs-whychooseus.style2 .rs-counter-list .counter-text .rs-count {
  font-size: 44px;
  line-height: 60px;
  font-weight: 600;
  color: #1c1b1b;
  position: relative;
  display: inline-block;
  padding-right: 28px;
  margin-bottom: 16px;
}
.rs-whychooseus.style2 .rs-counter-list .counter-text .rs-count:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #1c1b1b;
  opacity: 0.9;
}
.rs-whychooseus.style2 .rs-counter-list .counter-text .title {
  font-size: 16px;
  line-height: 18px;
  color: #666;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
}
.rs-whychooseus.style2 .rs-counter-list.plus .counter-text .rs-count:after {
  content: "+";
}
.rs-whychooseus.style2 .rs-counter-list.thousand .counter-text .rs-count:after {
  content: "K";
  right: -3px;
}
.rs-whychooseus.style3 {
  background-image: linear-gradient(180deg, #f8f2fd 0%, #e7f1fd 100%);
  background-color: transparent;
}
.rs-whychooseus.style3 .content-wrap .item-part {
  display: inline-flex;
}
.rs-whychooseus.style3 .content-wrap .item-part .icon-part img {
  width: 70px;
  height: 70px;
  max-width: unset;
}
.rs-whychooseus.style3 .content-wrap .item-part .desc-text {
  padding-left: 30px;
}
.rs-whychooseus.style3 .content-wrap .item-part .desc-text .title {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 32px;
  font-weight: 600;
  color: #101010;
}
.rs-whychooseus.style3 .image-wrap {
  position: relative;
}
.rs-whychooseus.style3 .image-wrap .ly {
  position: absolute;
}
.rs-whychooseus.style3 .image-wrap .ly.ly1 {
  top: 52px;
  right: 35px;
  max-width: 312px;
  animation: left-right 4s alternate infinite;
}
.rs-whychooseus.style3 .image-wrap .ly.ly2 {
  bottom: 87px;
  right: 35px;
  max-width: 232px;
}
.rs-whychooseus.style3 .image-wrap .ly.ly3 {
  left: -37px;
  bottom: -30px;
  max-width: 240px;
  animation: up-down 4s alternate infinite;
}
.rs-whychooseus.style4 .content-part {
  display: inline-flex;
}
.rs-whychooseus.style4 .content-part .icon-part img {
  width: 70px;
  height: 70px;
  max-width: unset;
}
.rs-whychooseus.style4 .content-part .text-part {
  padding-left: 35px;
}
.rs-whychooseus.style4 .content-part .text-part .title {
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
}
.rs-whychooseus.style5 .content-wrap .item-part {
  display: inline-flex;
}
.rs-whychooseus.style5 .content-wrap .item-part .icon-part img {
  width: 55px;
  max-width: unset;
}
.rs-whychooseus.style5 .content-wrap .item-part .desc-text {
  padding-left: 25px;
}
.rs-whychooseus.style5 .content-wrap .item-part .desc-text .title {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 32px;
  font-weight: 700;
  color: #333333;
}
.rs-whychooseus.style5 .image-wrap {
  position: relative;
}
.rs-whychooseus.style5 .image-wrap .ly {
  position: absolute;
}
.rs-whychooseus.style5 .image-wrap .ly.ly1 {
  top: 52px;
  right: 35px;
  max-width: 312px;
  animation: left-right 4s alternate infinite;
}
.rs-whychooseus.style5 .image-wrap .ly.ly2 {
  bottom: 87px;
  right: 35px;
  max-width: 232px;
}
.rs-whychooseus.style5 .image-wrap .ly.ly3 {
  left: -37px;
  bottom: -30px;
  max-width: 240px;
  animation: up-down 4s alternate infinite;
}
.rs-whychooseus.style6 .img-part {
  position: relative;
  display: inline-block;
}
.rs-whychooseus.style6 .img-part .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-whychooseus.style6 .content-wrap .item-part {
  display: inline-flex;
  border-bottom: 1px solid #F7F7F7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.rs-whychooseus.style6 .content-wrap .item-part .icon-part {
  padding-top: 10px;
}
.rs-whychooseus.style6 .content-wrap .item-part .icon-part img {
  width: 65px;
  max-width: unset;
}
.rs-whychooseus.style6 .content-wrap .item-part .desc-text {
  padding-left: 25px;
}
.rs-whychooseus.style6 .content-wrap .item-part .desc-text .title {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 32px;
  font-weight: 600;
  color: #101010;
}
.rs-whychooseus.style6 .content-wrap .item-part:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
.rs-whychooseus.style7 .iconbox-area {
  padding: 60px 35px 60px 30px;
  box-shadow: 0 0 30px #eee;
  background: #ffffff;
  margin: 0 -15px 0 0;
}
.rs-whychooseus.style7 .iconbox-area .box-content {
  text-align: center;
}
.rs-whychooseus.style7 .iconbox-area .box-content .icon-area {
  display: inline-block;
  color: #101010;
  transition: all ease .3s;
  margin-bottom: 30px;
}
.rs-whychooseus.style7 .iconbox-area .box-content .icon-area a img {
  width: 80px;
}
.rs-whychooseus.style7 .iconbox-area .box-content .text-area .title {
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  margin-bottom: 20px;
}
.rs-whychooseus.style7 .iconbox-area .box-content .text-area .title a {
  color: #0a0a0a;
}
.rs-whychooseus.style7 .iconbox-area .box-content .text-area .title a:hover {
  color: #106eea;
}
.rs-whychooseus.style7 .iconbox-area .box-content .text-area .services-txt {
  margin-bottom: 0;
}
.rs-whychooseus.style7 .iconbox-area:hover .box-content .icon-area {
  animation-name: elementor-animation-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.rs-whychooseus.style8 .services-part {
  display: flex;
}
.rs-whychooseus.style8 .services-part .services-icon {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #E2E2E2;
  display: inline-block;
  padding: 5px;
  line-height: 50px;
  color: #368DF0;
  min-width: 60px;
  height: 60px;
  transition: all ease .3s;
  border-radius: 50%;
  text-align: center;
}
.rs-whychooseus.style8 .services-part .services-icon i {
  transition: all ease .3s;
}
.rs-whychooseus.style8 .services-part .services-icon i:before {
  font-size: 20px;
}
.rs-whychooseus.style8 .services-part .services-text {
  padding: 0px 0px 0px 25px;
}
.rs-whychooseus.style8 .services-part .services-text .services-title .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-whychooseus.style8 .services-part .services-text .services-title .title a {
  color: #111111;
}
.rs-whychooseus.style8 .services-part .services-text .services-title .title a:hover {
  color: #106eea;
}
.rs-whychooseus.style8 .services-part .services-text .services-txt {
  color: #666666;
  margin: 0;
}
.rs-whychooseus.style8 .services-part:hover .services-icon i {
  transition: all ease .3s;
}
.rs-whychooseus.style8 .services-part:hover .services-icon i:before {
  font-size: 20px;
}
.rs-whychooseus.style8 .images-part {
  position: relative;
  z-index: 11;
}
.rs-whychooseus.style8 .rs-animations .shape-icons {
  position: absolute;
  z-index: 1;
}
.rs-whychooseus.style8 .rs-animations .shape-icons.one {
  right: 8%;
  top: 10%;
}
.rs-whychooseus.style8 .rs-animations .shape-icons.two {
  left: 12%;
  bottom: 3%;
}
.rs-whychooseus.style9 {
  position: relative;
  z-index: 111;
}
.rs-whychooseus.style9 .services-item {
  display: flex;
}
.rs-whychooseus.style9 .services-item .services-icon {
  min-width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: #FC6638;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.rs-whychooseus.style9 .services-item .services-icon i {
  text-align: center;
  position: relative;
  top: 3px;
  height: 65px;
  min-width: 65px;
  line-height: 65px;
  border-radius: 50%;
  background-color: #FC6638;
  box-shadow: 0px 2px 15px 1px #ed2b09;
  display: inline-block;
}
.rs-whychooseus.style9 .services-item .services-icon i:before {
  font-size: 24px;
  color: #ffffff;
}
.rs-whychooseus.style9 .services-item .services-icon.purple-bg {
  background-color: #6C58FA;
}
.rs-whychooseus.style9 .services-item .services-icon.purple-bg i {
  background-color: #6C58FA;
  box-shadow: 0px 2px 15px 1px #6f42ca;
}
.rs-whychooseus.style9 .services-item .services-icon.blue-bg {
  background-color: #323E78;
}
.rs-whychooseus.style9 .services-item .services-icon.blue-bg i {
  background-color: #323E78;
  box-shadow: 0px 2px 15px 1px #282e68;
}
.rs-whychooseus.style9 .services-item .services-text {
  padding-left: 25px;
}
.rs-whychooseus.style9 .services-item .services-text .services-title .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-whychooseus.style9 .services-item .services-text .services-title .title a {
  color: #04004D;
}
.rs-whychooseus.style9 .services-item .services-text .services-title .title a:hover {
  color: #f64f26;
}
.rs-whychooseus.style9 .services-item .services-text .services-title .title.purple-color a:hover {
  color: #6C58FA;
}
.rs-whychooseus.style9 .services-item .services-text .services-title .title.blue-color a:hover {
  color: #5e617d;
}
.rs-whychooseus.style9 .services-item .services-text .services-txt {
  margin-bottom: 0;
  padding-right: 74px;
}
.rs-whychooseus.style9 .apps-store li {
  display: inline-block;
  margin-right: 25px;
}
.rs-whychooseus.style9 .apps-store li:last-child {
  margin: 0;
}
.rs-whychooseus.style9 .rs-animations .spinner {
  position: absolute;
  bottom: 0;
  z-index: -111;
}
.rs-whychooseus.style9 .rs-animations .spinner.big {
  top: 4%;
  left: 9%;
}
.rs-whychooseus.style9 .rs-animations .spinner.dot {
  top: 35%;
  left: 35%;
}
.rs-whychooseus.style9 .rs-animations .spinner.ball {
  top: 54%;
  left: 39%;
}
.rs-whychooseus.style9 .rs-animations .spinner.circle {
  top: 52%;
  right: 15px;
}
.rs-whychooseus.style10 {
  position: relative;
}
.rs-whychooseus.style10 .rs-counter {
  position: absolute;
  bottom: 100px;
  left: 14%;
}
.rs-whychooseus.style10 .rs-counter .expert-consultion {
  padding: 35px 20px 35px 20px;
  background-color: #BB0B0B;
  display: inline-block;
}
.rs-whychooseus.style10 .rs-counter .expert-consultion .couter-part {
  text-align: center;
}
.rs-whychooseus.style10 .rs-counter .expert-consultion .couter-part .rs-count {
  font-size: 73px;
  line-height: 73px;
  font-weight: 600;
  color: #fff;
  position: relative;
  display: inline-block;
  padding-right: 40px;
  margin-bottom: 12px;
}
.rs-whychooseus.style10 .rs-counter .expert-consultion .couter-part .rs-count:before {
  content: '+';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.rs-whychooseus.style10 .rs-counter .expert-consultion .couter-part .title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0;
}
/*---------------------------------------
    12. Featured Section CSS
---------------------------------------*/
.rs-featured.style1 .featured-wrap {
  display: flex;
  padding-right: 15px;
}
.rs-featured.style1 .featured-wrap .icon-part img {
  width: 65px;
  max-width: 65px;
}
.rs-featured.style1 .featured-wrap .content-part {
  padding-left: 25px;
}
.rs-featured.style1 .featured-wrap .content-part .title {
  margin-bottom: 12px;
}
.rs-featured.style1 .featured-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-featured.style1 .featured-wrap .content-part .title a:hover {
  color: #ec4c6f;
}
/*---------------------------------------
    13. Counter Section CSS
---------------------------------------*/
.rs-counter.style1 .couter-part {
  text-align: center;
}
.rs-counter.style1 .couter-part .icon-part {
  margin-bottom: 20px;
}
.rs-counter.style1 .couter-part .icon-part img {
  max-width: 70px;
  margin: 0 auto;
}
.rs-counter.style1 .couter-part .rs-count {
  font-size: 44px;
  line-height: 60px;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  display: inline-block;
  padding-right: 28px;
  margin-bottom: 16px;
}
.rs-counter.style1 .couter-part .rs-count:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  opacity: 0.9;
}
.rs-counter.style1 .couter-part .title {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}
.rs-counter.style1 .couter-part.plus .rs-count:after {
  content: "+";
}
.rs-counter.style1 .couter-part.thousand .rs-count:after {
  content: "K";
  right: -3px;
}
.rs-counter.style1.modify .couter-part .rs-count {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  padding-right: 14px;
  margin-bottom: 20px;
}
.rs-counter.style1.modify .couter-part .rs-count:after {
  bottom: 7px;
  font-size: 19px;
  line-height: 26px;
  font-weight: 700;
  top: unset;
  transform: unset;
  opacity: 1;
}
.rs-counter.style1.modify .couter-part .title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
}
.rs-counter.style1.modify .couter-part.plus .rs-count:after {
  content: "+";
}
.rs-counter.style1.modify .couter-part.thousand .rs-count {
  padding-right: 10px;
}
.rs-counter.style1.modify .couter-part.thousand .rs-count:after {
  content: "K";
  font-size: 16px;
}
.rs-counter.style1.modify2 .couter-part .rs-count {
  font-size: 44px;
  line-height: 60px;
  font-weight: 600;
  color: #fff;
}
.rs-counter.style1.modify2 .couter-part .title {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.rs-counter.style2 .counter-wrap .content-part {
  align-items: center;
  display: flex;
}
.rs-counter.style2 .counter-wrap .content-part .counter-part {
  border-right: 2px solid #e0dddd;
  padding: 10px 0;
  margin-right: 30px;
  min-width: 154px;
}
.rs-counter.style2 .counter-wrap .content-part .counter-part .rs-count {
  color: #ec4c6f;
  line-height: 1;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 7px;
  display: inline-block;
  position: relative;
}
.rs-counter.style2 .counter-wrap .content-part .counter-part .rs-count.thousand {
  padding-right: 25px;
}
.rs-counter.style2 .counter-wrap .content-part .counter-part .rs-count.thousand:after {
  position: absolute;
  content: "k";
  right: 0;
  bottom: 4px;
  color: #ec4c6f;
  font-size: 36px;
}
.rs-counter.style2 .counter-wrap .content-part .counter-part .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #333333;
}
.rs-counter.style2 .counter-wrap .content-part .desc-text .desc {
  font-size: 18px;
  line-height: 31px;
  color: #363636;
}
.rs-counter.style3 .bg-overlay {
  margin-top: -175px;
  z-index: 111;
}
.rs-counter.style3 .bg-overlay .counter-part {
  text-align: center;
}
.rs-counter.style3 .bg-overlay .counter-part .rs-count {
  color: #FFFFFF;
  font-size: 80px;
  font-weight: 700;
  -webkit-text-fill-color: #793A3A00;
  -webkit-text-stroke-color: #FFFFFF;
  -webkit-text-stroke-width: 1px;
  line-height: 60px;
  display: inline-block;
  transition: all .3s ease 0s;
}
.rs-counter.style3 .bg-overlay .counter-part .title {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}
.rs-counter.style4 .rs-counter-list {
  text-align: center;
}
.rs-counter.style4 .rs-counter-list .icon-part img {
  width: 60px;
  margin: 0px 0px 8px 0px;
}
.rs-counter.style4 .rs-counter-list .counter-text .rs-count {
  color: #5A2ECA;
  font-size: 51px;
  font-weight: 700;
  line-height: 60px;
  padding: 8px 0;
}
.rs-counter.style4 .rs-counter-list .counter-text .title {
  color: #555555;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
/*---------------------------------------
    14. Latest Work Section CSS
---------------------------------------*/
.rs-latest-work .work-wrap {
  position: relative;
  overflow: hidden;
}
.rs-latest-work .work-wrap .content-part {
  position: absolute;
  padding: 0 27px 30px;
  width: 100%;
  bottom: -100px;
  transition: all 0.3s ease;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.rs-latest-work .work-wrap .content-part .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.rs-latest-work .work-wrap .content-part .title a {
  color: #ffffff;
}
.rs-latest-work .work-wrap .content-part .title a:hover {
  opacity: 0.65;
}
.rs-latest-work .work-wrap .content-part .category {
  color: #ffffff;
}
.rs-latest-work .work-wrap .content-part .category:hover {
  opacity: 0.65;
}
.rs-latest-work .work-wrap:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  background-image: linear-gradient(180deg, transparent 40%, #333333 100%);
}
.rs-latest-work .work-wrap:hover .content-part {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.rs-latest-work .work-wrap:hover:after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
/*---------------------------------------
    15. Collaboration Section CSS
---------------------------------------*/
.rs-collaboration.style1 .img-part img {
  position: relative;
  bottom: -100px;
}
/*---------------------------------------
    16. Faq Section CSS
---------------------------------------*/
.rs-faq.inner .accordion {
  border-width: 12px;
}
.rs-faq.inner .accordion .card {
  margin-bottom: 25px;
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.1);
}
.rs-faq.inner .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-faq.inner .accordion .card .card-header {
  padding: 0 !important;
  border: none;
  background-color: transparent;
}
.rs-faq.inner .accordion .card .card-header .card-link {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #1c1b1b;
  display: block;
  padding: 20px 25px;
  background: transparent;
  border: none;
}
.rs-faq.inner .accordion .card .card-body {
  background: transparent;
  font-weight: 400;
  color: #363636;
  padding: 15px 40px 41px 20px !important;
  border: none;
}
.rs-faq.inner .accordion .card .card-header .card-link {
  position: relative;
  padding-left: 65px !important;
}
.rs-faq.inner .accordion .card .card-header .card-link:after {
  content: 'I';
  font-family: "linea-basic-10" !important;
  position: absolute;
  left: 20px;
  color: #106eea;
  font-size: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-faq.inner .accordion .card .card-header .card-link.collapsed:after {
  content: 'H';
}
/*---------------------------------------
    17. Portfolio Section CSS
---------------------------------------*/
.rs-portfolio.style1 .dot-style1 .owl-dots {
  margin-top: 50px;
}
.rs-portfolio.style1 .portfolio-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.rs-portfolio.style1 .portfolio-item .img-part img {
  transition: 1.3s all ease;
  -webkit-transition: 1.3s all ease;
}
.rs-portfolio.style1 .portfolio-item .content-part {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100%;
  padding: 0 40px;
  z-index: 1;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}
.rs-portfolio.style1 .portfolio-item .content-part .categories {
  color: #dad9d9;
}
.rs-portfolio.style1 .portfolio-item .content-part .categories:hover {
  color: #106eea;
}
.rs-portfolio.style1 .portfolio-item .content-part .title {
  margin-bottom: 0;
  font-size: 22px;
}
.rs-portfolio.style1 .portfolio-item .content-part .title a {
  color: #ffffff;
}
.rs-portfolio.style1 .portfolio-item .content-part .title a:hover {
  color: #106eea;
}
.rs-portfolio.style1 .portfolio-item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.rs-portfolio.style1 .portfolio-item:hover .img-part img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.rs-portfolio.style1 .portfolio-item:hover .content-part {
  bottom: 15%;
}
.rs-portfolio.style1.modify1 {
  margin-top: -290px;
  position: relative;
}
.rs-portfolio.style1.modify1 .portfolio-item .content-part .categories:hover {
  color: #dad9d9;
}
.rs-portfolio.style1.modify1 .portfolio-item .content-part .title a:hover {
  color: #106eea;
}
.rs-portfolio.style1.modify1 .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-portfolio.style1.modify1 .owl-dots .owl-dot {
  background: #0e0b38;
}
.rs-portfolio.style1.modify1 .owl-dots .owl-dot:hover,
.rs-portfolio.style1.modify1 .owl-dots .owl-dot.active {
  background: #0e0b38;
}
.rs-portfolio.style1.modify1 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-portfolio.style2 {
  position: relative;
}
.rs-portfolio.style2 .row.custom-width {
  max-width: 1920px;
  margin: 0 auto;
}
.rs-portfolio.style2 .title-wrap {
  position: absolute;
  top: -27px;
  left: 22%;
  transform: rotate(-90deg);
}
.rs-portfolio.style2 .title-wrap .title-part {
  position: relative;
}
.rs-portfolio.style2 .title-wrap .title-part .title {
  bottom: 0;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.rs-portfolio.style2 .title-wrap .title-part .watermark {
  font-size: 200px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 146px;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
  opacity: .05;
}
.rs-portfolio.style2 .slider-part .owl-item {
  padding-bottom: 30px;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap {
  position: relative;
  padding-bottom: 60px;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .img-part {
  overflow: hidden;
  border-radius: 5px;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .img-part img {
  transition: all 0.7s;
  transform: scale(1);
  width: 100%;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: 0;
  right: 35px;
  left: 35px;
  padding: 18px 0;
  text-align: center;
  transition: all 0.5s;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .title {
  font-size: 22px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 0;
  position: relative;
  top: 12px;
  transition: all 0.7s;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .categories {
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .categories a {
  color: #106eea;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap .content-part .categories a:hover {
  color: #1c1b1b;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap:hover .img-part img {
  transform: scale(1.2);
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap:hover .content-part {
  bottom: 15px;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap:hover .content-part .title {
  top: 0;
}
.rs-portfolio.style2 .slider-part .owl-item .portfolio-wrap:hover .content-part .categories {
  opacity: 1;
  visibility: visible;
}
.rs-portfolio.style2:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 432px;
  background: #f1f6fc;
  z-index: -1;
}
.rs-portfolio.style2.solution {
  position: unset;
}
.rs-portfolio.style2.solution .owl-dots {
  bottom: -122px;
  position: relative;
}
.rs-portfolio.style2.solution .owl-stage-outer {
  bottom: -70px;
  padding-bottom: 10px;
  margin-bottom: -10px;
}
.rs-portfolio.style2.solution .slider-part .owl-item {
  padding-bottom: 0;
}
.rs-portfolio.style2.solution .slider-part .owl-item .portfolio-wrap .content-part .title {
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
}
.rs-portfolio.style2.solution .slider-part .owl-item .portfolio-wrap .content-part .categories a {
  color: #363636;
}
.rs-portfolio.style2.solution .slider-part .owl-item .portfolio-wrap .content-part .categories a:hover {
  color: #106eea;
}
.rs-portfolio.style3 .portfolio-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.rs-portfolio.style3 .portfolio-item .content-part {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 30%;
  left: 0;
  transition: all 0.5s;
  opacity: 0;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle {
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .title {
  font-style: 20px;
  margin-bottom: 8px;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .title a {
  color: #ffffff;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .title a:hover {
  color: #cccccc;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .categories {
  display: block;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .categories a {
  color: #ffffff;
}
.rs-portfolio.style3 .portfolio-item .content-part .middle .categories a:hover {
  color: #cccccc;
}
.rs-portfolio.style3 .portfolio-item .content-part:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.rs-portfolio.style3 .portfolio-item:hover .content-part {
  opacity: 1;
  top: 0;
}
.rs-portfolio.inner .portfolio-item {
  position: relative;
  overflow: hidden;
}
.rs-portfolio.inner .portfolio-item .img-part {
  position: relative;
}
.rs-portfolio.inner .portfolio-item .img-part a.search {
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 25px;
  top: -100px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 100%;
  background: #106eea;
  color: #ffffff;
  text-align: center;
  transition: all .4s ease 0s;
}
.rs-portfolio.inner .portfolio-item .content-part {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 25px 23px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease;
}
.rs-portfolio.inner .portfolio-item .content-part .categories {
  color: #d1cfcf;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
  display: inline-block;
}
.rs-portfolio.inner .portfolio-item .content-part .categories:hover {
  color: #ffffff;
}
.rs-portfolio.inner .portfolio-item .content-part .title {
  margin-bottom: 0;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}
.rs-portfolio.inner .portfolio-item .content-part .title a {
  color: #ffffff;
}
.rs-portfolio.inner .portfolio-item .content-part .title a:hover {
  color: #ece7e7;
}
.rs-portfolio.inner .portfolio-item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: all 0.3s ease;
}
.rs-portfolio.inner .portfolio-item:hover .img-part a.search {
  top: 20px;
}
.rs-portfolio.inner .portfolio-item:hover .content-part {
  opacity: 1;
}
.rs-portfolio.inner .portfolio-item:hover:after {
  opacity: 1;
}
.rs-portfolio.inner2 .portfolio-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-img img {
  width: 100%;
  transform: scale(1.2);
  transition: .6s;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content {
  position: absolute;
  padding: 24px 30px;
  opacity: 0;
  z-index: 1;
  top: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle {
  display: table;
  width: 100%;
  height: 100%;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner {
  position: relative;
  z-index: 11;
  transform: scale(1.3);
  transition: .5s;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner .title {
  margin-bottom: 5px;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner .title a {
  color: #ffffff;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner .title a:hover {
  color: rgba(255, 255, 255, 0.65);
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner .category {
  color: #ffffff;
}
.rs-portfolio.inner2 .portfolio-item .portfolio-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(18, 115, 235, 0.74);
  transform: scale(1.2);
  transition: .6s;
}
.rs-portfolio.inner2 .portfolio-item:hover .portfolio-img img {
  transform: scale(1);
}
.rs-portfolio.inner2 .portfolio-item:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
}
.rs-portfolio.inner2 .portfolio-item:hover .portfolio-content .vertical-middle .vertical-middle-cell .portfolio-inner {
  transform: scale(1);
}
.rs-portfolio.inner2 .portfolio-item:hover .portfolio-content:before {
  opacity: 1;
  visibility: visible;
}
.rs-portfolio.inner3 .portfolio-item {
  position: relative;
  overflow: hidden;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-img img {
  transform: scale(1);
  transition: 1.3s all ease;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content {
  position: absolute;
  padding: 17px 25px 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  left: 0;
  bottom: -100px;
  text-align: left;
  width: 100%;
  border-top: 0;
  z-index: 11;
  transition: .6s;
  height: 100%;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content .portfolio-inner {
  position: absolute;
  bottom: -100px;
  transition: .5s;
  left: 30px;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content .portfolio-inner .title {
  margin-bottom: 0;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content .portfolio-inner .title a {
  color: #ffffff;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content .portfolio-inner .title a:hover {
  color: rgba(255, 255, 255, 0.65);
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content .portfolio-inner .category {
  color: #ffffff;
}
.rs-portfolio.inner3 .portfolio-item .portfolio-content:before {
  background: rgba(18, 115, 235, 0.74);
  content: '';
  position: absolute;
  bottom: -150px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: all .4s;
  border-radius: 5px;
  transition: .6s;
}
.rs-portfolio.inner3 .portfolio-item:hover .portfolio-img img {
  transform: scale(1.2);
}
.rs-portfolio.inner3 .portfolio-item:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-portfolio.inner3 .portfolio-item:hover .portfolio-content .portfolio-inner {
  bottom: 30px;
}
.rs-portfolio.inner3 .portfolio-item:hover .portfolio-content:before {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-img {
  overflow: hidden;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-img img {
  transform: scale(1);
  transition: 7s all ease;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-content .portfolio-inner .title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 0;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-content .portfolio-inner .title a {
  color: #ffffff;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-content .portfolio-inner .title a:hover {
  color: rgba(255, 255, 255, 0.65);
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-content .portfolio-inner .category {
  color: #ffffff;
}
.rs-portfolio.inner3.onepage16 .portfolio-item .portfolio-content:before {
  background: rgba(0, 0, 0, 0.5);
  border-radius: unset;
}
.rs-portfolio.inner4 .portfolio-item {
  position: relative;
  overflow: hidden;
}
.rs-portfolio.inner4 .portfolio-item .content-part {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  bottom: -150px;
  opacity: 0;
  width: calc(100% - 60px);
  padding: 28px 20px 24px;
  z-index: 1;
  transition: all 0.5s;
}
.rs-portfolio.inner4 .portfolio-item .content-part .categories {
  color: #1c1b1b;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  display: inline-block;
}
.rs-portfolio.inner4 .portfolio-item .content-part .categories:hover {
  color: #106eea;
}
.rs-portfolio.inner4 .portfolio-item .content-part .title {
  margin-bottom: 0;
  font-size: 20px;
}
.rs-portfolio.inner4 .portfolio-item .content-part .title a {
  color: #1c1b1b;
}
.rs-portfolio.inner4 .portfolio-item .content-part .title a:hover {
  color: #106eea;
}
.rs-portfolio.inner4 .portfolio-item:hover .content-part {
  bottom: 30px;
  opacity: 1;
}
.rs-portfolio.single .project-sidebar .sb-project-detail {
  background: #fbfbfb;
}
.rs-portfolio.single .project-sidebar .sb-project-detail .title {
  padding: 14px 20px;
  background: #106eea;
  color: #ffffff;
  margin-bottom: 0;
}
.rs-portfolio.single .project-sidebar .sb-project-detail ul {
  padding: 25px 30px;
}
.rs-portfolio.single .project-sidebar .sb-project-detail ul li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(54, 54, 54, 0.05);
}
.rs-portfolio.single .project-sidebar .sb-project-detail ul li span {
  width: 150px;
  display: inline-block;
  color: #1c1b1b;
  font-weight: 600;
}
.rs-portfolio.single .project-sidebar .sb-project-detail ul li:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
.rs-portfolio.single .gallery-wrap .gallery-item {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.rs-portfolio.single .gallery-wrap .gallery-item a.image-popup {
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  text-align: center;
  position: absolute;
  content: '';
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.rs-portfolio.single .gallery-wrap .gallery-item a.image-popup:hover {
  background: #106eea;
  border-color: #106eea;
}
.rs-portfolio.single .gallery-wrap .gallery-item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.rs-portfolio.single .gallery-wrap .gallery-item:hover a.image-popup {
  top: 50%;
  opacity: 1;
  visibility: visible;
}
.rs-portfolio.single .gallery-wrap .gallery-item:hover:after {
  opacity: 1;
  visibility: visible;
}
/*---------------------------------------
    18. Casestudies Section CSS
---------------------------------------*/
.rs-casestudies.style1 .dot-style1 .owl-dots {
  margin-top: 32px;
}
.rs-casestudies.style1 .portfolio-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.rs-casestudies.style1 .portfolio-item .img-part img {
  transition: 1.3s all ease;
  -webkit-transition: 1.3s all ease;
}
.rs-casestudies.style1 .portfolio-item .content-part {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100%;
  padding: 0 40px;
  z-index: 1;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}
.rs-casestudies.style1 .portfolio-item .content-part .categories {
  color: #dad9d9;
}
.rs-casestudies.style1 .portfolio-item .content-part .categories:hover {
  color: #106eea;
}
.rs-casestudies.style1 .portfolio-item .content-part .title {
  margin-bottom: 0;
  font-size: 22px;
}
.rs-casestudies.style1 .portfolio-item .content-part .title a {
  color: #ffffff;
}
.rs-casestudies.style1 .portfolio-item .content-part .title a:hover {
  color: #106eea;
}
.rs-casestudies.style1 .portfolio-item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.rs-casestudies.style1 .portfolio-item:hover .img-part img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.rs-casestudies.style1 .portfolio-item:hover .content-part {
  bottom: 15%;
}
.rs-casestudies.style2 .portfolio-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 29px;
}
.rs-casestudies.style2 .portfolio-item .img-part img {
  transition: 1.3s all ease;
  -webkit-transition: 1.3s all ease;
}
.rs-casestudies.style2 .portfolio-item .content-part {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100%;
  padding: 0 40px;
  z-index: 1;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}
.rs-casestudies.style2 .portfolio-item .content-part .categories {
  color: #ffffff;
}
.rs-casestudies.style2 .portfolio-item .content-part .categories:hover {
  color: #e6e6e6;
}
.rs-casestudies.style2 .portfolio-item .content-part .title {
  margin-bottom: 0;
  font-size: 22px;
}
.rs-casestudies.style2 .portfolio-item .content-part .title a {
  color: #ffffff;
}
.rs-casestudies.style2 .portfolio-item .content-part .title a:hover {
  color: #106eea;
}
.rs-casestudies.style2 .portfolio-item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.rs-casestudies.style2 .portfolio-item:hover .img-part img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.rs-casestudies.style2 .portfolio-item:hover .content-part {
  bottom: 15%;
}
/*---------------------------------------
    19. Free Quote Section CSS
---------------------------------------*/
.rs-quote.style1 .custom {
  flex: 0 0 35.646%;
  max-width: 35.646%;
}
.rs-quote.style1 .quote-form {
  background: #67c27c;
  padding: 50px 50px 80px;
  border-radius: 30px;
  box-shadow: 0 0 72px -10px rgba(0, 0, 0, 0.22);
}
.rs-quote.style1 .quote-form .form-ctrl label {
  color: #ffffff;
  margin-bottom: 9px;
  font-weight: 500;
}
.rs-quote.style1 .quote-form .form-ctrl .select-option {
  position: relative;
  display: block;
}
.rs-quote.style1 .quote-form .form-ctrl .select-option select {
  appearance: none;
  cursor: pointer;
}
.rs-quote.style1 .quote-form .form-ctrl .select-option:before {
  content: "";
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  pointer-events: none;
}
.rs-quote.style1 .quote-form .form-ctrl input,
.rs-quote.style1 .quote-form .form-ctrl select {
  width: 100%;
  background: #7BDE92;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 11px 20px;
}
.rs-quote.style1 .quote-form .submit-btn button {
  width: 100%;
  padding: 18px 30px 18px 30px;
  background: #ffffff;
  color: #67c27c;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  outline: none;
  transition: all 0.3s ease;
}
.rs-quote.style1 .quote-form .submit-btn button:hover {
  background: #7BDE92;
  color: #ffffff;
}
.rs-quote.style1 .quote-form ::placeholder {
  color: #ffffff;
}
.rs-quote.style2 .quote-form {
  border-radius: 5px;
  padding: 65px 60px 71px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  background: #ffffff;
  z-index: 1;
}
.rs-quote.style2 .quote-form input,
.rs-quote.style2 .quote-form textarea {
  width: 100%;
  background: #ffffff;
  border: none;
  border-bottom: 2px solid #F7F5F5;
  outline: none;
  color: #676767;
  padding: 15px 10px 15px 0px;
}
.rs-quote.style2 .quote-form textarea {
  height: 120px;
  display: inherit;
}
.rs-quote.style2 .quote-form .submit-btn button {
  padding: 19px 42px;
  border-radius: 3px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background: #d83030;
  color: #ffffff;
  cursor: pointer;
  text-transform: capitalize;
  border: none;
  outline: none;
  transition: all 0.3s ease;
}
.rs-quote.style2 .quote-form .submit-btn button:hover {
  background: #dc4545;
}
.rs-quote.style2 .quote-form ::placeholder {
  color: #676767;
}
.rs-quote.style2 .left-img {
  position: absolute;
  left: -115px;
  top: 50%;
  transform: translateY(-50%);
}
.rs-quote.style2 .left-img img {
  position: relative;
  z-index: 1;
}
.rs-quote.style2 .left-img .morphin {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -225px;
}
.rs-quote.style3 .content-wrap {
  text-align: center;
}
.rs-quote.style3 .content-wrap .icon-part {
  margin-bottom: 25px;
}
.rs-quote.style3 .content-wrap .icon-part img {
  width: 100px;
}
.rs-quote.style3 .content-wrap .sub-text {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 25px;
}
.rs-quote.style3 .content-wrap .title {
  font-family: "Nunito", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.rs-quote.style3 .content-wrap .title a {
  color: #ffffff;
}
.rs-quote.style3 .content-wrap .title a:hover {
  color: #ccc;
}
.rs-quote.style3 .content-wrap .title-big {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 0;
}
.rs-quote.style3 .contact-wrap {
  padding: 50px 50px 50px 50px;
  background-color: #090E40DE;
  border-radius: 0px 0px 0px 0px;
  max-width: 620px;
  margin: 0 auto;
}
.rs-quote.style3 .contact-wrap .from-control {
  padding: 24px 20px;
  color: #fff;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  background-color: #070925;
  width: 100%;
  opacity: 1;
}
.rs-quote.style3 .contact-wrap textarea {
  height: 120px;
}
.rs-quote.style3 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #fff;
  opacity: 1;
}
.rs-quote.style3 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #fff;
  opacity: 1;
}
.rs-quote.style3 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #fff;
  opacity: 1;
}
.rs-quote.style3 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #fff;
  opacity: 1;
}
/*---------------------------------------
    20. Skillbar Section CSS
---------------------------------------*/
.rs-skillbar.style1 .content-part {
  padding: 52px 60px 60px 75px;
}
.rs-skillbar.style1 .bg-part {
  background: url(./images/bg/skill.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  height: 100%;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar-title {
  font-weight: 600;
  color: #212121;
  font-size: 14px;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar {
  background: #eeeeee;
  height: 10px;
  margin: 17px 0 24px;
  position: relative;
  width: 100%;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #106eea;
  height: 10px;
  width: 0;
  margin-bottom: 0;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -45px;
  font-size: 14px;
  font-weight: 600;
  color: #645959;
}
.rs-skillbar.style2 .rs-pie-content {
  margin-bottom: 10px;
}
.rs-skillbar.style2 .rs-pie-content .rs-pie {
  display: inline-block;
  position: relative;
  text-align: center;
}
.rs-skillbar.style2 .rs-pie-content .rs-pie .rspie-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.rs-skillbar.style2 .pie-title {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.rs-skillbar.style3 .content-part {
  background: #f1f6fc;
  padding: 25px 15px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}
.rs-skillbar.style3 .content-part .rs-pie-content {
  margin-bottom: 10px;
}
.rs-skillbar.style3 .content-part .rs-pie-content .rs-pie2 {
  display: inline-block;
  position: relative;
  text-align: center;
}
.rs-skillbar.style3 .content-part .rs-pie-content .rs-pie2 .rspie-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  font-weight: 500;
  font-size: 20px;
  color: #101010;
}
.rs-skillbar.style3 .content-part .pie-title {
  margin-bottom: 0;
  color: #101010;
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
}
/*---------------------------------------
    21. Testimonial Section CSS
---------------------------------------*/
.rs-testimonial.style1 .bg-part {
  background: url(./images/testimonial/left-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.rs-testimonial.style1 .slider-part {
  padding: 67px 85px 62px 70px;
}
.rs-testimonial.style1 .testi-item {
  margin-bottom: 30px;
}
.rs-testimonial.style1 .testi-item .content-part .icon-part {
  margin-bottom: 18px;
}
.rs-testimonial.style1 .testi-item .content-part .icon-part i {
  color: rgba(18, 115, 235, 0.12);
  font-size: 50px;
}
.rs-testimonial.style1 .testi-item .content-part .desc {
  color: #363636;
  font-size: 18px;
  font-style: italic;
  line-height: 1.9em;
}
.rs-testimonial.style1 .testi-item .posted-by {
  margin-top: 34px;
}
.rs-testimonial.style1 .testi-item .posted-by .avatar img {
  width: 65px;
  height: auto;
  border-radius: 100%;
  margin: 0 auto;
}
.rs-testimonial.style1 .testi-item .posted-by .name {
  font-size: 20px;
  font-weight: 500;
  margin: 19px 0 4px;
  font-family: 'Roboto', sans-serif;
}
.rs-testimonial.style1 .testi-item .posted-by .designation {
  color: #878787;
}
.rs-testimonial.style2 .dot-style1 .owl-dots {
  margin-top: 39px;
}
.rs-testimonial.style2 .testi-item .content-part {
  background: rgba(49, 69, 98, 0.47);
  padding: 30px 35px 35px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  margin-bottom: 28px;
}
.rs-testimonial.style2 .testi-item .content-part .icon-part {
  margin-bottom: 14px;
}
.rs-testimonial.style2 .testi-item .content-part .icon-part i {
  font-size: 35px;
  color: #106eea;
}
.rs-testimonial.style2 .testi-item .content-part .desc {
  color: #ffffff;
}
.rs-testimonial.style2 .testi-item .content-part:after {
  top: 100%;
  left: 13%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: rgba(49, 69, 98, 0.47);
  border-width: 12px;
  margin-left: -30px;
  z-index: 1;
}
.rs-testimonial.style2 .testi-item .posted-by {
  padding-left: 15px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.rs-testimonial.style2 .testi-item .posted-by .avatar img {
  max-width: 80px;
  border-radius: 100%;
}
.rs-testimonial.style2 .testi-item .posted-by .user {
  padding-left: 15px;
}
.rs-testimonial.style2 .testi-item .posted-by .user .name {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0;
}
.rs-testimonial.style2 .testi-item .posted-by .user .designation {
  font-size: 14px;
  font-weight: 500;
  color: #f2eeee;
}
.rs-testimonial.style3 .content-wrap .desc {
  font-size: 25px;
  line-height: 43px;
  font-style: italic;
  margin-bottom: 0;
}
.rs-testimonial.style3 .content-wrap .testi-meta {
  display: inline-block;
}
.rs-testimonial.style3 .content-wrap .testi-meta .img-part,
.rs-testimonial.style3 .content-wrap .testi-meta .detail-part {
  display: table-cell;
  vertical-align: middle;
}
.rs-testimonial.style3 .content-wrap .testi-meta .img-part {
  padding-right: 15px;
}
.rs-testimonial.style3 .content-wrap .testi-meta .img-part img {
  border-radius: 100%;
  width: 80px;
}
.rs-testimonial.style3 .content-wrap .testi-meta .detail-part .name {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 20px;
  color: #363636;
}
.rs-testimonial.style3 .content-wrap .testi-meta .detail-part .designation {
  font-weight: 500;
}
.rs-testimonial.style3.modify {
  position: relative;
}
.rs-testimonial.style3.modify .owl-item {
  margin-bottom: 72px;
}
.rs-testimonial.style3.modify .owl-dots {
  text-align: left;
}
.rs-testimonial.style3.modify:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 48%;
  height: 100%;
  background: url(./images/testimonial/left-img3.jpg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-testimonial.style4 .content-wrap .desc {
  font-size: 24px;
  line-height: 41px;
  font-style: italic;
  margin-bottom: 0;
  color: #444;
  padding-right: 75px;
}
.rs-testimonial.style4 .content-wrap .testi-meta .detail-part .name {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
  color: #67c27c;
}
.rs-testimonial.style4 .content-wrap .testi-meta .detail-part .designation {
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
  color: #444;
}
.rs-testimonial.style4.modify {
  position: relative;
}
.rs-testimonial.style4.modify .owl-item {
  margin-bottom: 72px;
}
.rs-testimonial.style4.modify .owl-dots {
  text-align: left;
}
.rs-testimonial.style4.modify:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 48%;
  height: 100%;
  background: url(./images/testimonial/left-img3.jpg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-testimonial.style4.modify2 .content-wrap .desc {
  padding-right: 55px;
}
.rs-testimonial.style4.modify2 .content-wrap .testi-meta .detail-part .name {
  color: #1c3988;
}
.rs-testimonial.style5 {
  background-image: linear-gradient(140deg, #1473eb 11%, #bf7eed 60%);
  background-color: transparent;
}
.rs-testimonial.style5 .content-wrap {
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
}
.rs-testimonial.style5 .content-wrap .icon-part {
  margin-bottom: 55px;
}
.rs-testimonial.style5 .content-wrap .icon-part img {
  width: 100px;
  margin: 0 auto;
  opacity: 0.5;
}
.rs-testimonial.style5 .content-wrap .desc {
  color: #ffffff;
  line-height: 1.7;
  font-size: 28px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 35px;
}
.rs-testimonial.style5 .content-wrap .posted-by .name {
  font-size: 25px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}
.rs-testimonial.style5 .content-wrap .posted-by .designation {
  color: #ffffff;
}
.rs-testimonial.style6 .testi-wrap {
  background: #f1f6fc;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 23px 35px 43px;
  text-align: center;
}
.rs-testimonial.style6 .testi-wrap .icon-part {
  max-width: 66px;
  margin: 0 auto 17px;
}
.rs-testimonial.style6 .testi-wrap .desc {
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 21px;
}
.rs-testimonial.style6 .testi-wrap .detail-part {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-testimonial.style6 .testi-wrap .detail-part .avatar img {
  width: 80px;
  border-radius: 100%;
}
.rs-testimonial.style6 .testi-wrap .detail-part .info {
  text-align: left;
  padding-left: 15px;
}
.rs-testimonial.style6 .testi-wrap .detail-part .info .name {
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
}
.rs-testimonial.style6 .testi-wrap .detail-part .info .designation {
  font-size: .85em;
  line-height: 1;
}
.rs-testimonial.style7 .testi-wrap {
  text-align: center;
}
.rs-testimonial.style7 .testi-wrap .top-part {
  padding: 70px 85px 80px;
  background: #F8F8F8;
  border-radius: 4px;
}
.rs-testimonial.style7 .testi-wrap .top-part .icon-part {
  margin-bottom: 25px;
}
.rs-testimonial.style7 .testi-wrap .top-part .icon-part img {
  max-width: 40px;
  margin: 0 auto;
}
.rs-testimonial.style7 .testi-wrap .top-part .desc {
  color: #333333;
  font-size: 22px;
  line-height: 37px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 0;
}
.rs-testimonial.style7 .testi-wrap .posted-by {
  margin-top: -40px;
}
.rs-testimonial.style7 .testi-wrap .posted-by .avatar {
  text-align: center;
  margin-bottom: 18px;
}
.rs-testimonial.style7 .testi-wrap .posted-by .avatar img {
  width: 80px;
  border-radius: 100%;
  margin: 0 auto;
}
.rs-testimonial.style7 .testi-wrap .posted-by .name {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #101010;
  margin-bottom: 2px;
}
.rs-testimonial.style8 .content-wrap {
  text-align: center;
  max-width: 1035px;
  margin-right: auto;
  margin-left: auto;
}
.rs-testimonial.style8 .content-wrap .icon-part {
  margin-bottom: 10px;
}
.rs-testimonial.style8 .content-wrap .icon-part img {
  width: 60px;
  margin: 0 auto;
}
.rs-testimonial.style8 .content-wrap .desc {
  color: #ffffff;
  line-height: 61px;
  font-size: 36px;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 27px;
}
.rs-testimonial.style8 .content-wrap .posted-by .avatar {
  margin-bottom: 15px;
}
.rs-testimonial.style8 .content-wrap .posted-by .avatar img {
  max-width: 80px;
  border-radius: 100%;
  margin: 0 auto;
}
.rs-testimonial.style8 .content-wrap .posted-by .name {
  font-size: 18px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}
.rs-testimonial.style8 .content-wrap .posted-by .designation {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.rs-testimonial.style9 .testi-wrap {
  position: relative;
  padding-top: 48px;
}
.rs-testimonial.style9 .testi-wrap .icon-part {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.rs-testimonial.style9 .testi-wrap .wraping {
  text-align: center;
  padding: 55px 50px 50px;
  background: #ffffff;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.03);
  border-bottom: 5px solid #FBEAEA;
}
.rs-testimonial.style9 .testi-wrap .wraping .desc {
  color: #333333;
  font-size: 21px;
  line-height: 36px;
  color: #032e42;
  font-family: 'Poppins', sans-serif;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by {
  display: flex;
  text-align: left;
  justify-content: center;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .avatar {
  margin-top: 6px;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .avatar img {
  width: 65px;
  border-radius: 5px;
  margin: 0 auto;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .info {
  padding-left: 24px;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .info .name {
  font-size: 22px;
  font-weight: 600;
  color: #032e42;
  margin-bottom: 2px;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .info .ratings {
  margin-top: 5px;
}
.rs-testimonial.style9 .testi-wrap .wraping .posted-by .info .ratings li {
  display: inline-block;
  color: #ffc62d;
  font-size: 17px;
}
.rs-testimonial.style10 .slick-part .slider {
  max-width: 842px;
  margin: 0 auto;
}
.rs-testimonial.style10 .slick-part .slider .images-slide-single {
  text-align: center;
}
.rs-testimonial.style10 .slick-part .slider .images-slide-single .single-testimonial .content-part .quote {
  margin: 0 auto 22px;
}
.rs-testimonial.style10 .slick-part .slider .images-slide-single .single-testimonial .content-part p {
  font-size: 24px;
  font-style: italic;
  line-height: 1.9em;
  font-weight: 400;
  color: #3e3c3c;
  margin-bottom: 50px;
}
.rs-testimonial.style10 .slick-part .slider .images-single {
  text-align: center;
}
.rs-testimonial.style10 .slick-part .slider .images-single img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  opacity: .5;
  cursor: pointer;
}
.rs-testimonial.style10 .slick-part .slider .images-single .testi-content {
  display: none;
  padding-top: 24px;
}
.rs-testimonial.style10 .slick-part .slider .images-single .testi-content .testi-name {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9em;
}
.rs-testimonial.style10 .slick-part .slider .images-single .testi-content .testi-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #3e3c3c;
  display: block;
}
.rs-testimonial.style10 .slick-part .slider .images-single.slick-center img {
  width: 100px;
  height: 100px;
}
.rs-testimonial.style10 .slick-part .slider .images-single.slick-center .testi-content {
  display: block;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots {
  position: absolute;
  bottom: -235px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 5px;
  height: 5px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  outline: none;
  opacity: .25;
  background: #000;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots button:hover {
  opacity: 1;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots button.slick-prev {
  display: none;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots button.slick-prev.previous {
  display: none;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.rs-testimonial.style10 .slick-part .slider .slick-dots li.slick-active button {
  opacity: 1;
}
.rs-testimonial.style10 .slick-part .slick-arrow {
  font-size: 0;
  border: none;
  background: unset;
}
.rs-testimonial.style10.modify1 .slick-part .slider .images-slide-single .single-testimonial .content-part p {
  color: #fff;
  font-size: 28px;
  font-style: italic;
  line-height: 1.9em;
  margin-bottom: 50px;
}
.rs-testimonial.style10.modify1 .slick-part .slider .images-single .testi-content .testi-name {
  font-size: 18px;
}
.rs-testimonial.style10.modify1 .slick-part .slider .images-single .testi-content .testi-title {
  color: #ffffff;
}
.rs-testimonial.style11 .testi-main-part {
  position: relative;
  margin-top: -487px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider {
  max-width: 850px;
  margin: 0 auto;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-slide-single {
  text-align: center;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-slide-single .single-testimonial .content-part .quote {
  margin: 0 auto 22px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-slide-single .single-testimonial .content-part p {
  font-size: 20px;
  font-style: italic;
  line-height: 1.9em;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 50px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single {
  text-align: center;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  opacity: .5;
  cursor: pointer;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single .testi-content {
  display: none;
  padding-top: 24px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single .testi-content .testi-name {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9em;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single .testi-content .testi-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #ffffff;
  display: block;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single.slick-center img {
  width: 100px;
  height: 100px;
  opacity: 1;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .images-single.slick-center .testi-content {
  display: block;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .slick-dots {
  position: absolute;
  bottom: -235px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .slick-dots.prev:before {
  content: "\f115";
  font-family: Flaticon;
  background: #f00;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .slick-dots button {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #f00;
  text-align: center;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .slick-dots button.prev:before {
  content: "\f115";
  font-family: Flaticon;
  background: #f00;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider .slick-dots li {
  display: none;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider.slider-nav {
  max-width: 600px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slider.slider-nav .slick-list {
  padding: 0 30px !important;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slick-arrow {
  position: absolute;
  top: 82%;
  transform: translateY(-50%);
  font-size: 0;
  box-shadow: 0 0 10px #eee;
  border: none;
  background: #fff;
  color: #1c1b1b;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slick-arrow:before {
  content: "\f115";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slick-arrow.slick-prev {
  left: -180px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slick-arrow.slick-next {
  right: -180px;
}
.rs-testimonial.style11 .testi-main-part .slick-part .slick-arrow.slick-next:before {
  content: "\f114";
}
.rs-testimonial.style11 .testi-main-part .slick-arrow {
  opacity: 0;
  visibility: 0;
  transition: 0.4s;
}
.rs-testimonial.style11 .testi-main-part:hover .slick-arrow {
  opacity: 1;
  visibility: 1;
}
.rs-testimonial.style12 .content-wrap {
  position: relative;
  text-align: center;
}
.rs-testimonial.style12 .content-wrap .desc {
  color: #fff;
  font-size: 24px;
  max-width: 875px;
  margin: 0 auto 48px;
}
.rs-testimonial.style12 .content-wrap .images-wrap img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 20px;
}
.rs-testimonial.style12 .content-wrap .testi-meta .detail-part .name {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.9em;
  margin-bottom: 0px;
}
.rs-testimonial.style12 .content-wrap .testi-meta .detail-part .designation {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-list {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single {
  padding: 20px 20px 30px 20px;
  background-color: #fff;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 50px;
  box-shadow: 0px 11px 45px 0px #eee;
  text-align: center;
  position: relative;
  opacity: 0.3;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single .content-part .icon-part img {
  margin: 0 auto;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single .content-part .quote {
  margin: 0 auto 22px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single .content-part .desc {
  font-size: 20px;
  font-style: italic;
  line-height: 1.9em;
  font-weight: 400;
  color: #555555;
  margin-bottom: 35px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single .content-part .ratings-part img {
  margin: 0 auto;
  max-width: 120px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single.slick-current {
  position: relative;
  opacity: 1;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single.slick-current .content-part {
  position: relative;
  z-index: 9;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-for2 .images-slide-single.slick-current:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scalex(1.3) scaleY(1.3);
  width: 100%;
  height: 100%;
  box-shadow: 0px 11px 45px 0px #eee;
  background: #fff;
  z-index: 1;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 {
  max-width: 580px;
  margin: 0 auto;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .slick-list {
  padding: 0 20px !important;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single {
  text-align: center;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  opacity: .5;
  cursor: pointer;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single .testi-content {
  display: none;
  padding-top: 24px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single .testi-content .testi-name {
  color: #04004D;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9em;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single .testi-content .testi-title {
  color: #555555;
  font-size: 16px;
  font-weight: 400;
  display: block;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single.slick-center img {
  width: 100px;
  height: 100px;
  opacity: 1;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slider.slider-nav2 .images-single.slick-center .testi-content {
  display: block;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-dots {
  position: absolute;
  bottom: -235px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-dots.prev:before {
  content: "\f115";
  font-family: Flaticon;
  background: #f00;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-dots button {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #f00;
  text-align: center;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-dots button.prev:before {
  content: "\f115";
  font-family: Flaticon;
  background: #f00;
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-dots li {
  display: none;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-arrow {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  font-size: 0;
  box-shadow: 0 0 10px #eee;
  border: none;
  background: #fff;
  color: #1c1b1b;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 1;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-arrow:before {
  content: "\f115";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-arrow.slick-prev {
  left: -80px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-arrow.slick-next {
  right: -80px;
}
.rs-testimonial.style13 .testi-main-part .slick-part .slick-arrow.slick-next:before {
  content: "\f114";
}
.rs-testimonial.style13 .testi-main-part .slick-arrow {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-testimonial.style13 .testi-main-part:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}
.rs-testimonial.style13 .testi-main-part:hover .slick-arrow.slick-prev {
  left: 250px;
  right: unset;
}
.rs-testimonial.style13 .testi-main-part:hover .slick-arrow.slick-next {
  right: 250px;
}
.rs-testimonial.style14 .testi-item .item-content {
  position: relative;
  padding: 90px 40px 25px 40px;
  background-color: #333333;
}
.rs-testimonial.style14 .testi-item .item-content span img {
  width: 40px;
  height: 40px;
  top: 33px;
  left: 12%;
  position: absolute;
  z-index: 9;
}
.rs-testimonial.style14 .testi-item .item-content p {
  color: #ffffff;
  margin-bottom: 0;
}
.rs-testimonial.style14 .testi-item .item-content:after {
  top: 100%;
  left: 15%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #333333;
  border-width: 12px;
  margin-left: -30px;
  z-index: 1;
}
.rs-testimonial.style14 .testi-item .testi-content {
  padding-top: 30px;
  display: flex;
  align-items: center;
}
.rs-testimonial.style14 .testi-item .testi-content .image-wrap img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}
.rs-testimonial.style14 .testi-item .testi-content .testi-information {
  padding-left: 20px;
}
.rs-testimonial.style14 .testi-item .testi-content .testi-information .testi-name {
  font-size: 17px;
  line-height: 27px;
  font-weight: 500;
  color: #ffffff;
}
.rs-testimonial.style14 .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  color: #D7D3D3;
}
.rs-testimonial.style14 .owl-dots {
  margin-top: 50px;
}
.rs-testimonial.style14 .owl-dots .owl-dot {
  background: #bf0505;
  opacity: .5;
}
.rs-testimonial.style14 .owl-dots .owl-dot:hover,
.rs-testimonial.style14 .owl-dots .owl-dot.active {
  width: 40px;
  background: #bf0505;
  opacity: 1;
}
.rs-testimonial.style14 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-testimonial.style15 .testi-bg .testi-item {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
.rs-testimonial.style15 .testi-bg .testi-item .item-content p {
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
}
.rs-testimonial.style15 .testi-bg .testi-item .testi-content .image-wrap {
  margin-bottom: 15px;
}
.rs-testimonial.style15 .testi-bg .testi-item .testi-content .image-wrap img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
}
.rs-testimonial.style15 .testi-bg .testi-item .testi-content .testi-information .testi-name {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.9em;
}
.rs-testimonial.style15 .testi-bg .testi-item .testi-content .testi-information .testi-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
/*---------------------------------------
    22. Partner Section CSS
---------------------------------------*/
.rs-partner .partner-item a img {
  max-width: 158px;
  margin: 0 auto;
  opacity: .5;
  transition: all 0.3s ease;
}
.rs-partner .partner-item a:hover img {
  opacity: 1;
}
.rs-partner.modify .partner-wrap {
  background: #22293a;
  border-radius: 10px;
  padding: 60px;
  position: relative;
  bottom: -77px;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.5);
}
.rs-partner.modify .partner-item a img {
  max-width: 158px;
  margin: 0 auto;
  transition: all 0.3s ease;
  opacity: 1;
}
.rs-partner.modify .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rs-partner.modify2 .partner-item a img {
  opacity: 1;
  max-width: 181px;
}
.rs-partner.modify2 .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rs-partner.modify3 .partner-item a img {
  opacity: 1;
  max-width: 175px;
}
.rs-partner.modify3 .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rs-partner.modify4 .rs-carousel {
  max-width: 1000px;
  margin: 0 auto;
}
.rs-partner.modify4 .partner-item a {
  display: inline-block;
  padding: 0 61px;
}
.rs-partner.modify4 .partner-item a img {
  opacity: 1;
  max-width: 100%;
}
.rs-partner.modify4 .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rs-partner.modify5 .partner-item a {
  display: inline-block;
  padding: 0 61px;
}
.rs-partner.modify5 .partner-item a img {
  opacity: 1;
  max-width: 100%;
}
.rs-partner.modify5 .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rs-partner.modify6 .partner-item a img {
  opacity: 1;
  max-width: 100%;
}
.rs-partner.modify6 .partner-item a:hover img {
  animation-name: pulse-anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
/*---------------------------------------
    23. Team Section CSS
---------------------------------------*/
.rs-team.slider1 .team-wrap {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}
.rs-team.slider1 .team-wrap .team-image {
  margin-bottom: 105px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.rs-team.slider1 .team-wrap .team-image img {
  transition: all 0.3s ease;
}
.rs-team.slider1 .team-wrap .text-bottom {
  text-align: center;
  border-bottom: 3px solid #eaedf0;
  border-radius: 0 0 3px 3px;
  background: #f1f6fc;
  padding: 26px 0 23px 0;
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.3s ease;
}
.rs-team.slider1 .team-wrap .text-bottom h4.person-name {
  font-size: 20px;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.rs-team.slider1 .team-wrap .text-bottom h4.person-name a {
  color: #1c1b1b;
}
.rs-team.slider1 .team-wrap .text-bottom h4.person-name a:hover {
  color: #1c1b1b;
}
.rs-team.slider1 .team-wrap .text-bottom span.designation {
  display: block;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.rs-team.slider1 .team-wrap .social-links {
  margin-top: 5px;
  padding-bottom: 15px;
  transition: all 0.3s ease;
  margin-bottom: -50px;
  opacity: 0;
}
.rs-team.slider1 .team-wrap .social-links ul li {
  display: inline;
  padding: 0 7px;
}
.rs-team.slider1 .team-wrap .social-links ul li a i {
  color: #ffffff;
  transition: all 0.3s ease;
}
.rs-team.slider1 .team-wrap .social-links ul li a:hover i {
  color: #ffffff;
}
.rs-team.slider1 .team-wrap:hover .team-image img {
  transform: scale(1.1);
}
.rs-team.slider1 .team-wrap:hover .text-bottom {
  background: #106eea;
  padding-bottom: 8px !important;
}
.rs-team.slider1 .team-wrap:hover .text-bottom .person-name a {
  color: #ffffff;
}
.rs-team.slider1 .team-wrap:hover .text-bottom .person-name a:hover {
  color: #ffffff;
}
.rs-team.slider1 .team-wrap:hover .text-bottom .designation {
  color: #ffffff;
}
.rs-team.slider1 .team-wrap:hover .text-bottom .social-links {
  margin-bottom: 0;
  opacity: 1;
}
.rs-team.slider1.modify .team-wrap {
  margin-bottom: 0;
}
.rs-team.slider1.modify .team-wrap .text-bottom {
  text-align: center;
  border-bottom: none;
  border-radius: 0;
  background: transparent;
  padding: 26px 0 23px 0;
  border-radius: 0 0 5px 5px;
}
.rs-team.slider1.modify .team-wrap .text-bottom h4.person-name a {
  color: #1c1b1b;
}
.rs-team.slider1.modify .team-wrap .text-bottom h4.person-name a:hover {
  color: #1c1b1b;
}
.rs-team.slider1.modify .team-wrap .text-bottom span.designation {
  display: block;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.rs-team.slider1.modify .team-wrap:hover .text-bottom {
  background: #106eea;
}
.rs-team.slider1.modify .team-wrap:hover .text-bottom h4.person-name a {
  color: #ffffff;
}
.rs-team.slider1.modify2 .top-part {
  padding-bottom: 412px;
}
.rs-team.slider1.modify2 .bottom-part {
  margin-top: -360px;
}
.rs-team.slider1.modify2 .team-wrap {
  margin-bottom: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: hidden;
}
.rs-team.slider1.modify2 .team-wrap .text-bottom {
  border: none;
  padding: 27px 0 23px 0;
  background: #ffffff;
}
.rs-team.slider1.modify2 .team-wrap:hover .text-bottom {
  background: #222222;
}
.rs-team.slider1.red-mode .team-wrap {
  margin-bottom: 50px;
}
.rs-team.slider1.red-mode .team-wrap:hover .text-bottom {
  background: #ec4c6f;
}
.rs-team.slider1.red-mode2 .team-wrap {
  margin-bottom: 50px;
}
.rs-team.slider1.red-mode2 .team-wrap .text-bottom {
  background: #fbfbfb;
}
.rs-team.slider1.red-mode2 .team-wrap:hover .text-bottom {
  background: #d83030;
}
.rs-team.slider1.modify4 .team-wrap {
  margin-bottom: unset;
}
.rs-team.slider1.modify4 .team-wrap .team-image img {
  transition: all .7s;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom {
  border-bottom: unset;
  padding: 26px 0 26px 0;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom h4.person-name {
  font-weight: 700;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom h4.person-name a {
  color: #1c1b1b;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom h4.person-name a:hover {
  color: #1c1b1b;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom span.designation {
  font-size: 15px;
}
.rs-team.slider1.modify4 .team-wrap .text-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #BF0505;
  -webkit-transition: .4s all ease;
  -moz-transition: .4s all ease;
  transition: .4s all ease;
  left: 0;
}
.rs-team.slider1.modify4 .team-wrap:hover .text-bottom {
  background: #222;
}
.rs-team.slider1.modify4 .team-wrap:hover .text-bottom .person-name a {
  color: #ffffff;
}
.rs-team.slider1.modify4 .team-wrap:hover .text-bottom .person-name a:hover {
  color: #ffffff;
}
.rs-team.slider2 {
  position: relative;
}
.rs-team.slider2 .team-wrap {
  padding-bottom: 30px;
}
.rs-team.slider2 .team-wrap .team-img {
  position: relative;
  overflow: hidden;
}
.rs-team.slider2 .team-wrap .team-img a img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-team.slider2 .team-wrap .team-img .team-social {
  position: absolute;
  bottom: 0;
  right: -50px;
  opacity: 0;
  padding: 4px 0;
  background: #1c3988;
  width: 50px;
  z-index: 2;
  text-align: center;
  transition: all 0.3s;
}
.rs-team.slider2 .team-wrap .team-img .team-social a {
  color: #fff;
  display: block;
  padding: 6px 8px;
  transition: all 0.3s;
  font-size: 15px;
}
.rs-team.slider2 .team-wrap .team-img .team-social a:hover {
  color: #cccccc;
}
.rs-team.slider2 .team-wrap .team-img .team-social a:last-child {
  margin-right: 0;
}
.rs-team.slider2 .team-wrap .team-item-text .team-details {
  padding: 20px 20px 25px;
  background: #fff;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.1);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 1;
  position: relative;
  text-align: center;
}
.rs-team.slider2 .team-wrap .team-item-text .team-details .team-name {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
}
.rs-team.slider2 .team-wrap .team-item-text .team-details .team-name a {
  color: #0c121d;
}
.rs-team.slider2 .team-wrap .team-item-text .team-details .team-name a:hover {
  color: #1c3988;
}
.rs-team.slider2 .team-wrap .team-item-text .team-details .team-title {
  text-transform: uppercase;
  font-size: 14px;
  color: #777;
}
.rs-team.slider2 .team-wrap:hover .team-img .team-social {
  right: 0;
  opacity: 1;
}
.rs-team.slider2:after {
  position: absolute;
  content: '';
  left: 0;
  top: -15px;
  height: 235px;
  z-index: -1;
  width: 100%;
  background: url(./images/bg/shape/shape-3.png);
}
.rs-team.slider2.modify1 {
  position: unset;
}
.rs-team.slider2.modify1 .team-wrap .team-img .team-social {
  background: #090E40;
}
.rs-team.slider2.modify1 .team-wrap .team-img .team-social a {
  color: #fff;
}
.rs-team.slider2.modify1 .team-wrap .team-item-text .team-details {
  background: #090E40;
}
.rs-team.slider2.modify1 .team-wrap .team-item-text .team-details .team-name a {
  color: #fff;
}
.rs-team.slider2.modify1 .team-wrap .team-item-text .team-details .team-name a:hover {
  color: #106eea;
}
.rs-team.slider2.modify1 .team-wrap .team-item-text .team-details .team-title {
  color: #fff;
}
.rs-team.slider2.modify1:after {
  background: unset;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-img .team-social {
  background-color: #1566F5;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-img .team-social a {
  color: #fff;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-item-text .team-details {
  background-color: #1566F5;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-item-text .team-details .team-name a {
  color: #fff;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-item-text .team-details .team-name a:hover {
  color: #fff;
}
.rs-team.slider2.modify1.corporate2 .team-wrap .team-item-text .team-details .team-title {
  color: #fff;
}
.rs-team.slider2.modify1.corporate2 .owl-dots {
  margin-top: 25px;
}
.rs-team.grid1 .team-item {
  text-align: center;
}
.rs-team.grid1 .team-item .image-part {
  margin-bottom: 10px;
}
.rs-team.grid1 .team-item .image-part a {
  border-radius: 100%;
  overflow: hidden;
  display: inline-block;
}
.rs-team.grid1 .team-item .image-part a img {
  transition: all 0.3s ease;
}
.rs-team.grid1 .team-item .content-part .name {
  margin-bottom: 4px;
}
.rs-team.grid1 .team-item .content-part .name a {
  color: #ffffff;
}
.rs-team.grid1 .team-item .content-part .designation {
  color: #ffffff;
  display: block;
}
.rs-team.grid1 .team-item .content-part .social-links {
  margin-top: 13px;
}
.rs-team.grid1 .team-item .content-part .social-links li {
  display: inline;
  padding: 0 7px;
}
.rs-team.grid1 .team-item .content-part .social-links li a i {
  color: #ffffff;
  transition: all 0.3s ease;
}
.rs-team.grid1 .team-item .content-part .social-links li a:hover i {
  color: #ffffff;
}
.rs-team.grid1 .team-item:hover .image-part a img {
  transform: scale(1.2);
}
.rs-team.grid1.modify .team-item .content-part .name {
  font-weight: 600;
}
.rs-team.grid1.modify .team-item .content-part .name a {
  color: #1c1b1b;
}
.rs-team.grid1.modify .team-item .content-part .name a:hover {
  color: #106eea;
}
.rs-team.grid1.modify .team-item .content-part .designation {
  color: #363636;
  font-size: 15px;
}
.rs-team.grid1.modify .team-item .content-part .social-links {
  margin-top: 13px;
}
.rs-team.grid1.modify .team-item .content-part .social-links li a i {
  color: #106eea;
}
.rs-team.grid1.modify .team-item .content-part .social-links li a:hover i {
  color: #1c1b1b;
}
.rs-team.grid2 .team-wrap .team-img {
  position: relative;
  overflow: hidden;
}
.rs-team.grid2 .team-wrap .team-img a img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-team.grid2 .team-wrap .team-img .team-social {
  position: absolute;
  bottom: 0;
  right: -50px;
  opacity: 0;
  padding: 4px 0;
  background: #106eea;
  width: 50px;
  z-index: 2;
  text-align: center;
  transition: all 0.3s;
}
.rs-team.grid2 .team-wrap .team-img .team-social a {
  color: #fff;
  display: block;
  padding: 6px 8px;
  transition: all 0.3s;
  font-size: 15px;
}
.rs-team.grid2 .team-wrap .team-img .team-social a:hover {
  color: #cccccc;
}
.rs-team.grid2 .team-wrap .team-img .team-social a:last-child {
  margin-right: 0;
}
.rs-team.grid2 .team-wrap .team-item-text .team-details {
  padding: 20px 20px 25px;
  background: #fff;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.1);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 1;
  position: relative;
  text-align: center;
}
.rs-team.grid2 .team-wrap .team-item-text .team-details .team-name {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
}
.rs-team.grid2 .team-wrap .team-item-text .team-details .team-name a {
  color: #0c121d;
}
.rs-team.grid2 .team-wrap .team-item-text .team-details .team-name a:hover {
  color: #106eea;
}
.rs-team.grid2 .team-wrap .team-item-text .team-details .team-title {
  text-transform: uppercase;
  font-size: 14px;
  color: #777;
}
.rs-team.grid2 .team-wrap:hover .team-img .team-social {
  right: 0;
  opacity: 1;
}
.rs-team.style2 .team-item-wrap .team-wrap {
  padding: 38px 38px 38px 38px;
  background: url(./images/team/seo/team.png);
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.rs-team.style2 .team-item-wrap .team-wrap .image-inner {
  overflow: hidden;
  border-radius: 50%;
}
.rs-team.style2 .team-item-wrap .team-wrap .image-inner a img {
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.rs-team.style2 .team-item-wrap .team-content {
  padding: 20px 20px 20px;
}
.rs-team.style2 .team-item-wrap .team-content .person-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 5px;
}
.rs-team.style2 .team-item-wrap .team-content .person-name a {
  color: #0a0a0a;
}
.rs-team.style2 .team-item-wrap .team-content .person-name a:hover {
  color: #106eea;
}
.rs-team.style2 .team-item-wrap .team-content .designation {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: #363636;
  margin-bottom: 15px;
}
.rs-team.style2 .team-item-wrap .team-content .team-social li {
  display: inline-block;
  margin-right: 16px;
}
.rs-team.style2 .team-item-wrap .team-content .team-social li a i {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #FF9F01;
}
.rs-team.style2 .team-item-wrap .team-content .team-social li:last-child {
  margin-right: 0;
}
.rs-team.style2 .team-item-wrap:hover .team-wrap .image-inner a img {
  transform: scale(1.2);
}
.rs-team.style2 .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-team.style2 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #106eea;
  opacity: .5;
}
.rs-team.style2 .owl-dots .owl-dot:hover,
.rs-team.style2 .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-team.style2 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-team.style2.modify1 .team-item-wrap .team-wrap {
  padding: 38px 38px 38px 38px;
  background: url(./images/team/seo/solutions-bg.png);
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.rs-team.style2.modify1 .team-item-wrap .team-content .designation {
  font-size: 14px;
}
.rs-team.style2.modify1 .team-item-wrap .team-content .team-social li a i {
  color: #106eea;
}
.rs-team.inner .owl-stage-outer {
  padding-bottom: 40px;
}
.rs-team.inner .team-item {
  position: relative;
  margin-bottom: 73px;
}
.rs-team.inner .team-item .team-image img {
  border-radius: 3px 3px 0 0;
}
.rs-team.inner .team-item .text-bottom {
  padding: 24px 25px;
  background: #ffffff;
  text-align: center;
  position: absolute;
  bottom: -72.5px;
  width: 80%;
  left: 50%;
  z-index: 1;
  transition: all 0.3s ease;
  transform: translateX(-50%);
  box-shadow: 0px 15px 34px -5px rgba(193, 193, 193, 0.3);
}
.rs-team.inner .team-item .text-bottom .person-name {
  margin-bottom: 5px;
  font-size: 22px;
  text-transform: capitalize;
  line-height: normal;
}
.rs-team.inner .team-item .text-bottom .person-name a {
  color: #000;
}
.rs-team.inner .team-item .text-bottom .person-name a:hover {
  color: #106eea;
}
.rs-team.inner .team-item .text-bottom span.designation {
  display: block;
  color: #000;
  margin: 4px 0 0;
  font-size: 14px;
  text-transform: uppercase;
}
.rs-team.inner .team-item .text-bottom .desc {
  font-size: 14px;
  position: relative;
  z-index: 1;
  max-height: 0;
  opacity: 0;
  line-height: 24px;
  -webkit-transition: all 0.6s, max-height 0.5s;
  -o-transition: all 0.6s, max-height 0.5s;
  -moz-transition: all 0.6s, max-height 0.5s;
  transition: all 0.6s, max-height 0.5s;
  overflow: hidden;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  -ms-transform-origin: bottom;
  -o-transform-origin: bottom;
  transform-origin: bottom;
}
.rs-team.inner .team-item .text-bottom .desc:before {
  content: '';
  position: absolute;
  z-index: 1;
  background: #000;
  top: 0;
  left: 50%;
  width: 50px;
  height: 2px;
  transform: translateX(-50%);
}
.rs-team.inner .team-item .text-bottom .team-social {
  margin-top: 10px;
}
.rs-team.inner .team-item .text-bottom .team-social ul li {
  display: inline-block;
  margin-right: 18px;
}
.rs-team.inner .team-item .text-bottom .team-social ul li a {
  color: #106eea;
}
.rs-team.inner .team-item .text-bottom .team-social ul li a:hover {
  color: #333;
}
.rs-team.inner .team-item .text-bottom .team-social ul li:last-child {
  margin: 0;
}
.rs-team.inner .team-item:hover .text-bottom .desc {
  position: relative;
  max-height: 400px;
  opacity: 1;
  text-align: center;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.4s, max-height 1.5s;
  -o-transition: all 0.4s, max-height 1.5s;
  -moz-transition: all 0.4s, max-height 1.5s;
  transition: all 0.4s, max-height 1.5s;
  padding-top: 20px;
  margin-top: 14px;
}
/*Team Single Section CSS*/
.profile-section {
  position: relative;
}
.profile-section .content-part .title {
  color: #1c1b1b;
  font-weight: 600;
  line-height: 1.3em;
  font-size: 26px;
}
.profile-section .content-part p {
  font-size: 16px;
  margin: 0;
}
.profile-section .content-column {
  position: relative;
}
.profile-section .content-column .inner-column {
  position: relative;
}
.profile-section .content-column .inner-column h2 {
  position: relative;
  color: #1c1b1b;
  font-weight: 700;
  margin: 0;
}
.profile-section .content-column .inner-column h4 {
  position: relative;
  color: #1c1b1b;
  font-weight: 600;
  line-height: 1.3em;
  margin-top: 15px;
  margin-bottom: 20px;
}
.profile-section .content-column .inner-column h5 {
  position: relative;
  color: #1c1b1b;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.3em;
  margin-top: 12px;
  margin-bottom: 0px;
}
.profile-section .content-column .inner-column p {
  position: relative;
  color: #626262;
  font-weight: 400;
  line-height: 1.9em;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 35px;
}
.profile-section .content-column .inner-column .student-list {
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;
}
.profile-section .content-column .inner-column .student-list li {
  position: relative;
  color: #393939;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
  margin-right: 50px;
  display: inline-block;
  list-style: disc;
  margin-bottom: 20px;
}
.profile-section .content-column .inner-column .student-list li .fa {
  color: #106eea;
  margin: 0px 2px;
}
.profile-section .content-column .inner-column .student-list li:last-child {
  margin-right: 0px;
}
.profile-section .image-column {
  position: relative;
}
.profile-section .image-column .inner-column {
  position: relative;
  padding: 30px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
}
.profile-section .image-column .inner-column .image {
  position: relative;
}
.profile-section .image-column .inner-column .image img {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  width: 100%;
}
.profile-section .image-column .team-content h3 {
  position: relative;
  font-weight: 700;
  color: #1c1b1b;
  margin-top: 30px;
  margin-bottom: 10px;
}
.profile-section .image-column .team-content .text {
  position: relative;
  color: #626262;
  font-size: 16px;
  line-height: 1.7em;
  margin-bottom: 8px;
}
.profile-section .image-column .team-content .personal-info {
  margin-bottom: 20px;
}
.profile-section .image-column .team-content .personal-info li {
  margin: 5px 0;
  display: inline-block;
  padding-right: 15px;
}
.profile-section .image-column .team-content .personal-info li:last-child {
  margin-right: none;
}
.profile-section .image-column .team-content .personal-info li a {
  color: #1c1b1b;
}
.profile-section .image-column .team-content .personal-info li a:hover {
  color: #106eea;
}
.profile-section .image-column .team-content .personal-info li span i:before {
  font-size: 16px;
  margin-right: 10px;
}
.profile-section .image-column .social-box {
  position: relative;
  text-align: center;
}
.profile-section .image-column .social-box a {
  position: relative;
  color: #626262;
  font-size: 28px;
  margin: 0px 5px;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.profile-section .image-column .social-box a:hover {
  color: #106eea;
}
.profile-section .browse-course-section {
  position: relative;
  margin-top: 50px;
}
.profile-section .browse-course-section .styled-pagination {
  margin-top: 20px;
}
.profile-section .skill-title {
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.profile-section .rs-progress {
  height: 8px;
  border: 1px solid #106eea;
  border-radius: 0;
  overflow: visible;
  padding: 1px;
  background: #fff;
  margin-top: 25px;
}
.profile-section .rs-progress + .rs-progress {
  margin-top: 50px;
}
.profile-section .rs-progress .progress-bar {
  position: relative;
  text-align: left;
  line-height: 4px;
  border-radius: 0;
  box-shadow: none;
  background-color: #106eea;
  overflow: visible;
}
.profile-section .rs-progress .progress-bar .pb-label {
  position: absolute;
  left: 0px;
  top: -24px;
  color: #666;
  font-size: 15px;
  font-weight: 600;
}
.profile-section .rs-progress .progress-bar .pb-percent {
  position: absolute;
  right: -13px;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  top: -30px;
  background: #106eea;
  padding: 8px 5px;
}
.profile-section .rs-progress .progress-bar .pb-percent:after {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  left: 50%;
  border-top-color: #106eea;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/*---------------------------------------
    24. Free Consultation Section CSS
---------------------------------------*/
.rs-free-consultation.style1 .image-part {
  background: url(./images/consultation/free-consultation.jpg);
  background-position: center right;
  background-size: cover;
  height: 100%;
}
.rs-free-consultation.style1 .content-part {
  padding: 80px 0 100px 90px;
  max-width: 772px;
}
.rs-free-consultation.style1 .content-part .title-part {
  text-align: center;
}
.rs-free-consultation.style1 .content-part .title-part .top-part {
  margin-bottom: 26px;
}
.rs-free-consultation.style1 .content-part .title-part .top-part img {
  max-width: 70px;
  margin-bottom: 24px;
}
.rs-free-consultation.style1 .content-part .title-part .top-part .title {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
}
.rs-free-consultation.style1 .content-part .title-part .bottom-part .number {
  font-size: 40px;
  line-height: 1;
  font-weight: 600;
}
.rs-free-consultation.style1 .content-part .title-part .bottom-part .number a {
  color: #ffffff;
}
.rs-free-consultation.style1 .content-part .title-part .bottom-part span {
  color: #ffffff;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}
.rs-free-consultation.style1 .content-part .title-part .bottom-part .title {
  font-size: 35px;
  line-height: 1;
  font-weight: 600;
}
.rs-free-consultation.style1 .content-part .form-part button,
.rs-free-consultation.style1 .content-part .form-part textarea,
.rs-free-consultation.style1 .content-part .form-part input {
  width: 100%;
  outline: none;
  border: none;
}
.rs-free-consultation.style1 .content-part .form-part textarea,
.rs-free-consultation.style1 .content-part .form-part input {
  background: #262B3A;
  padding: 16px 20px;
  margin-bottom: 35px;
  color: #ffffff;
}
.rs-free-consultation.style1 .content-part .form-part textarea {
  display: inherit;
  height: 120px;
}
.rs-free-consultation.style1 .content-part .form-part button {
  font-weight: 500;
  text-transform: uppercase;
  padding: 14px 35px 12px;
  background: #b1976b;
  transition: all 0.3s ease;
  color: #ffffff;
}
.rs-free-consultation.style1 .content-part .form-part button:hover {
  background: #a88b5a;
}
.rs-free-consultation.style2 {
  position: relative;
}
.rs-free-consultation.style2 .animate-left {
  position: absolute;
  left: 10%;
  bottom: 80px;
}
/*---------------------------------------
    25. Decoration Section CSS
---------------------------------------*/
.rs-decoration.style1 {
  background-image: linear-gradient(180deg, #f8f2fd 0%, #e7f1fd 100%);
  background-color: transparent;
  padding-bottom: 288px;
}
.rs-decoration.style1 .parallax-elemen {
  position: relative !important;
  max-width: 749px;
  margin: 236px auto 0;
  text-align: center;
}
.rs-decoration.style1 .parallax-elemen div {
  position: unset !important;
}
.rs-decoration.style1 .parallax-elemen div img {
  max-width: 360px;
  margin: 0 auto;
}
.rs-decoration.style1 .parallax-elemen span {
  position: absolute !important;
  z-index: 1;
}
.rs-decoration.style1 .parallax-elemen span img {
  max-width: 128px;
  width: 100%;
  margin: 0 auto;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(1) {
  left: 52% !important;
  top: -35% !important;
  right: unset !important;
  bottom: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(2) {
  right: 0 !important;
  top: -10% !important;
  left: unset !important;
  bottom: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(3) {
  right: -12% !important;
  top: 33% !important;
  left: unset !important;
  bottom: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(4) {
  right: -4% !important;
  bottom: -27% !important;
  left: unset !important;
  top: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(5) {
  right: 40% !important;
  bottom: -46% !important;
  left: unset !important;
  top: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(6) {
  left: 4% !important;
  bottom: -30% !important;
  right: unset !important;
  top: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(7) {
  left: -14% !important;
  bottom: 19% !important;
  right: unset !important;
  top: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(8) {
  left: -11% !important;
  top: -1% !important;
  right: unset !important;
  bottom: unset !important;
}
.rs-decoration.style1 .parallax-elemen span:nth-child(9) {
  left: 15% !important;
  top: -32% !important;
  right: unset !important;
  bottom: unset !important;
}
/*---------------------------------------
    26. Pricing Section CSS
---------------------------------------*/
.rs-pricing.style1 .pricing-wrap {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0.2rem 2.8rem rgba(36, 36, 36, 0.1);
  text-align: center;
  padding: 50px 0 40px;
}
.rs-pricing.style1 .pricing-wrap .top-part {
  margin-bottom: 37px;
}
.rs-pricing.style1 .pricing-wrap .top-part .title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 29px;
}
.rs-pricing.style1 .pricing-wrap .top-part .price {
  color: #106eea;
  font-size: 60px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 11px;
  line-height: 1;
}
.rs-pricing.style1 .pricing-wrap .top-part .price span {
  font-size: 24px;
  margin-right: 3px;
  font-family: 'Roboto', sans-serif;
}
.rs-pricing.style1 .pricing-wrap .top-part .priod {
  font-weight: 700;
  color: #106eea;
}
.rs-pricing.style1 .pricing-wrap .middle-part li {
  margin-bottom: 8px;
}
.rs-pricing.style1 .pricing-wrap .middle-part li i {
  margin-right: 10px;
  min-width: 15px;
}
.rs-pricing.style1 .pricing-wrap .middle-part li:last-child {
  margin: 0;
}
.rs-pricing.style1 .pricing-wrap .btn-part {
  margin-top: 48px;
}
.rs-pricing.style1 .pricing-wrap .btn-part a {
  padding: 11px 23px;
  border-radius: 3px;
  color: #ffffff;
  background: #106eea;
  display: inline-block;
  font-size: 16px;
}
.rs-pricing.style1 .pricing-wrap .btn-part a:hover {
  background: #228bfd;
}
.rs-pricing.style1 .video-sec {
  padding: 199px 0;
  background: url(./images/bg/videobg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 885px;
  margin: 0 auto -100px;
  position: relative;
  z-index: 2;
  text-align: center;
  border-radius: 5px;
}
.rs-pricing.style1 .video-sec .video-btn a {
  background: rgba(18, 115, 235, 0.72);
}
.rs-pricing.style1.modify .pricing-wrap .top-part .title {
  margin-bottom: 24px;
}
.rs-pricing.style1.modify .pricing-wrap .top-part .price {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}
.rs-pricing.style1.modify .pricing-wrap.active {
  padding: 58px 0 40px;
  background: url(./images/bg/price-table-item.jpg);
  background-repeat: no-repeat;
}
.rs-pricing.style1.modify .pricing-wrap.active .top-part {
  margin-bottom: 45px;
}
.rs-pricing.style1.modify .pricing-wrap.active .top-part .title {
  color: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .top-part .price {
  color: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .top-part .priod {
  color: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .middle-part li {
  color: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .middle-part li i {
  color: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .btn-part {
  margin-top: 80px;
}
.rs-pricing.style1.modify .pricing-wrap.active .btn-part a {
  color: #106eea;
  background: #ffffff;
}
.rs-pricing.style1.modify .pricing-wrap.active .btn-part a:hover {
  background: #e6e6e6;
}
.rs-pricing.style2 .pricing-wrap {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0.2rem 2.8rem rgba(36, 36, 36, 0.1);
  padding: 30px 50px 50px;
}
.rs-pricing.style2 .pricing-wrap .icon-part {
  margin-bottom: 41px;
}
.rs-pricing.style2 .pricing-wrap .icon-part img {
  max-width: 100px;
}
.rs-pricing.style2 .pricing-wrap .top-part {
  margin-bottom: 37px;
}
.rs-pricing.style2 .pricing-wrap .top-part .title {
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 27px;
  color: #485973;
}
.rs-pricing.style2 .pricing-wrap .top-part .price {
  color: #106eea;
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 11px;
}
.rs-pricing.style2 .pricing-wrap .top-part .price span {
  font-size: 24px;
  margin-right: 3px;
  font-family: 'Roboto', sans-serif;
}
.rs-pricing.style2 .pricing-wrap .top-part .priod {
  font-weight: 700;
  color: #106eea;
}
.rs-pricing.style2 .pricing-wrap .middle-part li {
  margin-bottom: 8px;
}
.rs-pricing.style2 .pricing-wrap .middle-part li i {
  margin-right: 10px;
  min-width: 15px;
}
.rs-pricing.style2 .pricing-wrap .middle-part li:last-child {
  margin: 0;
}
.rs-pricing.style2 .pricing-wrap .btn-part {
  margin-top: 48px;
}
.rs-pricing.style2 .pricing-wrap .btn-part a {
  padding: 11px 23px;
  border-radius: 3px;
  color: #ffffff;
  background: #106eea;
  display: inline-block;
  font-size: 16px;
}
.rs-pricing.style2 .pricing-wrap .btn-part a:hover {
  background: #228bfd;
}
.rs-pricing.style2 .pricing-wrap.one .top-part .price {
  color: #fd6000;
}
.rs-pricing.style2 .pricing-wrap.one .top-part .priod {
  color: #fd6000;
}
.rs-pricing.style2 .pricing-wrap.one .btn-part a {
  background: #fd6000;
}
.rs-pricing.style2 .pricing-wrap.one .btn-part a:hover {
  background: #e45600;
}
.rs-pricing.style2 .pricing-wrap.two .top-part .price {
  color: #0cbb9d;
}
.rs-pricing.style2 .pricing-wrap.two .top-part .priod {
  color: #0cbb9d;
}
.rs-pricing.style2 .pricing-wrap.two .btn-part a {
  background: #0cbb9d;
}
.rs-pricing.style2 .pricing-wrap.two .btn-part a:hover {
  background: #0aa389;
}
.rs-pricing.style2 .pricing-wrap.three .top-part .price {
  color: #fe6588;
}
.rs-pricing.style2 .pricing-wrap.three .top-part .priod {
  color: #fe6588;
}
.rs-pricing.style2 .pricing-wrap.three .btn-part a {
  background: #fe6588;
}
.rs-pricing.style2 .pricing-wrap.three .btn-part a:hover {
  background: #fe4c74;
}
.rs-pricing.style3 .pricing-wrap {
  border-radius: 20px 20px 250px 250px;
  box-shadow: 0px 3.8px 44.8px 0px rgba(36, 36, 36, 0.1);
  overflow: hidden;
  text-align: center;
  transition: all 0.5s;
}
.rs-pricing.style3 .pricing-wrap .title-part {
  background-color: #eaf1fd;
  padding: 30px 15px 25px;
  margin-bottom: 0;
  color: #010101;
  font-weight: 400;
  font-size: 24px;
  transition: all 0.3s ease;
}
.rs-pricing.style3 .pricing-wrap .price-part {
  padding: 36px 15px 37px;
  margin-bottom: 50px;
  background-color: #fff;
  transition: all 0.3s ease;
}
.rs-pricing.style3 .pricing-wrap .price-part .price {
  color: #0955d0;
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
  display: inline-block;
  transition: all 0.3s ease;
}
.rs-pricing.style3 .pricing-wrap .price-part .price span {
  font-size: 24px;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
}
.rs-pricing.style3 .pricing-wrap .price-part .priod {
  font-weight: 700;
  padding-left: 10px;
  color: #106eea;
  transition: all 0.3s ease;
}
.rs-pricing.style3 .pricing-wrap .middle-part {
  margin-bottom: 50px;
}
.rs-pricing.style3 .pricing-wrap .middle-part li {
  border-bottom: 1px solid rgba(186, 186, 186, 0.25);
  padding: 5px 0 10px;
  margin-bottom: 10px;
}
.rs-pricing.style3 .pricing-wrap .middle-part li:last-child {
  margin: 0;
}
.rs-pricing.style3 .pricing-wrap .btn-part a {
  width: 120px;
  height: 120px;
  line-height: 100px;
  margin: 0 0 50px;
  padding: 0;
  color: #fff;
  background-color: #0955d0;
  border: 10px solid #f2f2f2;
  border-radius: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  display: inline-block;
}
.rs-pricing.style3 .pricing-wrap .btn-part a:hover {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}
.rs-pricing.style3 .pricing-wrap.active .title-part {
  background-color: #0955d0;
  color: #ffffff;
}
.rs-pricing.style3 .pricing-wrap.active .price-part {
  background-color: #2065d4;
}
.rs-pricing.style3 .pricing-wrap.active .price-part .price {
  color: #ffffff;
}
.rs-pricing.style3 .pricing-wrap.active .price-part .priod {
  color: #ffffff;
}
.rs-pricing.style3 .pricing-wrap:hover {
  box-shadow: 0px 3.8px 44.8px 0px rgba(36, 36, 36, 0.3);
}
.rs-pricing.style4 .pricing-wrap {
  background: #ffffff;
  padding: 38px 58px 45px;
  box-shadow: 0 0.2rem 2.8rem rgba(36, 36, 36, 0.1);
  border-radius: 5px;
}
.rs-pricing.style4 .pricing-wrap .top-part {
  text-align: center;
}
.rs-pricing.style4 .pricing-wrap .top-part .title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.rs-pricing.style4 .pricing-wrap .top-part .price-area {
  position: relative;
  display: inline-block;
  padding-left: 13px;
}
.rs-pricing.style4 .pricing-wrap .top-part .price-area sup {
  position: absolute;
  top: 22px;
  left: 0;
  font-size: 24px;
  font-weight: 500;
}
.rs-pricing.style4 .pricing-wrap .top-part .price-area .price {
  color: #ec4c6f;
  font-weight: 300;
  font-size: 90px;
  line-height: 1;
}
.rs-pricing.style4 .pricing-wrap .top-part .price-area sub {
  font-size: 15px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part {
  text-align: center;
  padding-top: 14px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part .desc {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 21px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part ul li {
  border-bottom: 1px solid #F0EEF1;
  padding-bottom: 16px;
  margin-bottom: 14px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part ul li:last-child {
  margin: 0;
  padding: 0;
  border-bottom: none;
}
.rs-pricing.style4 .pricing-wrap .bottom-part .btn-part {
  margin-top: 32px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part .btn-part a {
  padding: 10px 30px;
  border: 1px solid #ec4c6f;
  color: #ec4c6f;
  display: inline-block;
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
}
.rs-pricing.style4 .pricing-wrap .bottom-part .btn-part a:hover {
  background: #ec4c6f;
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center {
  background: url(./images/bg/price-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 75px;
  padding-bottom: 75px;
}
.rs-pricing.style4 .pricing-wrap.center .top-part .title {
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .top-part .price-area sup {
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .top-part .price-area .price {
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .top-part .price-area sub {
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .bottom-part .desc {
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .bottom-part ul li {
  color: #ffffff;
  border-color: #F0EEF15E;
}
.rs-pricing.style4 .pricing-wrap.center .bottom-part .btn-part a {
  border-color: #ffffff;
  color: #ffffff;
}
.rs-pricing.style4 .pricing-wrap.center .bottom-part .btn-part a:hover {
  border-color: #ec4c6f;
}
.rs-pricing.style5 .pricing-table {
  padding: 27px 40px 0px 40px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EBEBEB;
  border-radius: 5px 5px 5px 5px;
  position: relative;
  text-align: center;
}
.rs-pricing.style5 .pricing-table .pricing-badge {
  top: 30px;
  left: 0px;
  padding: 10px 33px 10px 33px;
  background-color: #1273EB;
  border-radius: 0px 30px 30px 0px;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  color: #fff;
  line-height: 1;
  z-index: 1;
}
.rs-pricing.style5 .pricing-table .pricing-badge.white-bg {
  background: #ffffff;
  color: #106eea;
}
.rs-pricing.style5 .pricing-table .pricing-badge.secondary-bg {
  background: #228bfd;
}
.rs-pricing.style5 .pricing-table .pricing-icon {
  margin-bottom: 35px;
}
.rs-pricing.style5 .pricing-table .pricing-table-price {
  margin-bottom: 30px;
}
.rs-pricing.style5 .pricing-table .pricing-table-price .pricing-table-bags .pricing-currency {
  font-size: 25px;
  font-weight: 600;
  color: #1c1b1b;
}
.rs-pricing.style5 .pricing-table .pricing-table-price .pricing-table-bags .table-price-text {
  font-size: 52px;
  font-weight: 600;
  color: #1c1b1b;
}
.rs-pricing.style5 .pricing-table .pricing-table-price .pricing-table-bags .table-period {
  color: #B1E0F9;
  font-size: 16px;
  font-weight: 500;
  display: block;
}
.rs-pricing.style5 .pricing-table .pricing-table-body {
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #EBEBEB;
  padding: 18px 15px 4px 15px;
}
.rs-pricing.style5 .pricing-table .pricing-table-body li {
  text-align: left;
  padding: 9px 0px 9px 0px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
}
.rs-pricing.style5 .pricing-table .pricing-table-body li i {
  float: right;
  margin-right: 0;
  color: #1273EB;
}
.rs-pricing.style5 .pricing-table .pricing-table-body li i.new {
  font-size: 22px;
}
.rs-pricing.style5 .pricing-table.primary-bg {
  background-image: linear-gradient(90deg, #015eea 0%, #00a9f6 87%);
}
.rs-pricing.style5 .pricing-table.primary-bg .pricing-table-price .pricing-table-bags .pricing-currency {
  color: #ffffff;
}
.rs-pricing.style5 .pricing-table.primary-bg .pricing-table-price .pricing-table-bags .table-price-text {
  color: #ffffff;
}
.rs-pricing.style5 .pricing-table.primary-bg .pricing-table-body {
  border-color: #f8f9fa;
}
.rs-pricing.style5 .pricing-table.primary-bg .pricing-table-body li {
  color: #ffffff;
}
.rs-pricing.style5 .pricing-table.primary-bg .pricing-table-body li i {
  color: #ffffff;
}
.rs-pricing.style6 .pricing-table {
  padding: 45px 40px 45px 40px;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
}
.rs-pricing.style6 .pricing-table .pricing-table-header .table-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #04004D;
  margin-bottom: 22px;
}
.rs-pricing.style6 .pricing-table .pricing-table-price .pricing-table-bags {
  line-height: .66;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.rs-pricing.style6 .pricing-table .pricing-table-price .pricing-table-bags .pricing-currency {
  font-size: 22px;
  font-weight: 500;
  color: #EC4C6F;
}
.rs-pricing.style6 .pricing-table .pricing-table-price .pricing-table-bags .table-price-text {
  font-size: 90px;
  font-weight: 300;
  color: #EC4C6F;
}
.rs-pricing.style6 .pricing-table .pricing-table-price .pricing-table-bags .table-period {
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  font-weight: 500;
  color: #EC4C6F;
}
.rs-pricing.style6 .pricing-table .pricing-table-body {
  padding: 10px 15px 30px 15px;
  text-align: left;
}
.rs-pricing.style6 .pricing-table .pricing-table-body ul li {
  padding: 9px 0px 9px 0px;
  margin-bottom: 0px;
  color: #555555;
  font-size: 15px;
  font-weight: 400;
}
.rs-pricing.style6 .pricing-table .pricing-table-body ul li i {
  float: right;
  margin-right: 0;
  min-width: 15px;
  font-size: 16px;
}
.rs-pricing.style6 .pricing-table .pricing-table-body ul li i.new {
  font-size: 22px;
}
.rs-pricing.style6 .pricing-table.white-bg {
  background-color: #fff;
}
/*---------------------------------------
    27. Latest Project Section CSS
---------------------------------------*/
.rs-project.style1 .progress-part .rs-pie-content {
  margin-bottom: 10px;
}
.rs-project.style1 .progress-part .rs-pie-content .rs-pie {
  display: inline-block;
  position: relative;
  text-align: center;
}
.rs-project.style1 .progress-part .rs-pie-content .rs-pie .rspie-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 30px;
  color: #363636;
}
.rs-project.style1 .progress-part .pie-title {
  margin-bottom: 0;
  color: #000116;
  font-size: 20px;
  font-weight: 34px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
.rs-project.style1 .video-part {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.rs-project.style1 .video-part .video-btn {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.rs-project.style2 .project-wrap {
  text-align: center;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.rs-project.style2 .project-wrap .content-part {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 32px;
  z-index: 1;
  padding: 0 30px;
  transition: all 0.5s;
}
.rs-project.style2 .project-wrap .content-part .title {
  font-size: 23px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 5px;
}
.rs-project.style2 .project-wrap .content-part .title a {
  color: #ffffff;
}
.rs-project.style2 .project-wrap .content-part .title a:hover {
  color: #d83030;
}
.rs-project.style2 .project-wrap .content-part .category {
  display: block;
}
.rs-project.style2 .project-wrap .content-part .category a {
  color: #ffffff;
}
.rs-project.style2 .project-wrap .content-part .category a:hover {
  color: #d83030;
}
.rs-project.style2 .project-wrap .btn-part {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 1;
  transition: all 0.4s;
  opacity: 0;
}
.rs-project.style2 .project-wrap .btn-part a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #d83030;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
}
.rs-project.style2 .project-wrap:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg, transparent 0%, rgba(3, 46, 66, 0.73) 100%);
}
.rs-project.style2 .project-wrap:hover .content-part {
  bottom: 50%;
  transform: translateY(50%);
}
.rs-project.style2 .project-wrap:hover .btn-part {
  opacity: 1;
  bottom: 30px;
}
.rs-project.style2 .top-part {
  padding-bottom: 291px;
}
.rs-project.style2 .wrappin {
  margin-top: -240px;
}
.rs-project.style2.onepage17 .project-wrap .content-part .title a:hover {
  color: #1273EB;
}
.rs-project.style2.onepage17 .project-wrap .content-part .category a:hover {
  color: #1273EB;
}
.rs-project.style2.onepage17 .project-wrap .btn-part a {
  background: #1273EB;
}
.rs-project.style2.onepage17 .project-wrap:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff 0 0%, #1273eb 96 100%);
}
.rs-project.style2.onepage17 .project-wrap:hover .content-part {
  bottom: 50%;
  transform: translateY(50%);
}
.rs-project.style2.onepage17 .project-wrap:hover .btn-part {
  opacity: 1;
  bottom: 30px;
}
.rs-project.style3 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style3 .project-item .project-img img {
  border-radius: 5px;
}
.rs-project.style3 .project-item .project-content {
  position: absolute;
  padding: 30px 20px 30px;
  opacity: 0;
  z-index: 1;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 86%;
  z-index: 9;
  background: #ffffff;
  transition: 0.6s;
}
.rs-project.style3 .project-item .project-content .category {
  margin: 0;
}
.rs-project.style3 .project-item .project-content .category a {
  font-size: 16px;
  color: #f67d31;
}
.rs-project.style3 .project-item .project-content .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.rs-project.style3 .project-item .project-content .title a {
  color: #0F0F0F;
}
.rs-project.style3 .project-item:hover .project-content {
  opacity: 1;
  bottom: 30px;
}
.rs-project.style4 .project-item {
  position: relative;
  z-index: 1;
  overflow: visible;
  border-radius: 5px;
  opacity: 0.2;
}
.rs-project.style4 .project-item .project-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-project.style4 .project-item .project-img a img {
  opacity: 1;
  transform: scale(1);
  transition: .6s;
  border-radius: 5px;
}
.rs-project.style4 .project-item .project-inner {
  background-color: transparent;
  background-image: linear-gradient(220deg, #00a6f5 0%, #015eea 100%);
  position: absolute;
  bottom: -60px;
  left: 0;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  text-align: center;
  width: 100%;
  padding: 30px 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px 5px 0 0;
  max-width: 370px;
  left: 50%;
  transform: translateX(-50%);
}
.rs-project.style4 .project-item .project-inner .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.rs-project.style4 .project-item .project-inner .title a {
  color: #ffffff;
}
.rs-project.style4 .project-item .project-inner .category a {
  color: #ffffff;
}
.rs-project.style4 .project-item:hover .project-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-project.style4 .project-item:hover .project-img a img {
  transform: scale(1);
  transition: .6s;
}
.rs-project.style4 .project-item:hover .project-inner {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.rs-project.style4 .project-item:hover .project-inner .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}
.rs-project.style4 .project-item:hover .project-inner .title a {
  color: #ffffff;
}
.rs-project.style4 .project-item:hover .project-inner .category a {
  color: #ffffff;
}
.rs-project.style4 .center .project-item {
  opacity: 1;
}
.rs-project.style4 .center .project-item .project-inner {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-project.style4 .owl-nav .owl-next,
.rs-project.style4 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15.5%;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 30px #eee;
  text-align: center;
  color: #1c1b1b;
  transition: all 0.5s;
}
.rs-project.style4 .owl-nav .owl-next i:before,
.rs-project.style4 .owl-nav .owl-prev i:before {
  content: "\f115";
  font-family: Flaticon;
}
.rs-project.style4 .owl-nav .owl-next {
  right: 15.5%;
  left: unset;
}
.rs-project.style4 .owl-nav .owl-next i:before {
  content: "\f114";
}
/*---------------------------------------
    28. Our specialty Section CSS
---------------------------------------*/
.rs-specialty.style1 .specialty-grid {
  background: #191919;
  padding: 20px 30px 40px 30px;
  transition: all 0.3s ease;
}
.rs-specialty.style1 .specialty-grid .number {
  font-size: 40px;
  font-weight: 600;
  line-height: 2em;
  margin: 0 0 8px 0;
  color: #106eea;
}
.rs-specialty.style1 .specialty-grid .title {
  font-size: 22px;
  margin-bottom: 9px;
}
.rs-specialty.style1 .specialty-grid .title a {
  color: #ffffff;
}
.rs-specialty.style1 .specialty-grid .title a:hover {
  color: #106eea;
}
.rs-specialty.style1 .specialty-grid .desc {
  color: #ffffff;
}
.rs-specialty.style1 .specialty-grid:hover {
  background: #121212;
}
/*---------------------------------------
    29. CTA Section CSS
---------------------------------------*/
.rs-cta.style2 {
  position: relative;
}
.rs-cta.style2 .cta-wrap {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  border-radius: 5px;
  padding: 60px 80px 70px 80px;
  max-width: 722px;
  background: #ffffff;
}
.rs-cta.style2 .cta-wrap .title {
  font-size: 42px;
  line-height: 55px;
  font-weight: 700;
}
.rs-cta.style2:after,
.rs-cta.style2:before {
  position: absolute;
  content: '';
  width: 50%;
  height: 100%;
  top: 0;
}
.rs-cta.style2:before {
  background: url(./images/cta/left-img.jpg);
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.rs-cta.style2:after {
  background: url(./images/cta/right-img.jpg);
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/*---------------------------------------
    30. Questions Section CSS
---------------------------------------*/
.rs-questions.style1 .accordion {
  border-width: 12px;
}
.rs-questions.style1 .accordion .card {
  margin-bottom: 15px;
  border: none;
  background-color: #ffffff;
}
.rs-questions.style1 .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-questions.style1 .accordion .card .card-header {
  padding: 0 !important;
  border: none;
  background-color: transparent;
}
.rs-questions.style1 .accordion .card .card-header .card-link {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #1c1b1b;
  display: block;
  padding: 14px 20px;
  background: transparent;
  border: none;
}
.rs-questions.style1 .accordion .card .card-body {
  background: transparent;
  font-weight: 400;
  color: #363636;
  padding: 0 20px 17px !important;
  border: none;
}
.rs-questions.style1 .accordion .card .card-header .card-link {
  position: relative;
  padding-right: 45px !important;
}
.rs-questions.style1 .accordion .card .card-header .card-link:after {
  content: '\f068';
  font-family: FontAwesome;
  position: absolute;
  right: 20px;
}
.rs-questions.style1 .accordion .card .card-header .card-link.collapsed:after {
  content: '\f067';
}
/*---------------------------------------
    31. Free Quote Section CSS
---------------------------------------*/
.rs-freequote.style1 {
  position: relative;
}
.rs-freequote.style1 .quote-form input,
.rs-freequote.style1 .quote-form textarea {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 30px;
  color: #ffffff;
  background: rgba(49, 69, 98, 0.47);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.35);
  font-size: 16px;
  padding: 15px 20px;
}
.rs-freequote.style1 .quote-form textarea {
  display: inherit;
  height: 120px;
}
.rs-freequote.style1 .quote-form ::-webkit-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style1 .quote-form :-ms-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style1 .quote-form ::placeholder {
  color: #ffffff;
}
.rs-freequote.style1:before,
.rs-freequote.style1:after {
  position: absolute;
  content: '';
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}
.rs-freequote.style1:before {
  left: 0;
  background: url(./images/quote/left-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.rs-freequote.style1:after {
  right: 0;
  background: url(./images/quote/right-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.rs-freequote.style1.modify .quote-form input,
.rs-freequote.style1.modify .quote-form textarea {
  background: rgba(18, 115, 235, 0.5);
  box-shadow: none;
}
.rs-freequote.style1.modify .quote-form button {
  background: #ffffff;
  color: #106eea;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.rs-freequote.style1.modify .quote-form button:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-freequote.style1.modify:after {
  right: 0;
  background: url(./images/quote/right-bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.rs-freequote.style2 .quote-form {
  padding: 50px 50px 76px;
  background-color: #1c3988;
  border-radius: 0;
  box-shadow: 0px 0px 78px 0px #4054b2;
  max-width: 623px;
  margin: 0 auto;
}
.rs-freequote.style2 .quote-form input,
.rs-freequote.style2 .quote-form textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10.5px 20px;
  color: #fff;
  background-color: #2c4792;
}
.rs-freequote.style2 .quote-form textarea {
  display: inherit;
  height: 120px;
}
.rs-freequote.style2 .quote-form .submit-btn {
  text-align: center;
}
.rs-freequote.style2 .quote-form .submit-btn .readon {
  background: #67c27c;
  color: #ffffff;
  padding: 11px 27px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.rs-freequote.style2 .quote-form ::-webkit-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style2 .quote-form :-ms-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style2 .quote-form ::placeholder {
  color: #ffffff;
}
.rs-freequote.style3 .quote-form {
  background: url(./images/quote/right-bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 72px 70px 94px;
}
.rs-freequote.style3 .quote-form input,
.rs-freequote.style3 .quote-form textarea {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 30px;
  color: #ffffff;
  background: rgba(18, 115, 235, 0.5);
  font-size: 16px;
  padding: 11px 20px;
}
.rs-freequote.style3 .quote-form textarea {
  display: inherit;
  height: 120px;
}
.rs-freequote.style3 .quote-form button {
  background: #ffffff;
  color: #106eea;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.rs-freequote.style3 .quote-form button:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-freequote.style3 .quote-form ::-webkit-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style3 .quote-form :-ms-input-placeholder {
  color: #ffffff;
}
.rs-freequote.style3 .quote-form ::placeholder {
  color: #ffffff;
}
.rs-freequote.style3 .custom1 {
  ms-flex: 45.965%;
  flex: 45.965%;
  max-width: 45.965%;
}
.rs-freequote.style3 .custom2 {
  ms-flex: 54.035%;
  flex: 54.035%;
  max-width: 54.035%;
}
.rs-freequote.style3 .img-part {
  height: 100%;
  background: url(./images/quote/left-bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/*---------------------------------------
    32. Contact Section CSS
---------------------------------------*/
.rs-contact.style1 .form-part {
  padding: 60px 60px 60px 75px;
}
.rs-contact.style1 .contact-form .common-control input,
.rs-contact.style1 .contact-form .common-control textarea {
  background: #f1f6fc;
  width: 100%;
  padding: 10px 18px;
  border: none;
  outline: none;
  border-radius: 0;
  color: #363636;
  font-size: 16px;
}
.rs-contact.style1 .contact-form .common-control input {
  height: 48px;
}
.rs-contact.style1 .contact-form .common-control textarea {
  height: 120px;
}
.rs-contact.style1 .contact-form .common-control ::placeholder {
  color: #a19e9e;
}
.rs-contact.style1 .contact-info {
  background: #106eea;
  height: 100%;
  color: #ffffff;
  padding: 50px;
  font-size: 16px;
}
.rs-contact.style1 .contact-info .title {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 34px;
}
.rs-contact.style1 .contact-info .info-wrap {
  display: flex;
}
.rs-contact.style1 .contact-info .info-wrap .icon-part {
  margin-right: 25px;
}
.rs-contact.style1 .contact-info .info-wrap .icon-part i {
  font-size: 40px;
  line-height: 40px;
}
.rs-contact.style1 .contact-info .info-wrap .icon-part i:before {
  font-size: 40px;
  line-height: 40px;
}
.rs-contact.style1 .contact-info .info-wrap .content-part h4 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 5px;
}
.rs-contact.style1 .contact-info .info-wrap .content-part p {
  margin: 0;
}
.rs-contact.style1 .contact-info .info-wrap .content-part a {
  color: #ffffff;
}
.rs-contact.style1 .contact-info .info-wrap .content-part a:hover {
  opacity: 0.8;
}
.rs-contact.style1.red-mode .contact-form .common-control input,
.rs-contact.style1.red-mode .contact-form .common-control textarea {
  background: #ffffff;
}
.rs-contact.style1.red-mode .contact-form .common-control ::placeholder {
  color: #a19e9e;
}
.rs-contact.style1.red-mode .contact-info {
  background: #ec4c6f;
}
.rs-contact.style2 .form-part {
  background: url(./images/bg/form-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.rs-contact.style2 .contact-form .common-control input,
.rs-contact.style2 .contact-form .common-control textarea {
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.18);
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 0;
  color: #F2F2F2;
  font-size: 16px;
}
.rs-contact.style2 .contact-form .common-control input {
  height: 48px;
}
.rs-contact.style2 .contact-form .common-control textarea {
  height: 120px;
}
.rs-contact.style2 .contact-form .common-control ::placeholder {
  color: #a19e9e;
}
.rs-contact.style2 .g-map iframe {
  width: 100%;
  height: 715px;
  border: none;
  filter: grayscale(100%);
  display: inherit;
}
.rs-contact.style3 .contact-form input,
.rs-contact.style3 .contact-form textarea {
  background-color: #f3f5fa;
  width: 100%;
  border: none;
  outline: none;
  padding: 20px;
  border-radius: 0;
  color: #000;
  font-size: 16px;
}
.rs-contact.style3 .contact-form button {
  width: 100%;
  padding: 16px 30px;
  border: none;
  outline: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  background: #d83030;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
.rs-contact.style3 .contact-form button:hover {
  background: #da3d3d;
}
.rs-contact.style3 .contact-form textarea {
  display: inherit;
  height: 120px;
}
.rs-contact.style3 .contact-form ::placeholder {
  color: #685050;
  opacity: 1;
}
.rs-contact.style3 .address-wrap .address-part {
  display: flex;
}
.rs-contact.style3 .address-wrap .address-part .icon-part {
  margin-right: 20px;
  margin-top: 6px;
}
.rs-contact.style3 .address-wrap .address-part .icon-part i {
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 100%;
  text-align: center;
  background: #d83030;
  color: #ffffff;
}
.rs-contact.style3 .address-wrap .address-part .content-part .label {
  display: block;
  color: #999999;
  font-weight: 600;
}
.rs-contact.style3 .address-wrap .address-part .content-part .desc {
  color: #363636;
}
.rs-contact.style3 .address-wrap .address-part .content-part a {
  color: #363636;
}
.rs-contact.style3 .address-wrap .address-part .content-part a:hover {
  color: #d83030;
}
.rs-contact.style3 .g-map iframe {
  width: 100%;
  height: 540px;
  border: none;
  filter: grayscale(100%);
  display: inherit;
}
.rs-contact.style4 .contact-wrap {
  max-width: 800px;
  margin: 0 auto;
}
.rs-contact.style4 .contact-wrap .from-control {
  padding: 16px 20px 16px 20px;
  border-radius: 3px 3px 3px 3px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #FFFFFF;
  width: 100%;
  opacity: 1;
}
.rs-contact.style4 .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 0.3;
}
.rs-contact.style4 .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 0.3;
}
.rs-contact.style4 .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 0.3;
}
.rs-contact.style4 .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 0.3;
}
.rs-contact.style5 .contact-us .contact-widget {
  padding: 60px 60px 60px 60px;
  background: #ffffff;
}
.rs-contact.style5 .contact-us .contact-widget .from-control {
  padding: 10px 20px 10px 20px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  background-color: #F9F9F9;
  width: 100%;
  opacity: 1;
}
.rs-contact.style5 .contact-us .contact-widget textarea {
  height: 120px;
}
.rs-contact.style5 .contact-us .contact-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636;
  opacity: 0.5;
}
.rs-contact.style5 .contact-us .contact-widget ::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636;
  opacity: 0.5;
}
.rs-contact.style5 .contact-us .contact-widget :-ms-input-placeholder {
  /* IE 10+ */

  color: #363636;
  opacity: 0.5;
}
.rs-contact.style5 .contact-us .contact-widget :-moz-placeholder {
  /* Firefox 18- */

  color: #363636;
  opacity: 0.5;
}
.rs-contact.style5 .contact-box {
  background: url(./images/bg/contact-12.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 70px 50px 77px 50px;
}
.rs-contact.style5 .contact-box .address-box {
  display: flex;
}
.rs-contact.style5 .contact-box .address-box .box-icon {
  margin-right: 25px;
}
.rs-contact.style5 .contact-box .address-box .box-icon i:before {
  font-size: 35px;
  color: #ffffff;
}
.rs-contact.style5 .contact-box .address-box .address-text .label {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}
.rs-contact.style5 .contact-box .address-box .address-text .desc {
  color: #ffffff;
  margin-bottom: 0;
}
.rs-contact.style5 .contact-box .address-box .address-text .desc a {
  color: #ffffff;
}
.rs-contact.style5 .contact-box .address-box .address-text .desc a:hover {
  color: #f67d31;
}
.rs-contact.style5.modify1 .contact-us .contact-widget {
  padding: 0px 33px 38px 37px;
}
.rs-contact.style5.modify1 .contact-box {
  background: url(./images/bg/contact-16.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #070F3A;
  padding: 10px 50px 10px 30px;
}
.rs-contact.style5.modify1 .contact-box .address-box .address-text .desc a {
  color: #ffffff;
}
.rs-contact.style5.modify1 .contact-box .address-box .address-text .desc a:hover {
  color: #106eea;
}
.rs-contact.style6 .contact-widget {
  padding: 70px 50px 70px 50px;
  background: #ffffff;
  border-radius: 30px;
}
.rs-contact.style6 .contact-widget .from-control {
  padding: 20px 20px 20px 20px;
  color: #5B5A5A;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0px #eee;
  background-color: #fff;
  width: 100%;
  opacity: 1;
}
.rs-contact.style6 .contact-widget textarea {
  height: 120px;
}
.rs-contact.style6 .contact-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636;
}
.rs-contact.style6 .contact-widget ::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636;
}
.rs-contact.style6 .contact-widget :-ms-input-placeholder {
  /* IE 10+ */

  color: #363636;
}
.rs-contact.style6 .contact-widget :-moz-placeholder {
  /* Firefox 18- */

  color: #363636;
}
.rs-contact.style6 .contact-box {
  padding: 0px 0px 0px 35px;
}
.rs-contact.style6 .contact-box .address-box {
  display: flex;
}
.rs-contact.style6 .contact-box .address-box .box-icon {
  margin-right: 25px;
}
.rs-contact.style6 .contact-box .address-box .box-icon i:before {
  font-size: 35px;
  color: #ffffff;
}
.rs-contact.style6 .contact-box .address-box .address-text .label {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}
.rs-contact.style6 .contact-box .address-box .address-text .desc {
  color: #ffffff;
  margin-bottom: 0;
}
.rs-contact.style6 .contact-box .address-box .address-text .desc a {
  color: #ffffff;
}
.rs-contact.style6 .contact-box .address-box .address-text .desc a:hover {
  color: #090E40;
}
.rs-contact.onepage13 .contact-box .address-box {
  display: flex;
}
.rs-contact.onepage13 .contact-box .address-box .box-icon {
  margin-right: 26px;
  background-color: #fff;
  min-width: 60px;
  height: 60px;
  line-height: 60px !important;
  box-shadow: 7px 5px 30px 0px #e9ecef;
  border-radius: 0px 0px 0px 0px;
  display: inline-block;
  text-align: center;
}
.rs-contact.onepage13 .contact-box .address-box .box-icon img {
  width: 35px;
}
.rs-contact.onepage13 .contact-box .address-box .address-text .label {
  color: #111111;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin: 0px 0px 0px 0px;
}
.rs-contact.onepage13 .contact-box .address-box .address-text .desc {
  color: #666666;
  margin-bottom: 0;
}
.rs-contact.onepage13 .contact-box .address-box .address-text .desc a {
  color: #666666;
}
.rs-contact.onepage13 .contact-box .address-box .address-text .desc a:hover {
  color: #106eea;
}
.rs-contact.onepage13 .contact-part {
  box-shadow: 5px 7px 30px 0px #e9ecef;
  padding: 30px 40px 30px 40px;
  background: #ffffff;
}
.rs-contact.onepage13 .contact-part .from-control {
  width: 100%;
  padding: 15px 25px 15px 25px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  background-color: #F1F6FC;
}
.rs-contact.onepage13 .contact-part textarea {
  height: 120px;
}
.rs-contact.onepage13 .contact-part ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage13 .contact-part ::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage13 .contact-part :-ms-input-placeholder {
  /* IE 10+ */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage13 .contact-part :-moz-placeholder {
  /* Firefox 18- */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage13 .contact-map {
  overflow: hidden;
}
.rs-contact.onepage13 .contact-map iframe {
  float: left;
  width: 100%;
  height: 450px;
  border: none;
}
.rs-contact.onepage14 .contact-box .address-box {
  display: flex;
  align-items: center;
}
.rs-contact.onepage14 .contact-box .address-box .box-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #BF0505;
  border-radius: 100%;
  text-align: center;
  margin-right: 20px;
}
.rs-contact.onepage14 .contact-box .address-box .box-icon i {
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
.rs-contact.onepage14 .contact-box .address-box .address-text .label {
  color: #5B4A4A;
  font-weight: 600;
  margin: 0px 0px 0px 0px;
}
.rs-contact.onepage14 .contact-box .address-box .address-text .desc {
  color: #3F3131;
  margin: 0;
}
.rs-contact.onepage14 .contact-box .address-box .address-text .desc a {
  color: #3F3131;
}
.rs-contact.onepage14 .contact-box .address-box .address-text .desc a:hover {
  color: #bf0505;
}
.rs-contact.onepage14 .contact-part .from-control {
  width: 100%;
  padding: 15px 25px 15px 25px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #FFFFFF;
  background-color: #F3F5FA;
}
.rs-contact.onepage14 .contact-part textarea {
  height: 120px;
}
.rs-contact.onepage14 .contact-part ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage14 .contact-part ::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage14 .contact-part :-ms-input-placeholder {
  /* IE 10+ */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage14 .contact-part :-moz-placeholder {
  /* Firefox 18- */

  color: #363636;
  opacity: 1;
}
.rs-contact.onepage14 .contact-map {
  overflow: hidden;
}
.rs-contact.onepage14 .contact-map iframe {
  float: left;
  width: 100%;
  height: 450px;
  border: none;
}
.rs-contact.inner .content-info-part .info-item {
  background: #f1f6fc;
  padding: 50px 30px 37px;
  text-align: center;
}
.rs-contact.inner .content-info-part .info-item .icon-part {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 100%;
  margin: 0 auto 25px;
  text-align: center;
  background: #acccf4;
  position: relative;
}
.rs-contact.inner .content-info-part .info-item .icon-part i {
  font-size: 80px;
  color: #106eea;
  line-height: 1;
  margin-top: -5px;
  position: relative;
  z-index: 1;
}
.rs-contact.inner .content-info-part .info-item .icon-part i.fa-map-o {
  font-size: 65px;
  margin: 0;
}
.rs-contact.inner .content-info-part .info-item .icon-part:after {
  background: transparent;
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
  transition: all 0.3s ease;
}
.rs-contact.inner .content-info-part .info-item .content-part {
  font-size: 18px;
}
.rs-contact.inner .content-info-part .info-item .content-part .title {
  font-family: 'Roboto', sans-serif;
  color: #363636;
  margin-bottom: 12px;
}
.rs-contact.inner .content-info-part .info-item .content-part a {
  color: #363636;
}
.rs-contact.inner .content-info-part .info-item .content-part a:hover {
  color: #106eea;
}
.rs-contact.inner .content-info-part .info-item .content-part p {
  margin: 0;
}
.rs-contact.inner .content-info-part .info-item:hover .icon-part:after {
  background: #acccf4;
}
.rs-contact.inner .contact-form-part form {
  background: url(./images/quote/right-bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 72px 70px 94px;
}
.rs-contact.inner .contact-form-part form input,
.rs-contact.inner .contact-form-part form textarea {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 30px;
  color: #ffffff;
  background: rgba(18, 115, 235, 0.5);
  font-size: 16px;
  padding: 11px 20px;
}
.rs-contact.inner .contact-form-part form textarea {
  display: inherit;
  height: 120px;
}
.rs-contact.inner .contact-form-part form button {
  background: #ffffff;
  color: #106eea;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.rs-contact.inner .contact-form-part form button:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-contact.inner .contact-form-part form ::-webkit-input-placeholder {
  color: #ffffff;
}
.rs-contact.inner .contact-form-part form :-ms-input-placeholder {
  color: #ffffff;
}
.rs-contact.inner .contact-form-part form ::placeholder {
  color: #ffffff;
}
.rs-contact.inner .contact-form-part .custom1 {
  ms-flex: 45.965%;
  flex: 45.965%;
  max-width: 45.965%;
}
.rs-contact.inner .contact-form-part .custom2 {
  ms-flex: 54.035%;
  flex: 54.035%;
  max-width: 54.035%;
}
.rs-contact.inner .contact-form-part .img-part {
  height: 100%;
  background: url(./images/contact/left-img.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.rs-contact.inner .g-map {
  padding: 0 10px 10px;
}
.rs-contact.inner .g-map iframe {
  width: 100%;
  height: 560px;
  border: none;
  filter: grayscale(100%);
  display: inherit;
}
.rs-contact.inner .g-map.modify iframe {
  filter: unset;
}
.rs-contact .rs-map-6 iframe {
  width: 100%;
  height: 575px;
  border: none;
  margin: 0;
}
/*---------------------------------------
    33. Blog Section CSS
---------------------------------------*/
.rs-blog.style1 .owl-stage-outer {
  padding-bottom: 5px;
  margin-bottom: 29px;
}
.rs-blog.style1 .blog-wrap {
  transition: all 0.3s ease;
}
.rs-blog.style1 .blog-wrap .img-part {
  position: relative;
}
.rs-blog.style1 .blog-wrap .img-part img {
  transition: all 0.3s ease;
}
.rs-blog.style1 .blog-wrap .img-part .fly-btn {
  position: absolute;
  left: 50%;
  bottom: -24px;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.rs-blog.style1 .blog-wrap .img-part .fly-btn a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100%;
  background: #106eea;
  text-align: center;
  display: inline-block;
}
.rs-blog.style1 .blog-wrap .img-part .fly-btn a i:before {
  color: #ffffff;
  font-size: 16px;
}
.rs-blog.style1 .blog-wrap .img-part .fly-btn a:hover {
  background: #228bfd;
}
.rs-blog.style1 .blog-wrap .content-part {
  padding: 33px 30px 25px;
  box-shadow: 0 5px 20px rgba(34, 34, 34, 0.1);
  background: #ffffff;
}
.rs-blog.style1 .blog-wrap .content-part a.categories {
  text-transform: uppercase;
  color: #363636;
  display: inline-block;
  font-size: 15px;
}
.rs-blog.style1 .blog-wrap .content-part a.categories:hover {
  color: #106eea;
}
.rs-blog.style1 .blog-wrap .content-part .title {
  margin: 6px 0 15px;
}
.rs-blog.style1 .blog-wrap .content-part .title a {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #1c1b1b;
}
.rs-blog.style1 .blog-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta .user-data {
  display: flex;
  align-items: center;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta .user-data img {
  max-width: 40px;
  border-radius: 100%;
  margin-right: 8px;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta .user-data span {
  font-size: 15px;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta .date {
  font-size: 14px;
}
.rs-blog.style1 .blog-wrap .content-part .blog-meta .date i {
  color: #106eea;
  margin-right: 5px;
}
.rs-blog.style1 .blog-wrap:hover {
  margin-top: -10px;
}
.rs-blog.style1 .blog-wrap:hover .img-part img {
  filter: grayscale(1);
}
.rs-blog.style1 .blog-wrap:hover .img-part .fly-btn {
  opacity: 1;
  visibility: visible;
}
.rs-blog.style1.modify .blog-wrap .img-part .fly-btn {
  bottom: -29px;
}
.rs-blog.style1.modify .blog-wrap .img-part .fly-btn a {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.rs-blog.style1.modify .blog-wrap .content-part a.categories {
  color: #106eea;
}
.rs-blog.style1.modify .blog-wrap .content-part .desc {
  margin-bottom: 14px;
}
.rs-blog.style1.modify .blog-wrap .content-part .blog-meta .user-data {
  font-weight: 500;
  color: #1c1b1b;
}
.rs-blog.style1.modify .blog-wrap .content-part .blog-meta .date {
  font-weight: 500;
  color: #1c1b1b;
}
.rs-blog.style1.modify .blog-wrap .content-part .blog-meta .date i {
  color: #106eea;
  font-weight: normal;
}
.rs-blog.style1.modify2 .owl-stage-outer {
  padding-bottom: 30px;
  margin-bottom: 0;
}
.rs-blog.style1.modify2 .blog-wrap .img-part .fly-btn {
  bottom: unset;
}
.rs-blog.style1.modify2 .blog-wrap .img-part .fly-btn a {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.rs-blog.style1.modify2 .blog-wrap .content-part a.categories {
  color: #106eea;
}
.rs-blog.style1.modify2 .blog-wrap .content-part .desc {
  margin-bottom: 14px;
}
.rs-blog.style1.modify2 .blog-wrap .content-part .blog-meta .user-data {
  color: #1c1b1b;
}
.rs-blog.style1.modify2 .blog-wrap .content-part .blog-meta .date {
  color: #1c1b1b;
}
.rs-blog.style1.modify2 .blog-wrap .content-part .blog-meta .date i {
  color: #106eea;
  font-weight: normal;
}
.rs-blog.style1.modify2.modify-home13 .owl-stage-outer {
  padding-top: 10px;
}
.rs-blog.style1.modify2.modify-home13 .blog-wrap {
  padding: 30px 30px 30px 30px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EDEDED;
  transition: all 500ms ease;
  border-radius: 5px;
}
.rs-blog.style1.modify2.modify-home13 .blog-wrap .img-part img {
  border-radius: 5px 5px 0 0;
}
.rs-blog.style1.modify2.modify-home13 .blog-wrap .content-part {
  background: unset;
  box-shadow: unset;
  padding: 25px 0px 0px 0px;
}
.rs-blog.style1.modify2.modify-home13 .blog-wrap:hover {
  margin-top: unset;
  transform: translateY(-10px);
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .img-part img {
  border-radius: 5px 5px 0 0;
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .content-part {
  background: unset;
  box-shadow: unset;
  padding: 25px 0px 0px 0px;
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .content-part a.categories {
  color: #bf0505;
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .content-part .title a {
  line-height: 1.2;
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .content-part .title a:hover {
  color: #bf0505;
}
.rs-blog.style1.modify2.modify-home13.marketing .blog-wrap .content-part .blog-meta .date i {
  color: #bf0505;
}
.rs-blog.style1.modify2.corporate .blog-wrap .content-part {
  text-align: center;
}
.rs-blog.style1.modify2.corporate2 .blog-wrap {
  border-radius: 5px;
}
.rs-blog.style1.modify2.corporate2 .blog-wrap .content-part .title a {
  color: #0a0a0a;
}
.rs-blog.style1.modify2.corporate2 .blog-wrap .content-part .title a:hover {
  color: #090E40;
}
.rs-blog.style1.modify2.green .blog-wrap .img-part .fly-btn {
  bottom: -29px;
}
.rs-blog.style1.modify2.green .blog-wrap .img-part .fly-btn a {
  background: #67c27c;
}
.rs-blog.style1.modify2.green .blog-wrap .content-part a.categories {
  display: none;
}
.rs-blog.style1.modify2.green .blog-wrap .content-part .title a:hover {
  color: #67c27c;
}
.rs-blog.style1.modify2.green .blog-wrap .content-part .blog-meta .date i {
  color: #67c27c;
}
.rs-blog.style1.modify3 .blog-wrap .content-part a.categories {
  color: #f67d31;
}
.rs-blog.style1.modify3 .blog-wrap .content-part a.categories:hover {
  color: #106eea;
}
.rs-blog.style1.modify3 .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-blog.style1.modify3 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #106eea;
  opacity: .5;
}
.rs-blog.style1.modify3 .owl-dots .owl-dot:hover,
.rs-blog.style1.modify3 .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-blog.style1.modify3 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-blog.style1.modify3.green .blog-wrap .img-part .fly-btn {
  bottom: -29px;
}
.rs-blog.style1.modify3.green .blog-wrap .img-part .fly-btn a {
  background: #67c27c;
}
.rs-blog.style1.modify3.green .blog-wrap .content-part a.categories {
  display: none;
}
.rs-blog.style1.modify3.green .blog-wrap .content-part .title a:hover {
  color: #67c27c;
}
.rs-blog.style1.modify3.green .blog-wrap .content-part .blog-meta .date i {
  color: #67c27c;
}
.rs-blog.style1.modify4 .blog-wrap {
  transition: all 0.3s ease;
  border-radius: 5px;
  overflow: hidden;
}
.rs-blog.style1.modify4 .blog-wrap .img-part .fly-btn a {
  background: #ec4c6f;
}
.rs-blog.style1.modify4 .blog-wrap .img-part .fly-btn a:hover {
  background-color: #DD4F6E;
}
.rs-blog.style1.modify4 .blog-wrap .content-part {
  box-shadow: unset;
}
.rs-blog.style1.modify4 .blog-wrap .content-part a.categories:hover {
  color: #ec4c6f;
}
.rs-blog.style1.modify4 .blog-wrap .content-part .title a:hover {
  color: #ec4c6f;
}
.rs-blog.style1.modify4 .blog-wrap .content-part .blog-meta .date i {
  color: #ec4c6f;
}
.rs-blog.style1.modify4 .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-blog.style1.modify4 .owl-dots .owl-dot {
  background: #ec4c6f;
}
.rs-blog.style2 .blog-wrap {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.rs-blog.style2 .blog-wrap .content-part {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  background: #ffffff;
  text-align: center;
  padding: 23px 15px 20px;
  border-radius: 5px;
}
.rs-blog.style2 .blog-wrap .content-part .title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 0;
}
.rs-blog.style2 .blog-wrap .content-part .title a {
  color: #101010;
}
.rs-blog.style2 .blog-wrap .content-part .title a:hover {
  color: #ec4c6f;
}
.rs-blog.style3 .blog-wrap {
  text-align: center;
  background: #ffffff;
  padding: 15px 15px 0 15px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.rs-blog.style3 .blog-wrap .img-part {
  position: relative;
}
.rs-blog.style3 .blog-wrap .img-part img {
  border-radius: 5px 5px 0 0;
  transition: all 0.3s ease;
}
.rs-blog.style3 .blog-wrap .img-part .blog-meta {
  min-width: 150px;
  text-align: center;
  background: #d83030;
  color: #ffffff;
  position: absolute;
  content: '';
  padding: 8.5px 0;
  border-radius: 5px 5px 0 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  font-size: 15px;
}
.rs-blog.style3 .blog-wrap .img-part .blog-meta i {
  margin-right: 5px;
}
.rs-blog.style3 .blog-wrap .content-part {
  position: relative;
  padding: 26px 15px 73px;
}
.rs-blog.style3 .blog-wrap .content-part .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.rs-blog.style3 .blog-wrap .content-part .title a {
  color: #032e42;
}
.rs-blog.style3 .blog-wrap .content-part .title a:hover {
  color: #d83030;
}
.rs-blog.style3 .blog-wrap .content-part .btn-part {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 0.3s ease;
}
.rs-blog.style3 .blog-wrap .content-part .btn-part a {
  font-size: 20px;
  padding: 10px;
  color: #ffffff;
}
.rs-blog.style3 .blog-wrap .content-part:after,
.rs-blog.style3 .blog-wrap .content-part:before {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  margin: 0 auto;
  transition: all 0.3s ease;
}
.rs-blog.style3 .blog-wrap .content-part:before {
  width: 170px;
  height: 170px;
  border: 1px solid #f3f3f3;
  bottom: -125px;
}
.rs-blog.style3 .blog-wrap .content-part:after {
  background: #f3f3f3;
  width: 126px;
  height: 126px;
  bottom: -98px;
}
.rs-blog.style3 .blog-wrap:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background: #d83030;
  transition: all 0.5s;
}
.rs-blog.style3 .blog-wrap:hover .img-part img {
  filter: grayscale(1);
}
.rs-blog.style3 .blog-wrap:hover .content-part .btn-part {
  bottom: 0;
}
.rs-blog.style3 .blog-wrap:hover .content-part:before {
  width: 182px;
  height: 182px;
  border-color: #d83030;
}
.rs-blog.style3 .blog-wrap:hover .content-part:after {
  width: 140px;
  height: 140px;
  background: #d83030;
}
.rs-blog.style3 .blog-wrap:hover:after {
  width: 100%;
}
.rs-blog.style4 .blog-item .blog-wrap {
  position: relative;
  overflow: hidden;
  transition: all 500ms ease;
  border-radius: 5px;
}
.rs-blog.style4 .blog-item .blog-wrap .img-part {
  position: relative;
}
.rs-blog.style4 .blog-item .blog-wrap .img-part a img {
  transition: all .8s ease 0s;
  border-radius: 5px 5px 0 0;
}
.rs-blog.style4 .blog-item .blog-wrap .img-part:before {
  opacity: .85;
  background: 0 0;
  background: linear-gradient(transparent, #333333), linear-gradient(transparent, #333333);
  background: -moz-linear-gradient(transparent, #333333), -moz-linear-gradient(transparent, #333333);
  background: -o-linear-gradient(transparent, #333333), -o-linear-gradient(transparent, #333333);
  content: "";
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  transition: all .7s ease 0s;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content {
  margin-bottom: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: 0 0;
  text-align: left;
  padding: 50px 30px 60px 30px;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .categories {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .categories:hover {
  color: #106eea;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 14px;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .title a {
  color: #ffffff;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .title a:hover {
  color: #106eea;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .blog-meta .date {
  font-size: 14px;
  color: #ffffff;
}
.rs-blog.style4 .blog-item .blog-wrap .blog-content .blog-meta .date i {
  color: #1273EB;
  margin-right: 5px;
}
.rs-blog.style4 .blog-item .blog-wrap:hover {
  transform: translateY(-10px);
}
.rs-blog.style4 .blog-item .blog-wrap:hover .img-part {
  position: relative;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .img-part a img {
  transform: scale3d(1.1, 1.1, 1.1);
  filter: grayscale(1);
}
.rs-blog.style4 .blog-item .blog-wrap:hover .img-part:before {
  opacity: .85;
  background: 0 0;
  background: linear-gradient(transparent, #333333), linear-gradient(transparent, #333333);
  background: -moz-linear-gradient(transparent, #333333), -moz-linear-gradient(transparent, #333333);
  background: -o-linear-gradient(transparent, #333333), -o-linear-gradient(transparent, #333333);
  content: "";
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  transition: all .7s ease 0s;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content {
  margin-bottom: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: 0 0;
  text-align: left;
  padding: 50px 30px 60px 30px;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .categories {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .categories:hover {
  color: #106eea;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 14px;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .title a {
  color: #ffffff;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .title a:hover {
  color: #106eea;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .blog-meta .date {
  font-size: 14px;
  color: #ffffff;
}
.rs-blog.style4 .blog-item .blog-wrap:hover .blog-content .blog-meta .date i {
  color: #1273EB;
  margin-right: 5px;
}
.rs-blog.style4 .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-blog.style4 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #106eea;
  opacity: .5;
}
.rs-blog.style4 .owl-dots .owl-dot:hover,
.rs-blog.style4 .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-blog.style4 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-blog.inner .blog-wrap .content-part {
  padding: 36px 46px 45px;
}
.rs-blog.inner .blog-wrap .content-part .title {
  font-size: 26px;
  line-height: 35px;
}
.rs-blog.inner .blog-wrap .content-part .title a {
  color: #1c1b1b;
}
.rs-blog.inner .blog-wrap .content-part .title a:hover {
  color: #106eea;
}
.rs-blog.inner .blog-wrap .content-part .blog-meta li {
  display: inline;
  font-size: 13px;
  margin-right: 15px;
}
.rs-blog.inner .blog-wrap .content-part .blog-meta li i {
  margin-right: 3px;
  color: #106eea;
  font-size: 14px;
  font-weight: 700;
}
.rs-blog.inner .blog-wrap .content-part .blog-meta li a {
  color: #363636;
}
.rs-blog.inner .blog-wrap .content-part .blog-meta li a:hover {
  color: #106eea;
}
.rs-blog.inner .blog-wrap .content-part .blog-meta li:last-child {
  margin: 0;
}
.rs-blog.inner .blog-sidebar {
  width: 350px;
}
.rs-blog.single .blog-part .article-content {
  padding: 38px 40px 34px;
}
.rs-blog.single .blog-part .article-content .blog-meta li {
  display: inline;
  font-size: 14px;
  margin-right: 10px;
}
.rs-blog.single .blog-part .article-content .blog-meta li i {
  margin-right: 3px;
  color: #106eea;
  font-size: 13px;
}
.rs-blog.single .blog-part .article-content .blog-meta li a {
  color: #363636;
}
.rs-blog.single .blog-part .article-content .blog-meta li a:hover {
  color: #106eea;
}
.rs-blog.single .blog-part .article-content .blog-meta li:last-child {
  margin: 0;
}
.rs-blog.single .blog-part .article-content .markup-text {
  padding: 40px 40px 50px 30px;
  color: #666;
  font-weight: 400;
  font-style: italic;
  text-align: left;
  clear: both;
  box-shadow: 0 0 150px #eee !important;
  border-left: 10px solid #106eea;
}
.rs-blog.single .blog-part .article-nav {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(170, 170, 170, 0.25);
  padding: 30px 0;
}
.rs-blog.single .blog-part .article-nav ul {
  list-style: none;
}
.rs-blog.single .blog-part .article-nav ul li {
  width: 50%;
  display: inline-block;
}
.rs-blog.single .blog-part .article-nav ul li a span {
  text-transform: uppercase;
  color: #909090;
  display: block;
  vertical-align: middle;
  transition: all 0.3s ease;
}
.rs-blog.single .blog-part .article-nav ul li a span i {
  margin-top: -4px;
  display: inline-block;
}
.rs-blog.single .blog-part .article-nav ul li a span i:before {
  font-size: 15px;
}
.rs-blog.single .blog-part .article-nav ul li a span.link-text {
  text-transform: capitalize;
  color: #1c1b1b;
  font-weight: 500;
  transition: all 0.3s ease;
}
.rs-blog.single .blog-part .article-nav ul li a:hover span {
  color: #106eea !important;
}
.rs-blog.single .blog-part .article-nav ul li.next {
  float: right;
  text-align: right;
}
.rs-blog.single .blog-part .article-nav ul li.next a span i {
  margin-left: 10px;
}
.rs-blog.single .blog-part .article-nav ul li.prev a span i {
  margin-right: 10px;
}
.rs-blog.single .bs-contact-form .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 17px;
}
.rs-blog.single .bs-contact-form form {
  padding: 0;
  max-width: 100%;
}
.rs-blog.single .bs-contact-form form .form-field {
  margin-bottom: 22px;
}
.rs-blog.single .bs-contact-form form .form-field label {
  display: block;
}
.rs-blog.single .bs-contact-form form .form-field input,
.rs-blog.single .bs-contact-form form .form-field textarea {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: unset;
  resize: unset;
}
.rs-blog.single .bs-contact-form form .form-field input {
  height: 50px;
  line-height: 50px;
}
.rs-blog.single .bs-contact-form form .form-field textarea {
  height: 160px;
  display: inherit;
}
.rs-blog.single .bs-contact-form form .form-button button {
  padding: 14px 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  font-family: 'Roboto', sans-serif;
  border: unset;
  outline: unset;
}
/*---------------------------------------
    34. Shop Section CSS
---------------------------------------*/
.rs-shop .select-option {
  position: relative;
}
.rs-shop .select-option select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: #363636;
  background: transparent;
  padding: 10px 18px;
  height: 56px;
  float: right;
}
.rs-shop .select-option:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #363636;
  right: 15px;
  position: absolute;
  top: 11px;
  font-size: 20px;
  pointer-events: none;
}
.rs-shop .product-list .image-product {
  position: relative;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.rs-shop .product-list .image-product .overley i {
  position: absolute;
  bottom: 50px;
  right: 13px;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-shop .product-list .image-product .overley i:before {
  font-size: 23px;
  color: #ffffff;
  font-weight: 400;
  background: #106eea;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
}
.rs-shop .product-list .image-product .onsale {
  font-size: 13px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  background: #228bfd;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 13px;
}
.rs-shop .product-list .content-desc .loop-product-title {
  margin: 0;
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
}
.rs-shop .product-list .content-desc .loop-product-title a {
  color: #1c1b1b;
}
.rs-shop .product-list .content-desc .loop-product-title a:hover {
  color: #106eea;
}
.rs-shop .product-list .content-desc .price {
  font-size: 16px;
  color: #228bfd;
  font-weight: 600;
  margin: 0;
}
.rs-shop .product-list .content-desc .price del {
  opacity: 0.5;
  padding-right: 10px;
}
.rs-shop .product-list:hover .image-product .overley i {
  opacity: 1;
  visibility: visible;
  bottom: 13px;
}
.rs-shop.single-product .single-product-slider .single-product-image .images-single img {
  width: 100%;
}
.rs-shop.single-product .single-product-slider .single-product-nav {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 15px;
}
.rs-shop.single-product .single-product-slider .single-product-nav .images-slide-single img {
  opacity: .5;
  cursor: pointer;
}
.rs-shop.single-product .single-product-slider .single-product-nav .images-slide-single img:hover {
  opacity: 1;
}
.rs-shop.single-product .single-product-slider .single-product-nav .slick-list .slick-track {
  margin: 0 auto;
}
.rs-shop.single-product .single-product-slider .single-product-nav .slick-current img {
  opacity: 1;
}
.rs-shop.single-product .single-price-info .product-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.rs-shop.single-product .single-price-info .single-price {
  color: #106eea;
  font-weight: 600;
}
.rs-shop.single-product .single-price-info .some-text {
  margin-top: 15px;
}
.rs-shop.single-product .single-price-info form {
  margin-bottom: 30px;
}
.rs-shop.single-product .single-price-info form input {
  height: 40px;
  width: 70px;
  line-height: 40px;
  text-align: center;
  padding-left: 10px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: none;
}
.rs-shop.single-product .single-price-info form .readon {
  outline: none;
  font-weight: 400;
  border: 1px solid #106eea;
  text-transform: capitalize;
  font-size: 15px;
  padding: 0 25px;
  height: 40px;
  line-height: 40px !important;
  margin-left: 10px;
}
.rs-shop.single-product .single-price-info p.category {
  margin: 0;
  padding-top: 25px;
  border-top: 1px solid #e6e6e6;
  font-size: 13px;
}
.rs-shop.single-product .single-price-info p.category span {
  font-weight: 700;
}
.rs-shop.single-product .single-price-info p.category a {
  color: #363636;
}
.rs-shop.single-product .single-price-info p.category a:hover {
  color: #106eea;
}
.rs-shop.single-product .single-price-info .tag {
  margin: 0;
  font-size: 13px;
}
.rs-shop.single-product .single-price-info .tag span {
  font-weight: 700;
}
.rs-shop.single-product .single-price-info .tag a {
  color: #106eea;
}
.rs-shop.single-product .single-price-info .tag a:hover {
  color: #363636;
}
.rs-shop.single-product .tab-area {
  margin-top: 50px;
}
.rs-shop.single-product .tab-area ul.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;
}
.rs-shop.single-product .tab-area ul.nav-tabs li {
  margin-right: 12px;
}
.rs-shop.single-product .tab-area ul.nav-tabs li a {
  font-size: 18px;
  border-radius: 4px 4px 0 0;
  border-radius: 5px 5px 0 0;
  background: #fbfbfb;
  color: #363636;
  display: inline-block;
  padding: 20px 45px;
}
.rs-shop.single-product .tab-area ul.nav-tabs li a:hover,
.rs-shop.single-product .tab-area ul.nav-tabs li a.active {
  background: #106eea;
  color: #ffffff;
}
.rs-shop.single-product .tab-area ul.nav-tabs li:last-child {
  margin-right: 0;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .tab-title {
  font-weight: 700;
  margin-bottom: 34px;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .dsc-p {
  margin: 0;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane table .table-box tr {
  border: 1px solid #f0f0f0;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane table .table-box tr th {
  padding: 10px 20px;
  width: 150px;
  border-right: 1px solid #f0f0f0;
  display: inline-block;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane table .table-box tr td {
  width: 100%;
  padding: 10px 20px;
  font-style: italic;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .top-area {
  margin-bottom: 5px;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .top-area p {
  margin-bottom: 23px;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .top-area h4 {
  font-weight: 400;
  color: unset;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form p.comment-notes {
  margin-bottom: 15px;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl label {
  font-weight: 600;
  width: 100%;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl input {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl textarea {
  display: inherit;
  width: 100%;
  padding: 10px 15px;
  height: 75px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  margin-bottom: 30px;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings {
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li {
  display: inline;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i {
  color: #106eea;
  font-weight: normal;
}
.rs-shop.single-product .tab-area .tab-content .tab-pane .reviews-grid .comment-form .readon {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  padding: 11px 27px;
}
/*---------------------------------------
    35. Car Section CSS
---------------------------------------*/
.rs-cart .cart-wrap table.cart-table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
}
.rs-cart .cart-wrap table.cart-table td,
.rs-cart .cart-wrap table.cart-table th {
  padding: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
.rs-cart .cart-wrap table.cart-table th {
  border: none;
  font-size: 18px;
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table td {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #e6e6e6;
}
.rs-cart .cart-wrap table.cart-table .product-remove a {
  margin: 0 auto;
  color: #1c1b1b;
  display: block;
  border-radius: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  width: 20px;
  line-height: 17px;
  text-align: center;
}
.rs-cart .cart-wrap table.cart-table .product-remove a:hover {
  background: #ff0000;
  color: #ffffff;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail {
  min-width: 32px;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail a img {
  width: 80px;
  height: auto;
}
.rs-cart .cart-wrap table.cart-table .product-name a {
  color: #363636;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-name a:hover {
  color: #106eea;
}
.rs-cart .cart-wrap table.cart-table .product-price {
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-quantity input {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 0 0 10px;
  max-width: 65px;
  margin: 0 auto;
  outline: none;
}
.rs-cart .cart-wrap table.cart-table .action .coupon {
  float: left;
}
.rs-cart .cart-wrap table.cart-table .action .coupon input {
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: none;
  width: 320px;
  border-radius: 4px;
  height: 51px;
  margin-right: 20px;
  text-align: left;
  padding-left: 22px;
}
.rs-cart .cart-wrap table.cart-table .action .coupon button {
  padding: 11px 29px;
  font-weight: normal;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  border: none;
}
.rs-cart .cart-wrap table.cart-table .action .update-cart a.readon {
  padding: 11px 29px;
  font-weight: normal;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}
.rs-cart .cart-collaterals {
  width: 100%;
  overflow: hidden;
}
.rs-cart .cart-collaterals .cart-totals {
  float: right;
  width: 48%;
}
.rs-cart .cart-collaterals .cart-totals .title {
  font-weight: 700;
  color: #363636;
  text-transform: capitalize;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  font-size: 18px;
  font-weight: 700;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr td,
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  padding: 30px 10px;
}
.rs-cart .cart-collaterals .cart-totals .wc-proceed-to-checkout a.readon {
  padding: 11px 29px;
  font-weight: normal;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}
/*---------------------------------------
    36. Checkout Section CSS
---------------------------------------*/
.rs-checkout .checkout-title {
  margin-bottom: 30px;
}
.rs-checkout .checkout-title h3 {
  font-size: 26px;
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card {
  border: unset;
  border-top: 3px solid #106eea;
}
.rs-checkout .coupon-toggle .accordion .card .card-header {
  border: none;
  margin: 0;
  border-radius: unset;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title {
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title span i {
  margin-right: 10px;
  color: #106eea;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button {
  background: unset;
  border: none;
  color: #106eea;
  transition: all 0.3s ease;
  outline: none;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button:hover {
  color: #1c1b1b;
}
.rs-checkout .coupon-toggle .accordion .card .card-body {
  border: 1px solid #d3ced2;
  padding: 20px 20px 30px 20px;
  margin-top: 2em;
  text-align: left;
  overflow: hidden;
  border-radius: 5px;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input {
  width: 47%;
  float: left;
  margin-right: 70px;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input input {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 10px 18px;
  color: #363636;
  border: 1px solid rgba(54, 54, 54, 0.1);
}
.rs-checkout .coupon-toggle .accordion .card .card-body .readon {
  border: 1px solid #106eea;
  outline: none;
  padding: 9px 22px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  cursor: pointer;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .readon:focus {
  background: unset;
  color: unset;
}
.rs-checkout .full-grid {
  margin-top: 25px;
}
.rs-checkout .full-grid .form-content-box {
  margin-bottom: 50px;
}
.rs-checkout .full-grid .form-content-box .form-group label {
  line-height: 2;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.rs-checkout .full-grid .form-content-box .form-group label span {
  color: red;
}
.rs-checkout .full-grid .form-content-box .form-group select {
  color: #666666;
  opacity: .5;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  box-shadow: none;
  width: 100%;
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 18px;
  margin: 0;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod {
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  padding: 10px 18px;
  width: 100%;
  margin: 0;
  outline: none;
  line-height: normal;
  border-radius: unset;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod.margin-bottom {
  margin-bottom: 10px !important;
}
.rs-checkout .full-grid .ordered-product table {
  width: 100%;
}
.rs-checkout .full-grid .ordered-product table tr th {
  border: 1px solid #ccc;
  padding: 9px 12px;
}
.rs-checkout .full-grid .ordered-product table tr td {
  border: 1px solid #ccc;
  padding: 6px 12px;
}
.rs-checkout .full-grid .payment-method {
  background: #ebe9eb;
  border-radius: 5px;
  margin-top: 25px;
}
.rs-checkout .full-grid .payment-method .top-area {
  border-bottom: 1px solid #d3ced2;
  padding: 1em;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co {
  margin-bottom: 20px;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co span {
  font-weight: 600;
  margin-right: 10px;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #dfdcde;
  color: #515151;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg:before {
  content: '';
  display: block;
  border: 1em solid #dfdcde;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -0.75em;
  left: 0;
  margin: -1em 0 0 2em;
}
.rs-checkout .full-grid .payment-method .bottom-area {
  padding: 1em;
  text-align: right;
}
.rs-checkout .full-grid .payment-method .bottom-area .readon {
  padding: 10 22px;
  border: none;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
  outline: none;
}
/*---------------------------------------
    37. My account Section CSS
---------------------------------------*/
.rs-my-account .login-side,
.rs-my-account .recover-psw-side {
  padding: 30px 40px;
}
.rs-my-account .login-side form.login-form.border-style,
.rs-my-account .recover-psw-side form.login-form.border-style {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 20px;
  border-radius: 5px;
}
.rs-my-account .login-side form.login-form .input-label,
.rs-my-account .recover-psw-side form.login-form .input-label {
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-my-account .login-side form.login-form .input-label span,
.rs-my-account .recover-psw-side form.login-form .input-label span {
  color: red;
  font-weight: 700;
  font-size: 18px;
}
.rs-my-account .login-side form.login-form .input-control,
.rs-my-account .recover-psw-side form.login-form .input-control {
  width: 100%;
  display: block;
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  outline: none;
  padding: 10px 18px;
  line-height: 50px;
  margin-bottom: 15px;
  border-radius: 30px;
}
.rs-my-account .login-side form.login-form .login-control,
.rs-my-account .recover-psw-side form.login-form .login-control {
  margin-bottom: 15px;
}
.rs-my-account .login-side form.login-form .login-control ul li,
.rs-my-account .recover-psw-side form.login-form .login-control ul li {
  margin-right: 15px;
  display: inline;
}
.rs-my-account .login-side form.login-form .login-control ul li .readon,
.rs-my-account .recover-psw-side form.login-form .login-control ul li .readon {
  padding: 0 25px;
  height: 40px;
  outline: none;
  line-height: 40px !important;
  text-transform: capitalize;
  font-weight: normal;
}
.rs-my-account .login-side form.login-form .login-control ul li:last-child,
.rs-my-account .recover-psw-side form.login-form .login-control ul li:last-child {
  margin-right: 0;
}
.rs-my-account .login-side .recover-info,
.rs-my-account .recover-psw-side .recover-info {
  margin-bottom: 50px;
}
.rs-my-account .regi-side {
  padding: 30px 40px;
  background: #f9f9f9;
  border-radius: 10px;
}
.rs-my-account .regi-side form.register-form.border-style {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 20px;
  border-radius: 5px;
}
.rs-my-account .regi-side form.register-form label.input-label {
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-my-account .regi-side form.register-form label.input-label span.req {
  color: red;
  font-weight: 700;
  font-size: 17px;
}
.rs-my-account .regi-side form.register-form input.custom-placeholder {
  width: 100%;
  display: block;
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  outline: none;
  padding: 10px 18px;
  line-height: 50px;
  margin-bottom: 15px;
  border-radius: 30px;
}
.rs-my-account .regi-side form.register-form .margin-space {
  margin-bottom: 25px;
}
.rs-my-account .regi-side form.register-form .gender-detect label span {
  margin-left: 5px;
  margin-right: 5px;
}
.rs-my-account .regi-side form.register-form .gender-detect label:last-child span {
  margin-right: 0;
}
.rs-my-account .regi-side form.register-form .date {
  padding: 7px;
  color: #363636;
  border-radius: 3px;
  outline: unset;
  border: 1px solid #e4e4e4;
  margin-right: 15px;
}
.rs-my-account .regi-side form.register-form .checkbox {
  margin-bottom: 25px;
}
.rs-my-account .regi-side form.register-form .readon {
  padding: 0 25px;
  height: 40px;
  line-height: 36px !important;
  outline: none;
  text-transform: capitalize;
  font-weight: normal;
}
/*---------------------------------------
    38. Footer CSS
---------------------------------------*/
.rs-footer {
  background: #101010;
}
.rs-footer .footer-newsletter {
  padding: 80px 0 70px;
  border-bottom: 2px solid #333;
}
.rs-footer .footer-newsletter .title {
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
}
.rs-footer .footer-newsletter .newsletter-form {
  max-width: 498px;
  margin: 0 0 0 auto;
  position: relative;
}
.rs-footer .footer-newsletter .newsletter-form input,
.rs-footer .footer-newsletter .newsletter-form button {
  height: 60px;
  border-radius: 0;
  opacity: 1;
  border-radius: 0 3px 3px 0;
  outline: none;
}
.rs-footer .footer-newsletter .newsletter-form input {
  padding: 10px 130px 10px 18px;
  width: 100%;
  color: #ffffff;
  background: transparent;
  border: 2px solid #212326;
}
.rs-footer .footer-newsletter .newsletter-form button {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  background: #106eea;
  color: #ffffff;
  border: none;
  font-size: 20px;
  padding: 10px 27px;
}
.rs-footer .footer-newsletter .newsletter-form button i {
  transition: all 0.3s ease;
}
.rs-footer .footer-newsletter .newsletter-form button:hover i {
  opacity: 0.7;
}
.rs-footer .footer-newsletter .newsletter-form ::placeholder {
  color: #ffffff;
}
.rs-footer .footer-content .widget-title {
  color: #ffffff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.rs-footer .footer-content .footer-post .post-wrap {
  overflow: hidden;
}
.rs-footer .footer-content .footer-post .post-wrap .post-img {
  width: 100px;
  float: left;
  padding-right: 15px;
}
.rs-footer .footer-content .footer-post .post-wrap .post-img a img {
  border-radius: 4px;
}
.rs-footer .footer-content .footer-post .post-wrap .post-desc a {
  color: #e0e0e0;
  font-weight: 600;
}
.rs-footer .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #106eea;
}
.rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #e0e0e0;
  font-size: 14px;
}
.rs-footer .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #106eea;
  margin-right: 5px;
}
.rs-footer .footer-content .about-widget .logo-part {
  margin-bottom: 30px;
}
.rs-footer .footer-content .about-widget .logo-part a img {
  height: 38px;
  width: auto;
}
.rs-footer .footer-content .about-widget .desc {
  color: #e0e0e0;
}
.rs-footer .footer-content .address-widget li {
  margin-bottom: 24px;
}
.rs-footer .footer-content .address-widget li i {
  float: left;
  padding-right: 25px;
}
.rs-footer .footer-content .address-widget li i:before {
  color: #106eea;
}
.rs-footer .footer-content .address-widget li .desc {
  color: #e0e0e0;
}
.rs-footer .footer-content .address-widget li .desc a {
  color: #e0e0e0;
}
.rs-footer .footer-content .address-widget li .desc a:hover {
  color: #106eea;
}
.rs-footer .footer-content .address-widget li:last-child {
  margin-bottom: 0;
}
.rs-footer .footer-bottom {
  padding: 36px 0;
  border-top: 2px solid #333;
}
.rs-footer .footer-bottom .copyright p {
  margin-bottom: 0;
  color: #e0e0e0;
}
.rs-footer .footer-bottom .footer-social li {
  display: inline;
  margin-right: 6px;
}
.rs-footer .footer-bottom .footer-social li a {
  display: inline-block;
  font-size: 15px;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #106eea;
  color: #ffffff;
  text-align: center;
}
.rs-footer .footer-bottom .footer-social li a:hover {
  background: #228bfd;
}
.rs-footer .footer-bottom .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style2 {
  background: url(./images/bg/footer-seo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.rs-footer.style2 .footer-content .footer-post .post-wrap .post-desc a {
  color: #ffffff;
}
.rs-footer.style2 .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #f67d31;
}
.rs-footer.style2 .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #ffffff;
}
.rs-footer.style2 .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #ffab0b;
}
.rs-footer.style2 .footer-content .address-widget li i:before {
  color: #ffab0b;
}
.rs-footer.style2 .footer-content .address-widget li .desc {
  color: #ffffff;
}
.rs-footer.style2 .footer-content .address-widget li .desc a {
  color: #ffffff;
}
.rs-footer.style2 .footer-content .address-widget li .desc a:hover {
  color: #f67d31;
}
.rs-footer.style2 .footer-content .address-widget li:last-child {
  margin-bottom: 0;
}
.rs-footer.style2 .footer-bottom {
  border-top: 2px solid rgba(255, 255, 255, 0.11);
}
.rs-footer.style2 .footer-bottom .copyright p {
  color: #ffffff;
}
.rs-footer.style2 .footer-bottom .footer-social li a {
  background: #e68008;
}
.rs-footer.style2 .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style2 .footer-bottom .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style2.modify1 {
  background: url(./images/bg/footer-bg14.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.rs-footer.style2.modify1 .footer-content .widget-title {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .footer-post .post-wrap .post-desc a {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #1c3988;
}
.rs-footer.style2.modify1 .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #106eea;
}
.rs-footer.style2.modify1 .footer-content .about-widget .desc {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .address-widget li i:before {
  color: #106eea;
}
.rs-footer.style2.modify1 .footer-content .address-widget li .desc {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .address-widget li .desc a {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-content .address-widget li .desc a:hover {
  color: #1c3988;
}
.rs-footer.style2.modify1 .footer-content .address-widget li:last-child {
  margin-bottom: 0;
}
.rs-footer.style2.modify1 .footer-bottom {
  border-top: 2px solid rgba(28, 57, 136, 0.08);
}
.rs-footer.style2.modify1 .footer-bottom .copyright p {
  color: #333333;
}
.rs-footer.style2.modify1 .footer-bottom .footer-social li a {
  background: #1c3988;
}
.rs-footer.style2.modify1 .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style2.modify1 .footer-bottom .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style2.modify1.corporate2 .footer-content .widget-title {
  color: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-content .footer-post .post-wrap .post-desc a {
  color: #333333;
}
.rs-footer.style2.modify1.corporate2 .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #333333;
}
.rs-footer.style2.modify1.corporate2 .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-content .address-widget li i:before {
  color: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-content .address-widget li .desc {
  color: #333333;
}
.rs-footer.style2.modify1.corporate2 .footer-content .address-widget li .desc a {
  color: #333333;
}
.rs-footer.style2.modify1.corporate2 .footer-content .address-widget li .desc a:hover {
  color: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-content .address-widget li:last-child {
  margin-bottom: 0;
}
.rs-footer.style2.modify1.corporate2 .footer-bottom {
  border-top: 2px solid rgba(28, 57, 136, 0.08);
}
.rs-footer.style2.modify1.corporate2 .footer-bottom .footer-social li a {
  background: #106eea;
}
.rs-footer.style2.modify1.corporate2 .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style2.modify1.corporate2 .footer-bottom .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style3 {
  background: url(./images/bg/footer-bg13.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
.rs-footer.style3 .footer-newsletter {
  padding: 130px 0 70px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.06);
}
.rs-footer.style3 .footer-newsletter .newsletter-form input,
.rs-footer.style3 .footer-newsletter .newsletter-form input {
  border: 2px solid rgba(255, 255, 255, 0.06);
}
.rs-footer.style3 .footer-newsletter .newsletter-form button {
  background: #12adfc;
}
.rs-footer.style3 .footer-content .footer-post .post-wrap .post-desc a {
  color: #fff;
}
.rs-footer.style3 .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #12adfc;
}
.rs-footer.style3 .footer-content .footer-post .post-wrap .post-desc .date-post {
  color: #fff;
}
.rs-footer.style3 .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #12adfc;
}
.rs-footer.style3 .footer-content .about-widget .desc {
  color: #fff;
}
.rs-footer.style3 .footer-content .address-widget li {
  margin-bottom: 24px;
}
.rs-footer.style3 .footer-content .address-widget li i {
  float: left;
  padding-right: 25px;
}
.rs-footer.style3 .footer-content .address-widget li i:before {
  color: #12adfc;
}
.rs-footer.style3 .footer-content .address-widget li .desc {
  color: #fff;
}
.rs-footer.style3 .footer-content .address-widget li .desc a {
  color: #fff;
}
.rs-footer.style3 .footer-content .address-widget li .desc a:hover {
  color: #12adfc;
}
.rs-footer.style3 .footer-content .address-widget li:last-child {
  margin-bottom: 0;
}
.rs-footer.style3 .footer-bottom {
  border-top: 2px solid rgba(255, 255, 255, 0.06);
}
.rs-footer.style3 .footer-bottom .copyright p {
  color: #fff;
}
.rs-footer.style3 .footer-bottom .footer-social li a {
  background: #12adfc;
}
.rs-footer.style3 .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style3.modify1 {
  background: url(./images/bg/footer-bg15.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.rs-footer.style3.modify1 .footer-newsletter .newsletter-form button {
  background: #eb4a6f;
}
.rs-footer.style3.modify1 .footer-newsletter .newsletter-form button:hover {
  opacity: 0.9;
}
.rs-footer.style3.modify1 .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #ec4c6f;
}
.rs-footer.style3.modify1 .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #ec4c6f;
}
.rs-footer.style3.modify1 .footer-content .address-widget li i:before {
  color: #ec4c6f;
}
.rs-footer.style3.modify1 .footer-content .address-widget li .desc a:hover {
  color: #ec4c6f;
}
.rs-footer.style3.modify1 .footer-bottom .footer-social li a {
  background: #ec4c6f;
}
.rs-footer.style3.modify1 .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style3.modify1.marketing {
  background: url(./images/bg/footer-bg17.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #110909;
}
.rs-footer.style3.modify1.marketing .footer-newsletter .newsletter-form button {
  background: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-newsletter .newsletter-form button:hover {
  opacity: 0.9;
}
.rs-footer.style3.modify1.marketing .footer-content .footer-post .post-wrap .post-desc a:hover {
  color: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-content .footer-post .post-wrap .post-desc .date-post i {
  color: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-content .address-widget li i:before {
  color: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-content .address-widget li .desc a:hover {
  color: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-bottom .footer-social li a {
  background: #bf0505;
}
.rs-footer.style3.modify1.marketing .footer-bottom .footer-social li a:hover {
  color: #ccc;
}
.rs-footer.style4 {
  background: url(./images/bg/footer-bg-16.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
/*---------------------------------------
    39. Error 404 CSS 
---------------------------------------*/
.rs-page-error {
  height: 100vh;
  display: grid;
  align-items: center;
}
.rs-page-error .error-text {
  color: #106eea;
  text-align: center;
}
.rs-page-error .error-text .error-code {
  font-size: 250px;
  line-height: 250px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #1c1b1b;
}
.rs-page-error .error-text h3.error-message {
  color: #1c1b1b;
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
}
.rs-page-error .error-text form {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
.rs-page-error .error-text form input {
  border: 1px solid #ddd;
  color: #444444;
  padding: 0 17px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  outline: none;
  margin-bottom: 30px;
}
.rs-page-error .error-text form button {
  background: transparent;
  border: medium none;
  color: #666666;
  padding: 0 16px;
  height: 48px;
  line-height: 48px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}
.rs-page-error .error-text form button:hover {
  background: #106eea;
  color: #ffffff;
}
/*---------------------------------------
    40. Scroll Up CSS
---------------------------------------*/
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  background: #106eea;
  border-radius: 50%;
  height: 40px;
  font-size: 24px;
  font-weight: 600;
  width: 42px;
  color: #fff;
  line-height: 36px;
  transition: all 0.3s ease;
  margin-left: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}
#scrollUp i:hover {
  background-color: #106eea;
}
/*-----------------------------------------------
    41. Header Cart Modal Css
-----------------------------------------------*/
.woocommerce-mini-cart {
  position: absolute;
  padding: 30px 20px;
  background: #fff;
  right: 0;
  top: 200%;
  transition: all 0.3s ease;
  width: 270px;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.woocommerce-mini-cart .cart-icon-product-list {
  display: block !important;
}
.woocommerce-mini-cart .cart-icon-product-list li {
  border-bottom: 1px solid rgba(170, 170, 170, 0.25);
  padding: 0 0 20px 0 !important;
  margin: 0 0 20px 0 !important;
  margin-right: 0 !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.woocommerce-mini-cart .cart-icon-product-list li:last-child {
  margin: 0 !important;
}
.woocommerce-mini-cart .product-info {
  padding: 0 26px;
}
.woocommerce-mini-cart .product-info a {
  color: #363636 !important;
}
.woocommerce-mini-cart .product-info a:hover {
  color: #d83030 !important;
}
.woocommerce-mini-cart .total-price {
  padding: 0 0 20px;
}
.woocommerce-mini-cart .icon-cart i {
  color: #363636;
  width: 18px;
  height: 18px;
  line-height: 16px;
  border: 1px solid #363636;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  transition: all 0.3s ease;
}
.woocommerce-mini-cart .icon-cart i:hover {
  color: #d83030;
  border-color: #d83030;
}
.woocommerce-mini-cart .icon-cart,
.woocommerce-mini-cart .product-image {
  margin-top: 2px;
}
.woocommerce-mini-cart .product-image {
  display: block;
  float: right;
  text-align: right;
  width: 56px;
}
.woocommerce-mini-cart .crt-btn {
  padding: 5px 13px;
  font-size: 14px;
  background: #d83030;
  color: #ffffff;
  border-radius: 30px;
  display: inline-block;
}
.woocommerce-mini-cart .crt-btn:hover {
  background: #dc4545;
  color: #ffffff;
}
.woocommerce-mini-cart .crt-btn.btn1 {
  margin-right: 10px;
}
.mini-cart-active:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
/*---------------------------------------
    42. Preloader Css
---------------------------------------*/
.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  margin-top: 0;
  top: 0;
  background: #ffffff;
}
.loader .spinner {
  width: 40px;
  height: 40px;
  margin: -19px 0 0 -21px;
  background-color: #106eea;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.scale {
  animation: scale 8s alternate infinite;
  webkit-animation: scale 2s alternate infinite;
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.up-down-new {
  animation: up-down 4s alternate infinite;
  -webkit-animation: up-down 4s alternate infinite;
}
@keyframes up-down-new {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down-new {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
.rs-services.style20 .iconbox-area:hover .dance_hover,
.rs-whychooseus.style7 .iconbox-area:hover .dance_hover {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.horizontal {
  animation-name: horizontal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 20s;
}
@keyframes horizontal {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
.rotated-style {
  animation: rotated-style 40s linear infinite;
}
@keyframes rotated-style {
  0% {
    -webkit-transform: translate(0) rotate(0);
    transform: translate(0) rotate(0);
  }
  25% {
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    transform: translate(15px, -5px) rotate(15deg);
  }
  100% {
    transform: translate(0) rotate(0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 2s;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
/*---------------------------------------
    43. Responsive Menu Colors
---------------------------------------*/
@media only screen and (max-width: 991px) {
  .full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li.current-page-item > a,
  .full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li a:hover,
  .full-width-header.header-style3 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li.active a,
  .full-width-header.header-style2 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li.current-page-item > a,
  .full-width-header.header-style2 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li a:hover,
  .full-width-header.header-style2 .rs-header .menu-area .container .main-menu .rs-menu ul.sub-menu li.active a,
  .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.current-page-item > a,
  .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
  .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
    color: #106eea !important;
  }
  .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a::before,
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover,
  body.home-nine .full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-nine .full-width-header.header-style2.modify.red .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu#onepage-menu a:hover,
  body.home-nine .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-eight .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a::before,
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a::before,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover,
  body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a,
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu#onepage-menu .active-menu a,
  body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul li.current-menu-item > a::before,
  body.home-eleven .full-width-header .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu > li > a:hover {
    color: #ccc !important;
  }
  /*-- Home 6 menu color start --*/
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu {
    background: #67c27c;
  }
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul > li > a {
    border-color: #5cbe73;
  }
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.current-page-item > a,
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.active > a,
  body.home-six .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li > a:hover {
    color: #67c27c !important;
  }
  body.home-six .full-width-header.header-style2 .nav-menu > .menu-item-has-children > span.rs-menu-parent,
  body.home-six .full-width-header.header-style2 .nav-menu .rs-mega-menu > span.rs-menu-parent {
    background: #5cbe73;
  }
  body.home-six .full-width-header.header-style2 .rs-menu-toggle:hover {
    color: #67c27c !important;
  }
  /*-- Home 6 menu color end --*/
  /*-- Home 8 menu color start --*/
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu {
    background: #1c3988;
  }
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul > li > a {
    border-color: #1f3e95;
  }
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.current-page-item > a,
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.active > a,
  body.home-eight .full-width-header.header-style4 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li > a:hover {
    color: #1c3988 !important;
  }
  body.home-eight .full-width-header.header-style4 .nav-menu > .menu-item-has-children > span.rs-menu-parent,
  body.home-eight .full-width-header.header-style4 .nav-menu .rs-mega-menu > span.rs-menu-parent {
    background: #1f3e95;
  }
  body.home-eight .full-width-header.header-style4 .rs-menu-toggle:hover {
    color: #1c3988 !important;
  }
  /*-- Home 8 menu color end --*/
  /*-- Home 9 menu color start --*/
  body.home-nine .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu {
    background: #ea355c;
  }
  body.home-nine .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul > li > a {
    border-color: #ec4c6f;
  }
  body.home-nine .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.current-page-item > a,
  body.home-nine .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.active > a,
  body.home-nine .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li > a:hover {
    color: #ec4c6f !important;
  }
  body.home-nine .full-width-header.header-style2 .nav-menu > .menu-item-has-children > span.rs-menu-parent,
  body.home-nine .full-width-header.header-style2 .nav-menu .rs-mega-menu > span.rs-menu-parent {
    background: #ec4c6f !important;
  }
  body.home-nine .full-width-header.header-style2 .rs-menu-toggle:hover {
    color: #ec4c6f !important;
  }
  /*-- Home 9 menu color end --*/
  /*-- Home 10 menu color start --*/
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu {
    background: #b1976b;
  }
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul > li > a {
    border-color: #a88b5a !important;
  }
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.current-page-item > a,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.active > a,
  body.home-ten .full-width-header.header-style2 .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li > a:hover {
    color: #b1976b !important;
  }
  body.home-ten .full-width-header.header-style2 .nav-menu > .menu-item-has-children > span.rs-menu-parent,
  body.home-ten .full-width-header.header-style2 .nav-menu .rs-mega-menu > span.rs-menu-parent {
    background: #a88b5a;
  }
  body.home-ten .full-width-header.header-style2 .rs-menu-toggle:hover {
    color: #b1976b !important;
  }
  /*-- Home 10 menu color end --*/
  /*-- Home 11 menu color start --*/
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu {
    background: #d83030;
  }
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul > li > a {
    border-color: #c92626 !important;
  }
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.current-page-item > a,
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li.active > a,
  body.home-eleven .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li .sub-menu > li > a:hover {
    color: #d83030 !important;
  }
  body.home-eleven .full-width-header .nav-menu > .menu-item-has-children > span.rs-menu-parent,
  body.home-eleven .full-width-header .nav-menu .rs-mega-menu > span.rs-menu-parent {
    background: #c92626;
  }
  body.home-eleven .full-width-header .rs-menu-toggle:hover {
    color: #d83030 !important;
  }
  /*-- Home 11 menu color end --*/
}
@media only screen and (max-width: 767px) {
  .shape-bg2,
  .shape-bg5 {
    background: #fafafa;
  }
  .shape-bg1,
  .shape-bg3,
  .shape-bg4 {
    background: #1c3988;
  }
}
