.skill-container {
    width: 90%;
    margin: auto;
    border: 1px solid #000;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 10px;
    padding: 20px;
    overflow: hidden; /* Prevents content overflow */
}

@media (max-width: 768px) {
    .skill-container {
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 15px;
    }
}

.cl-skill-bar.style2.solutions {
    margin-top: 20px;
}

.video-skill .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.skillsets-part, .video-wrap {
    padding: 15px; /* Adds padding to skill and video sections */
}

.skillbar {
    position: relative;
    height: 25px;
    background: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 15px;
    overflow: hidden;
}

.skillbar-bar {
    height: 100%;
    background-color: #071f6b;
    border-radius: 5px;
    transition: width 1.5s ease-in-out;
}

.skill-bar-percent {
    position: absolute;
    right: 10px;
    top: 0;
    color: #fff;
}
